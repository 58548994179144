export const actionTypes = {
  CHANGE_SET_RETORNO: 'SET_RETORNO'
}

export const changeRetorno = (dataProximoRetorno) => {
  return {
    type: actionTypes.CHANGE_SET_RETORNO,
    payload: { dataProximoRetorno }
  };
};
