import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';

import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { changeloading } from '../../../store/actions/loading.action';
import FornecedoresService from '../../../services/FornecedoresService';
import { changeNotify } from '../../../store/actions/notify.actions';
import { CustomTextField } from '../../../components';



function EditFornecedor() {
  const location = useLocation();
  const fornecedor = location.state ? location.state.fornecedor : null;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [razao_social, setRazao_social] = useState('');
  const [email, setEmail] = useState('');
  const [telefone, setTelefone] = useState('');
  const [cnpj, setCnpj] = useState('');
  const [endereco, setEndereco] = useState('');

  useEffect(() => {
    if (fornecedor) {
      setRazao_social(fornecedor.razao_social || '');
      setEmail(fornecedor.email || '');
      setTelefone(fornecedor.telefone || '');
      setCnpj(fornecedor.cnpj || '')
      setEndereco(fornecedor.endereco || '')
    }
  }, [fornecedor]);


  const handleEdit = async (event) => {
    dispatch(changeloading({ open: true, msg: 'Enviando..' }))
    event.preventDefault();
    // Remover formatação do valor e substituir vírgula por ponto

    const data = { razao_social, email, telefone, cnpj, endereco };

    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await FornecedoresService.Update(fornecedor.id, data);
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Fornecedor editado com sucesso' }))
      navigate('/fornecedor');

    } catch (error) {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }))
    }
  };

  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={3}  >
      <Grid container spacing={2} justifyContent="center">
        <Typography variant='h2' sx={{ color: 'grey.700', marginBottom: '20px', marginTop: '10px' }}> Editar Fonecedor</Typography>
        <Grid item xs={12} sm={12} md={12}>

          <Grid container spacing={2} >
            <Grid item xs={12} sm={6} md={6} lg={5}>
              <TextField
                label='Razao Social'
                variant='outlined'
                fullWidth
                size='small'
                value={razao_social}
                onChange={(e) => setRazao_social(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <TextField
                label='Email'
                type='email'
                variant='outlined'
                fullWidth
                size='small'
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CustomTextField
                label='Telefone'
                format='(##) # ####-####'
                mask='_'
                allowEmptyFormatting
                isAllowed={(values) => {
                  const { formattedValue } = values;
                  return !formattedValue || formattedValue.length <= 16;
                }}
                fullWidth
                size='small'
                value={telefone}
                onValueChange={(values) => setTelefone(values.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={6} lg={3}>
              <CustomTextField
                label='Cnpj'
                format='##.###.###/####-##'  //XX.XXX.XXX/0001-XX
                mask='_'
                allowEmptyFormatting
                isAllowed={(values) => {
                  const { formattedValue } = values;
                  return !formattedValue || formattedValue.length <= 20;
                }}
                fullWidth
                size='small'
                value={cnpj}
                onValueChange={(values) => setCnpj(values.value)}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={6} lg={5}>
              <TextField
                label='Endereço'
                variant='outlined'
                fullWidth
                size='small'
                value={endereco}
                onChange={(e) => setEndereco(e.target.value)}
              />
            </Grid>



            <Grid item xs={12} sm={3} md={1} lg={4}>
            </Grid>



          </Grid>

        </Grid>

        <Box display='flex' justifyContent='space-between' width='40%' marginTop={5}>
          <Button sx={{ margin: '5px' }} fullWidth onClick={handleEdit} size='small' variant='contained'>editar</Button>
          <Button sx={{ margin: '5px' }} fullWidth size='small' variant='contained' color='warning' onClick={() => navigate(-1)}>Voltar</Button>
        </Box>
      </Grid>
    </Box>
  )
}

export default EditFornecedor;