import React, { useEffect, useState } from 'react';
import AddUsruario from './AddUsuario';
import {  Box,  Grid,  Paper, TextField, } from '@mui/material';
import { TableComponet } from '../../../components';
import UsersService from '../../../services/UsersService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';

// import { Container } from './styles';
const headers = [
  {
    id: "name",
    label: "Nome",
    props: {
      align: 'left',
    },

  },
  {
    id: "perfil",
    label: "Perfil",
    props: {
      align: 'right',
    },
  },

  {
    id: 'email',
    label: 'Email',
    props: {
      align: 'right',
    },
  },
  {
    id: 'telefone',
    label: 'Telefone',
    props: {
      align: 'right',
    },
  },
  {
    id: 'cpf',
    label: 'Cpf',
    props: {
      align: 'right',
    },
  },
  {
    id: 'rg',
    label: 'Indentidade',
    props: {
      align: 'right',
    },
  },
  {
    id: 'endereco',
    label: 'Endereço',
    props: {
      align: 'right',
    },
  },  
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];
function Index() {
const[usuario, setUsurario] = useState([]);
const [mounted, setMounted] = useState(true);
const dispatch = useDispatch();

  async function getUsuario() {
    const res = await UsersService.get();
  
    if (res) { setUsurario(res.usuarios); } }

 useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando", }) );
      if (mounted) {
        await getUsuario();
        dispatch(changeloading({ open: false, }) );
       }
    };

    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  return (
    <Box >
      <Grid container spacing={2} >

        <Grid item xs={12}>
          <AddUsruario />
        </Grid>

        <Grid item xs={12}>
  
              {/* <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse produto?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
              /> */}
       
            <Box>              
                <TableComponet
                headers={headers}
                data={usuario}
                labelCaption={'Nenhuma usuario encontrada!!'}
                labelTable={'Usuarios'}
                handlerEditarAction={''}
                handlerDeletarAction={''}
              />
            </Box>         
        </Grid>



      </Grid>
    </Box>
  )
}

export default Index;