import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, connect } from "react-redux"; // Importando o hook useSelector
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import ProdutosService from "../../../../services/ProdutosService";
import OrcamentoService from "../../../../services/OrcamentoService";
import { MaskLargura, MaskValor } from "../../../../utils/mascaras";

const schema = yup.object({
  material: yup.string().required(),
  qtdPe: yup.string().required(),
  tamanho: yup.string().required(),
  valorPe: yup.string().required(),
  chumbado: yup.string().required(),
});

function FormCalcularPe({ idOrcamentoEtapa2, IdOrcamentoEstrutura, orcamentoId }) {

  const handleClick = (data) => {
    if (orcamentoId) {
      handleEdit(data);
    } else {
      handleCalcularPe(data);
    }
  };

  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();

  const [valorTotalPe, setValorTotalPe] = useState(0);
  const [travessaSelecionada, setTravessaSelecionada] = useState("");
  const [travessas, setTravesas] = useState([]);
  const [chumbadoSelecionada, setChumbadoSelecionada] = useState("");
  const [valorButtonClicked, setValorButtonClicked] = useState(false);
  const [orcamentoEstruturaEtapaDois, setOrcamentoEstruturaEtapaDois] = useState('');

  const chumbado = [
    { value: 1, label: "sim" },
    { value: 0, label: "não" },
  ];

  const getTravessas = async () => {
    const res = await ProdutosService.buscarTravessas();
    if (res) {
      setTravesas(res.travessas);
    }
  };

  useEffect(() => {
    getTravessas();
  }, []);

  useEffect(() => {
    if (valorTotalPe !== 0) {
      const maskedValorPe = MaskValor(valorTotalPe);
      setValue("valorPe", maskedValorPe);
    }
  }, [valorTotalPe]);

  function handleCalcularPe(data) {
    if (!valorButtonClicked) {
      // Verifica se o botão de valor já foi clicado
      setValorButtonClicked(true); // Define que o botão foi clicado
      // Usando o valor de idOrcamento do Redux
      let newData = {
        ...data,
        orcamento_id: idOrcamentoEtapa2,
        IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      };

      dispatch(changeloading({ open: true, msg: "calculando.." }));
      OrcamentoService.buscarCarcularPe(newData)
        .then((res) => {
          setValorTotalPe(res.valorTotalPe);
          dispatch(changeloading({ open: false }));
        })
        .catch((error) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: error.response.data.error,
            })
          );
        });
    }
  }

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: 'buscando ...' }));
    const response = await OrcamentoService.trazerEtapadois(id);
    if (response) {
      const orcamento = response;
      setOrcamentoEstruturaEtapaDois(orcamento.estrutura_id)
      setValue("qtdPe", orcamento.qtdPe);
      setValue("tamanho", orcamento.tamanho);
      setValue("chumbado", orcamento.chumbado);
      setValue("valorPe", orcamento.valorPe);
      setChumbadoSelecionada(orcamento.chumbado)
      orcamento.produtos.forEach((produto) => {      
            setTravessaSelecionada(produto.produto_id);
            setValue("material", produto.produto_id);
   });
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
 
  };

  useEffect(() => {  
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleEdit = (data) => {
   
    let newData = {
      ...data,
      etapa_id: 2,  
      OrcamentoEstruturaId: orcamentoEstruturaEtapaDois,
      orcamento_id: orcamentoId   
    };  
    dispatch(changeloading({ open: true, msg:'editando' }));
    OrcamentoService.editEtapaDois(orcamentoId, newData)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        setValue("valorPe", res.valorPe);
        dispatch(
          changeNotify({ open: true, class: "success", msg: 'editado com sucesso'})
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  }

  return (
    <Box>
     
      <form onSubmit={onSubmit(handleClick)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              variant="h5"
              marginLeft={2}
              marginTop={2}
              marginBottom="-35px"
            >
              calcular pé
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={2} marginTop="24px">
            <TextField
              label="Qtd de pé"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              size="small"
              {...register("qtdPe")}
            />
            <Typography variant="subtitle2">
              {errors?.qtdPe?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={2} marginTop="24px">
            <TextField
              label="Tamanho"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              size="small"
              {...register("tamanho")}
              onInput={(e) => {
                e.target.value = MaskLargura(e.target.value);
                setValue("tamanho", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant="subtitle2">
              {errors?.tamanho?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={4} md={2} lg={2}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              chumbado
            </InputLabel>
            <Select
              label="chumbador"
              id="chumbador"
              variant="outlined"
              fullWidth
              {...register("chumbado")}
              value={chumbadoSelecionada}
              size="small"
              onChange={(e) => setChumbadoSelecionada(e.target.value)}
            >
              {chumbado.map((chumbado) => (
                <MenuItem key={chumbado.value} value={chumbado.value}>
                  {chumbado.label}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.chumbado?.message}
            </Typography>
            <Grid />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="4px">
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Material
            </InputLabel>
            <Select
              id="material"
              fullWidth
              size="small"
              value={travessaSelecionada}
              {...register("material")}
              onChange={(e) => {
                setTravessaSelecionada(e.target.value);
              }}
            >
              {travessas.map((travessas) => (
                <MenuItem key={travessas.id} value={travessas.id}>
                  {travessas.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.material?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} marginTop="24px">
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              {...register("valorPe", { value: valorTotalPe })}
              onClick={onSubmit(handleClick)}
            
            />
            <Typography variant="subtitle2">
              {errors?.valorPe?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <Typography sx={{ fontSize: "14px" }}>
              numero do orçamento:
              <br />
              {idOrcamentoEtapa2}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
function mapStateToProps(state) {
  return {
    idOrcamentoEtapa2: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}
export default connect(mapStateToProps)(FormCalcularPe);
