import { Box, Button, Modal, Paper, Typography } from "@mui/material";
import React, { useState } from "react";
import AddRetorno from "./AddRetorno";
import TableRetorno from "./TableRetorno";
import { useLocation } from "react-router-dom";

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "80%",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function Retorno() {

  const [openModal, setOpenModal] = useState(false);
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

  const handleCloseModal = () => {
    setOpenModal(false);
  };

 
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCadastroConcluido = () => {
    setTableUpdateTrigger((prev) => prev + 1);
    handleCloseModal();
  };

  return (
    <Box>
      <Box
        component={Paper}
        padding={1}
        display="flex"
        alignItems="center"
        justifyContent="space-between"
      >
        <Box marginLeft={2}>
          <Typography variant="h3">Retorno</Typography>
        </Box>
        <Box width="250px">
          <Button
            size="small"
            fullWidth
            variant="contained"
            onClick={handleOpenModal}
          >
            {" "}
            adicionar retorno{" "}
          </Button>
        </Box>
      </Box>

      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box>
            <AddRetorno
              handleCloseModal={handleCloseModal}
              onCadastroConcluido={handleCadastroConcluido}
            />
          </Box>
        </Box>
      </Modal>

      <Box marginTop={3}>
        <TableRetorno tableUpdateTriggerPai={tableUpdateTrigger}/>     
      </Box>
    </Box>
  );
}

export default Retorno;
