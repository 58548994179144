import React, {  useEffect, useState } from 'react';
import { Box, Grid,  TextField, Typography,Button } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';
import OrcamentoService from '../../../../services/OrcamentoService';
import { MaskValor } from '../../../../utils/mascaras';




function FormCalcularMaodeObra({  IdOrcamentoEstrutura, orcamentoId }) {
  const dispatch = useDispatch();

  const [valorTotalMaoDeObra, setValorTotalMaoDeObra] = useState(0);
 

  function handleCalcularMaodeObra(data) {
   
    const dadosParaEnviar = {     
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,  
      orcamentoId: orcamentoId
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.calcularMaodeObra(dadosParaEnviar).then((res) => {
      setValorTotalMaoDeObra(res.valorTotal)     
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  }



  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const res = await OrcamentoService.trazerEtapaDoze(id);
    dispatch(changeloading({ open: false }));
    setValorTotalMaoDeObra(res.valor_mao_de_obra)
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = () => {
    if (orcamentoId) {
      handleCalcularMaodeObra();
    } else {
      handleCalcularMaodeObra();
    }
  };
  return (
    <Box>
     
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant='h5' marginLeft={2} marginTop={3}>Calcular Mão de Obra</Typography>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} >
            <Button
              type='submit'
              variant='contained'            
              onClick={handleClick}             
            >Gerar valor</Button>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} >
            <TextField
              label='Valor'
              variant='outlined'
              fullWidth
              size='small'
              value={MaskValor(valorTotalMaoDeObra)}
              onChange={(e) => setValorTotalMaoDeObra(e.target.valeu)}
              disabled
            />
          </Grid>

        </Grid>   
    </Box>
  )
}

function mapStateToProps(state) {
  return {  
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,     
  }
}

export default connect(mapStateToProps) (FormCalcularMaodeObra);
