import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import { Confirm, TableComponet } from "../../../components";
import AddClientes from "./AddClientes";
import ClienteService from "../../../services/ClienteService";
import SearchIcon from "@mui/icons-material/Search";
import { useDebounce } from "../../../hooks/UseDebounce";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeloading } from "../../../store/actions/loading.action";
import { changeNotify } from "../../../store/actions/notify.actions";

const headers = [
  {
    id: "nome",
    label: "Nome",
    props: {
      align: "left",
    },
  },
  {
    id: "razao_social",
    label: "razao Social",
    props: {
      align: "right",
    },
  },

  {
    id: "email",
    label: "Email",
    props: {
      align: "right",
    },
  },
  {
    id: "telefone",
    label: "Telefone",
    props: {
      align: "right",
    },
  },
  {
    id: "cpf",
    label: "Cpf",
    props: {
      align: "right",
    },
  },
  {
    id: "cnpj",
    label: "Cnpj",
    props: {
      align: "right",
    },
  },
  {
    id: "endereco",
    label: "Endereço",
    props: {
      align: "right",
    },
  },
  {
    id: "actionRows",
    label: "Ações",
    props: {
      align: "right",
    },
  },
];
function Clientes() {
  //const da tabela
  const [clientes, setClientes] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
  //const auto complete
  const [optionsClientes, setOptionsClientes] = useState([]);
  const [busca, setBusca] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const { register } = useForm({ resolver: yupResolver() });
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  // Define o efeito encapsulado
  const fetchClientes = () => {
    setIsLoading(true);
    debounce(() => {
      ClienteService.get(busca).then((res) => {
        setIsLoading(false);
        setClientes(res.clientes || []);
        setOptionsClientes(
          res.clientes.map((cliente) => ({
            id: cliente.id,
            label: cliente.nome,
          }))
        );
      });
    });
  };

  useEffect(() => {
    fetchClientes();
  }, [busca, tableUpdateTrigger]);

  const handleEdit = (clienteId) => {
    navigate(`/editcliente/${clienteId.id}`, { state: { clienteId } });
  };

  //const do confirm
  const handleOpenConfirmDialog = (id) => {
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });

  const handleDelete = () => {
    const idToDelete = confirmar.id.id;
    ClienteService.Delete(idToDelete)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        fetchClientes();
        setTableUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddClientes />
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Box padding={2} width={400} marginTop="-20px" marginLeft="-10px">
              <Autocomplete
                openText="Abrir"
                closeText="Fechar"
                noOptionsText="Sem opções"
                loadingText="Carregando..."
                disablePortal
                options={optionsClientes}
                onInputChange={(_, newValue) => setBusca(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("users_id")}
                    label="Pesquisar..."
                    variant="outlined"
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse cliente?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
              />
            </Box>
            <Box>
              <TableComponet
                headers={headers}
                data={clientes}
                labelCaption={"Nenhum Produto encontrado!!"}
                labelTable={"clientes"}
                handlerEditarAction={(clienteId) => {
                  handleEdit(clienteId);
                }}
                handlerDeletarAction={(clienteId) => {
                  handleOpenConfirmDialog(clienteId);
                }}
                request
                qdtPage={totalPage}
                loading={loading}
                setData={setClientes}
                tableUpdateTrigger={tableUpdateTrigger}
                handlerRequest={async (page, size) => {
                  setLoading(true);
                  ClienteService.get("", page, size).then((data) => {
                    setLoading(false);
                    setClientes(data.clientes || []);
                    setTotalPages(data.totalPages || 0);
                    return data;
                  });
                  return [];
                }}
              />
            </Box>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
}

export default Clientes;
