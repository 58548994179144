import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, } from '@mui/material';
import { TableComponet } from '../../../components';


import AddEntrada from './AddEntrada';
import EstoqueService from '../../../services/EstoqueService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { MaskDate, MaskValor } from '../../../utils/mascaras';

function Entradas({ onUpdate }) {
  const dispatch = useDispatch();
  const [entrada, setEntrada] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [total, setTotal] = useState('');

  async function getEntrada() {
    const res = await EstoqueService.get();
    if (res) {
      setTotal(res.total)
      setEntrada(res.entradas.map(entry => ({
        ...entry,
        data_entrada: MaskDate(entry.data_entrada)
      })));      
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getEntrada();
        dispatch(changeloading({ open: false }));
      }
    };
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  const headers = [

    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: "data_entrada",
      label: "Data",
      props: {
        align: 'right',
      },
    },

    {
      id: 'qtd_entrada',
      label: 'Qtd Entrada',
      props: {
        align: 'right',
      },
    },
    {
      id: 'valor_entrada',
      label: 'Valor',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ];

  //atualizar a tabela como props
  const fetchData = async () => {
    setMounted(true)
    dispatch(changeloading({ open: true, msg: "Carregando" }));
    if (mounted) {
      await getEntrada();
      dispatch(changeloading({ open: false }));
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted, onUpdate]);


  return (
    <Box >
      <Grid container spacing={2} >

        <Grid item xs={12}>
          <AddEntrada onUpdate={() => fetchData()} />
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Box component={Paper} padding={2}>
              <TableComponet
                headers={headers}
                data={entrada}
                labelCaption={'Nenhuma entrada encontrada!!'}
                labelTable={'Entradas'}
                handlerEditarAction={''}
                handlerDeletarAction={''}
              />
              <Typography variant='h1' sx={{ fontSize: '20px!important', marginTop: '10px' }}>Total de entrada no estoque do dia: {MaskValor(total)}</Typography>
            </Box>
          </Box>
        </Grid>



      </Grid>
    </Box>
  )
}

export default Entradas;