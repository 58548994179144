import * as React from "react";
import { Typography, IconButton, Toolbar, Box, Tooltip, Avatar, Menu, MenuItem, } from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import { Container, BoxAvatar } from "./Styles";
import { styled } from "@mui/material/styles";
import Sidebar from "../Sidebar/Sidebar";
import MuiAppBar from "@mui/material/AppBar";

import { useNavigate } from "react-router-dom";


import { ConfirmationNumber, SingleBedOutlined } from "@mui/icons-material";
import { removeToken } from "../../../store/actions/authActions";
import { useDispatch } from "react-redux";
import { Http } from "../../../api";
import { changeNotify } from "../../../store/actions/notify.actions";
import SemFoto from '../../../image/sem-foto.png'


const drawerWidth = 230;

const Main = styled("main", { shouldForwardProp: (prop) => prop !== "open" })(
  ({ theme, open }) => ({
    flexGrow: 1,
    padding: theme.spacing(3),
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: `-${drawerWidth}px`,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: 0,
    }),
  })
);

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
  justifyContent: "flex-end",
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  transition: theme.transitions.create(["margin", "width"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: `${drawerWidth}px`,
    transition: theme.transitions.create(["margin", "width"], {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

function AvatarAppBar(props) {
  const [user, setUser] = React.useState({});
  const [anchorElUser, setAnchorElUser] = React.useState(null);
  const [open, setOpen] = React.useState(true);
 
  const navigate = useNavigate();
  const [foto, setFoto] = React.useState(null);
  const [fotoRetorno, setFotoRetorno] = React.useState('');

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };
  
  React.useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser || {});
  
    // Adiciona um event listener para monitorar as mudanças no localStorage
    const handleStorageChange = (event) => {
      if (event.key === "user") {
        setUser(JSON.parse(event.newValue));
      }
    };
  
    window.addEventListener("storage", handleStorageChange);  
    // Remove o event listener quando o componente é desmontado
    return () => {
      window.removeEventListener("storage", handleStorageChange);
    };
  }, []);


  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const dispatch = useDispatch();

  const logout = () => {
    dispatch(removeToken());
    navigate("/login");
  };

  const trocarFoto = async (event) => {
    const foto = event.target.files[0];
    setFoto(foto);
    const formData = new FormData();
    formData.append('foto_perfil', foto);
  
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    await Http.post(`/trocarfotoperfil/${user.id}`, formData, config)
      .then((response) => {
        if (response) {
          // Atualiza o estado com a nova foto de perfil
          setFotoRetorno(response.data.foto);
  
          // Atualiza o localStorage com a nova foto de perfil
          const updatedUser = { ...user, foto_perfil: response.data.foto };
          localStorage.setItem("user", JSON.stringify(updatedUser));
  
          // Dispara um evento personalizado para notificar a alteração no localStorage
          window.dispatchEvent(new StorageEvent('storage', { key: 'user', newValue: JSON.stringify(updatedUser) }));
          dispatch(changeNotify({ open: true, class: "success", msg: "A foto foi atualizada.", }));
          handleClose();
        }
      })
      .catch((error) => {
       
        dispatch(changeNotify({ open: true, class: "error", msg: "Erro ao cadastrar Foto!", }));
      });
    handleClose();
  };
  


  React.useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth > 960) {
        setOpen(true);
      } else {
        setOpen(false);
      }
    };

    handleResize(); // adicionando a verificação ao carregar a página

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const handleDrawerOpen = () => {
    setOpen(!open);
  };
  
  return (
    <Container>
      <AppBar position="fixed" open={open}>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            aria-label="open drawer"
            sx={{ mr: 1 }}
            onClick={() => handleDrawerOpen()}
          >
            <MenuIcon fontSize="large" />
          </IconButton>
          <Typography
            variant="h5"
            noWrap
            component="div"
            fontWeight={600}
            sx={{ flexGrow: 1, display: { xs: "none", sm: "block" } }}

          ></Typography>
          <IconButton>
            <SingleBedOutlined href="#" />
          </IconButton>

          <IconButton>
            <ConfirmationNumber href="#" />
          </IconButton>

          <Box sx={{ flexGrow: 0 }}>
            <Tooltip title="Configurações">
              <BoxAvatar>
                <div className="name">{user.name || 'Usuário'}</div>

                <IconButton onClick={handleOpenUserMenu} sx={{ p: 1 }}>
                  <Avatar
                    alt="Remy Sharp"
                    src={user.foto_perfil ? `${process.env.REACT_APP_ROOT_URL}/storage/app/public/${user.foto_perfil}` : SemFoto }
                  />
                </IconButton>
              </BoxAvatar>
            </Tooltip>
            <Menu
              sx={{ mt: "45px" }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              keepMounted
              transformOrigin={{
                vertical: "top",
                horizontal: "right",
              }}
              open={Boolean(anchorElUser)}
              onClose={handleCloseUserMenu}
            >
              <MenuItem onClick={logout}>
                <Typography textAlign="center" fontSize={16}>
                  Sair
                </Typography>
              </MenuItem>
              <MenuItem>
                <label htmlFor="foto">
                  <input
                    style={{ display: "none" }}
                    id="foto"
                    name="foto"
                    key="foto"
                    type="file"
                    onChange={trocarFoto}
                  />
                  <Typography textAlign="center" fontSize={16}>
                    Trocar foto do perfil
                  </Typography>
                </label>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <Sidebar open={open} />
      <Main open={open}>
        <DrawerHeader />
        {props.children}
      </Main>

    </Container>
  );
}


export default AvatarAppBar;