import { Http } from "../api";

class FornecedoresService {
  static async create(obj) {
    let res = null;
    await Http.post("/fornecedor", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async get(searchString, page = 1, pageSize = 5) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/fornecedor", params);
      res = response.data;    
    } catch (error) {
      // Lida com erros, se necessário
     
    }

    return res;
  }

  static async Update(id, data) {
    let res = null;

    await Http.put(`fornecedor/${id}`,data).then((response) => {
      res = response.data;

    });
    return res;
  }

  static async Delete(id) {
    let res = null;
    await Http.delete(`fornecedor/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  
}
export default FornecedoresService;