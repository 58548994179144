import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Divider,
} from "@mui/material";
import { useDispatch } from "react-redux";
import { changeloading } from "../../../store/actions/loading.action";
import { changeNotify } from "../../../store/actions/notify.actions";
import generatePDF, { Margin } from "react-to-pdf";
import { MaskDate } from "../../../utils/mascaras";
import Logo from "../../../image/logo.jpg";
import FotoOrcamento from "../../../image/fotoOrcamento.jpg";
import VendasService from "../../../services/VendasService";

function Venda() {
  const dispatch = useDispatch();
  const [dadosRecebidos, setDadosRecebidos] = useState(false);
  const [idVenda, setIdVenda] = useState("");
  const [itens, setItens] = useState([]);
  const [dadosItensVenda, setDadosItensVenda] = useState([]);
  const [cliente, setCliente] = useState({});
  const [valorTotalVenda, setValorTotalVenda] = useState("");
  const [valorLiquido, setValorLiquido] = useState("");
  const [desconto, setDesconto] = useState("");
  const [condPagamento, setCondPagamento] = useState([]);
  const [dataVenda, setDataVenda] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);

  // Função para gerar o orçamento
  const gerarOrcamento = async () => {
    setDadosRecebidos(true);
    const dadosParaEnviar = {
      idVenda: idVenda,
    };

    try {
      dispatch(
        changeloading({ open: true, msg: "Gerando orçamento aguarde.." })
      );
      const resposta = await VendasService.gerarContrato(dadosParaEnviar);
      setItens(resposta.itens);
      setDadosItensVenda(resposta.dadosOrcamentoEstrutura);
      setCliente(resposta.cliente);
      setValorTotalVenda(resposta.valorTotalVenda);
      setDesconto(resposta.desconto);
      setCondPagamento(resposta.pagamentos);
      setValorLiquido(resposta.valorLiquido);
      setDataVenda(resposta.dataVenda);
      setIsLoading(false);
      dispatch(
        changeNotify({ open: true, class: "success", msg: resposta.message })
      );
    } catch (error) {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "Erro ao gerar orçamento",
        })
      );
    } finally {
      dispatch(changeloading({ open: false }));
    }
  };

  const persolanizacao = {
    //se for abrir method open/ salvar = save
    method: "save",
    fileName: `${cliente.nome}.pdf`,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "A4",
      // default is 'portrait'
      orientation: "portrait",
    },
  };
  const recuperarCpnteudoParaPdf = () => document.getElementById("conteudo");

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={3}>
          {!dadosRecebidos && (
            <TextField
              type="text"
              fullWidth
              size="small"
              label="Id Venda"
              value={idVenda}
              onChange={(e) => setIdVenda(e.target.value)}
            />
          )}
        </Grid>
        <Grid item xs={12} sm={6} md={5} lg={4}>
          {!dadosRecebidos && (
            <Button fullWidth variant="contained" onClick={gerarOrcamento}>
              Gerar Contrato de Venda
            </Button>
          )}
        </Grid>
      </Grid>
      {dadosRecebidos && (
        <>
          <Box id="conteudo">
            <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
              <img
                src={FotoOrcamento}
                alt="display"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <div
              style={{
                position: "absolute",
                top: 950,
                left: 210,
                width: "70%",
                height: "50%",
                backgroundImage: `url(${Logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                opacity: 0.03, // Ajuste a opacidade conforme necessário
                pointerEvents: "none", // A marca d'água não é interativa
              }}
            ></div>

            {dadosItensVenda &&
              dadosItensVenda.modelosCobertura &&
              dadosItensVenda.modelosCobertura.map((modelo, index) => (
                <Box
                  key={`modelo-box-${index}`}
                  marginTop={1}
                  marginBottom={1}
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100%",
                    height: "40px",
                    backgroundColor: "grey.200",
                  }}
                >
                  <Typography
                    key={`modelo-${index}`}
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "5px",
                      fontWeight: "600",
                    }}
                  >
                    Tipo de cobertura: {modelo.modeloCobertura}
                  </Typography>
                  {dadosItensVenda.servicos &&
                    dadosItensVenda.servicos[index] && (
                      <Typography
                        key={`servico-${index}`}
                        sx={{
                          position: "absolute",
                          top: "8px",
                          left: "255px",
                          fontWeight: "600",
                        }}
                      >
                        Tipo de serviço:{" "}
                        {dadosItensVenda.servicos[index].servico}
                      </Typography>
                    )}
                  {dadosItensVenda.tamanhos &&
                    dadosItensVenda.tamanhos[index] && (
                      <Typography
                        key={`servico-${index}`}
                        sx={{
                          position: "absolute",
                          top: "8px",
                          left: "555px",
                          fontWeight: "600",
                        }}
                      >
                        Largura : {dadosItensVenda.tamanhos[index].largura} mm
                      </Typography>
                    )}
                  {dadosItensVenda.tamanhos &&
                    dadosItensVenda.tamanhos[index] && (
                      <Typography
                        key={`servico-${index}`}
                        sx={{
                          position: "absolute",
                          top: "8px",
                          left: "855px",
                          fontWeight: "600",
                        }}
                      >
                        Projeção: {dadosItensVenda.tamanhos[index].projecao} mm
                      </Typography>
                    )}
                </Box>
              ))}
            {/* Cabeçalho */}

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "130px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "10px",
                }}
              >
                Nome: {cliente.nome}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "10px",
                }}
              >
                End: {cliente.endereco}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "75px",
                  left: "10px",
                }}
              >
                Cidade: {cliente.cidade}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "110px",
                  left: "10px",
                }}
              >
                Email: {cliente.email}
              </Typography>
              {/* fila da direita */}
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "810px",
                }}
              >
                Cpf/Cnpj: {cliente.cpf || cliente.cnpj}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "810px",
                }}
              >
                Telefone: {cliente.telefone}
              </Typography>

              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "75px",
                  left: "810px",
                }}
              >
                Bairro: {cliente.bairro}
              </Typography>
              {dataVenda && (
                <Typography
                  variant="h4"
                  sx={{
                    position: "absolute",
                    top: "110px",
                    left: "810px",
                  }}
                >
                  Data: {MaskDate(dataVenda)}
                </Typography>
              )}
            </Box>

            {/* tabela */}
            <Box marginBottom={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "grey.200" }}>
                      <TableCell sx={{ fontSize: "12px" }}>
                        Material utilizado
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        Quantidade
                      </TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>Categoria</TableCell>
                      <TableCell sx={{ fontSize: "12px" }}>
                        Unidade de Medidas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      itens.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {row.nomeProduto}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {row.quantidade}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {row.subCategoria}
                          </TableCell>
                          <TableCell sx={{ fontSize: "12px" }}>
                            {row.uniMedida}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "100px",
                backgroundColor: "grey.400",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "810px",
                  fontWeight: "600",
                }}
              >
                Valor Total: {valorTotalVenda}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: "38px",
                  left: "810px",
                  fontWeight: "600",
                }}
              >
                Desconto: {desconto}
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: "68px",
                  left: "810px",
                  fontWeight: "800",
                }}
              >
                Valor com desconto: {valorLiquido}
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "40px",
                backgroundColor: "grey.300",
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "450px",
                  fontWeight: "500",
                }}
              >
                Condições de Pagamento
              </Typography>
            </Box>

            {condPagamento.map((pagamento, index) => (
              <Box
                key={index}
                sx={{
                  position: "relative",
                  width: "100%",
                  maxWidth: "100%",
                  height: "auto",
                  marginBottom: "10px",
                  padding: "10px",
                  border: "1px solid #ccc",
                }}
              >
                <Typography sx={{ fontWeight: "600" }}>
                  Parcela {index + 1}
                </Typography>
                <Typography sx={{ fontWeight: "400" }}>
                  Tipo de pagamento: {pagamento.tipo_pagamento}
                </Typography>
                <Typography sx={{ fontWeight: "400" }}>
                  Valor da parcela: {pagamento.valor_parcela}
                </Typography>
                <Typography sx={{ fontWeight: "400" }}>
                  Data de vencimento:{" "}
                  {new Date(pagamento.data_vencimento).toLocaleDateString()}
                </Typography>
              </Box>
            ))}

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "3px",
                  left: "450px",
                  fontWeight: "500",
                }}
              >
                Horário de Instalação de segunda à sexta das 8 às 17:30h
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "40px",
                backgroundColor: "grey.300",
                marginTop: "-28px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "450px",
                  fontWeight: "500",
                }}
              >
                Observações
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
              }}
            >
              <TextField
                fullWidth
                multiline
                maxRows={6}
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
            </Box>

            <Box
              marginTop={5}
              marginBottom={2}
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "3000px",
                backgroundColor: "grey.200",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "63px",
                  left: "30px",
                  fontWeight: "500",
                  fontSize: "20px!important",
                }}
              >
                Qualificação do Contratante no Anverso , Contratado Toldos
                Indaiatuba, razão social OMV Garcia comércio <br />
                de Toldos e produtos em geral ME, estabelecido na Rua Dos
                Indaiás, 1124 - Jd Santa Cruz - Indaiatuba/SP <br />
                CNPJ 20.119.622/0001-40 tem entre si, justo e contratado o
                presente que se regerá pelas cláusulas seguintes. <br />
                A Contratada prestará ao Contratante os serviços acordados,
                cujos elementos, preços, modalidades de pagamento e <br />
                obra estão especificados no anverso deste contrato. <br />{" "}
                <br />
                <b>
                  {" "}
                  DA INCLUSÃO, EXCLUSÃO E ALTERAÇÃO DAS INFORMAÇÕES OU
                  CANCELAMENTO
                </b>
                <br />
                01. Havendo omissão ou desistência imotivado após o "de acordo"
                deste contrato, o infrator incorrerá em em multa compensatória
                no valor correspondente a 10% (dez por cento) do contrato.
                <br />
                <br />
                02. O CONTRATANTE se obriga a comunicar por escrito a Toldos
                Indaiatuba as alterações ocorridas no local de instalação do
                serviço contratado, se houver após a medição, ciente que pode
                não haver tempo hábil para alteração do produto final, se assim
                for necessário, ficando o CONTRATANTE responsável por custos
                adicionais da alteração.
                <br />
                <br />
                <b>DO PREÇO E DAS CONDIÇÕES DE PAGAMENTO</b>
                <br />
                03. O preço total, a forma de cobrança e eventual parcelamento,
                estão descritos no anverso deste contrato.
                <br />
                <br />
                04. Pelo não pagamento na data acordada, o CONTRATANTE
                sujeita-se ao pagamento de multa de 2% juros de mora calculados
                à taxa de 0,66% ao mês, após 60 dias de atraso também os
                honorário advocatícios, legalmente permitidas.
                <br />
                <br />
                05. Nos casos de descontos concedidos pelo pagamento à vista os
                mesmos só serão válidos para pagamentos nas datas acordadas,
                ficando o desconto suspenso quaso não seja cumprido.
                <br />
                <br />
                06. O atraso de pagamento por mais de 90 (noventa dias)
                acarretará o imediato vencimento das parcelas subsequentes,
                podendo a Toldos Indaiatuba, emitir contra o CONTRATANTE título
                de crédito no valor do principal e dos encargos incidentes.
                <br />
                <br />
                <b>DO PERÍODO DE ENTREGA E INSTALAÇÃO</b>
                <br />
                07. As datas de entrega e instalação começam a validar a partir
                da compensação da entrada, exceto no caso de acordado sem
                entrada e deve estar descrito no anverso deste instrumento.
                <br />
                <br />
                08. O período básico de entrega dos serviços é de 21 à 90 dias
                dependendo do produto escolhido, o prazo máximo deve estar
                descrito no anverso, podendo por motivos extrá-ordinarios (ex.
                greves, fenômenos naturais, falta de energia, falta dágua, falta
                de combustíveis, crises sanitárias...) ser acrescido de mais
                dias ou suspenso temporariamente até a regularização pelas
                autiridades, com o devido aviso prévio.
                <br />
                <br />
                09. Fica o CONTRATANTE ciente que os materiais escolhidos são de
                fabricação de terceiros, cabendo a Toldos Indaiatuba a
                manufatura destes para entrega do produto final, portanto caso
                haja falta destes insumos o prazo de entrega pode ser acrescido
                em no máximo 30 dias para regularização da disponibilidade do
                referido insumo ou ainda a escolha de outro material através dos
                catálogos. <br />
                <br />
                10. Esgotados os prazos normais acrescido dos dias extras, caso
                haja necessidade, descritos nas clausulas 9 e 10 poderá o
                Contratante requerer a rescisão deste instrumento sem ônus a
                qualquer parte, sendo feita a devolução dos valores pagos
                acrescidos de correção monetária de acordo ao IPCA.
                <br />
                <br />
                11. No caso de condições de pagamentos parceladas em boleto em
                que o prazo máximo de entrega seja atingido e ainda haja
                parcelas a vencer a Toldos Indaiatuba prorrogará os vencimentos
                na proporção do atraso, caso a opção escolhida seja a de
                parcelamento no cartão de crédito não será possivel a
                prorrogação pois esses pagamentos são realizados no ato do
                contrato.
                <br />
                <br />
                12. O período básico de entrega do serviço que dependa de ART´S
                ou RRT´s ou ainda projetos arquitetônicos e/ou de engenharia que
                precisam ser liberados, começará a contar após liberação destas
                pendências.
                <br />
                <br />
                13. O CONTRANTE deve indicar na instalação onde passam quaisquer
                tipos de tubulação, a Toldos Indaiatuba não se responsabiliza
                por qualquer dano causado oriundo de vazamento, curto circuito
                devido a perfuração das tubulações, assim como possíveis
                quebras, trincas em pisos e paredes, pois não é possível
                analisar as condições dos revestimentos e paredes antes da
                instalação.
                <br />
                <br />
                14. O serviço de instalação requer operações de impacto com
                equipamentos pesados, escadas e ferramentas, recomenda-se que
                acabamentos finos e pinturas sejam realizadas após a instalação
                do serviço contratado, pois podem ocorrer pequenas avarias.
                <b>DA LIMITAÇÃO DE INDENIZAÇÃO</b>
                <br />
                15. Não haverá restituição financeira ou indenização para os
                casos de fenômenos naturais danificarem o produto tais como
                oscilações de energia. <br />
                <br />
                16. Em caso de erro por responsabilidade da Toldos Indaiatuba na
                opção contratada, a Toldos Indaiatuba fará os reparos
                necessários ou a substituição do item contratado de acordo a
                gravidade.
                <br />
                <br />
                17. Em coberturas, toldos ou pergolados que seja instalados a
                partir de beira, é de extrema importancia a impermeabilização
                deste, mais de 90% dos casos de vazamentos ocorrem nas
                pingadeiras destes beirais, se após instalados for detectado um
                vazamento e após comprovação junto ao cliente, o vazamento for
                por esse motivo, será cobrado o valor de uma visita. <br />
                <br />
                18. A escolha dos materiais estão descritos no anverso deste
                instrumento, não sendo cabível reclamações de cores e tamanhos,
                tendo em vista que no ato da visita o contratante escolheu as
                cores, acompanhou as medições e deu aceite no contrato conforme
                descrito.
                <br />
                <br />
                <b> DA GARANTIA E DURABILIDADE E LIMITAÇÕES</b>
                <br />
                19. A garantia das vedações são de 6 meses e mais 6 meses com
                custo de uma visita, (verificar o valor da visita no período
                requisitado), ficando ciente que as vedações requerem
                manutenções preventivas a cada 1 ano; A grantia por problemas de
                instalações e parte elétrica é de 1 ano e possíveis problemas de
                fabricação de até 2 anos sendo no primeiro ano direto com o
                Contratado e após esse período acionaremos o fabricante para
                análise.
                <br />
                <br />
                20. Em Serviços de manutenções, pinturas, reparos, substituição
                de materiais onde já exista uma estrutura a Toldos Indaiatuba
                não tem quaisquer responsabilidades na garantia do conjunto, a
                garantia limita-se a 3 meses somente do serviço que será
                realizado.
                <br />
                <br />
                21. Na entrega dos produtos é necessário que alguém responsável
                faça a vistoria do item adquirido, não serão aceitas reclamações
                de material riscado, manchado, torto após a entrega. Sempre
                registramos em fotografia ou vídeo o produto para sanar
                quaisquer dúvidas.
                <br />
                <br />
                22. Ondulações em policarbonato, são situações comuns devido a
                vários fatores como dilatação causada pelas oscilações de
                temperatura, porém em nada alteram sua durabilidade e garantia,
                ficando o CONTRATANTE ciente destas possibilidades.
                <br />
                <br />
                23. Rugas em toldos retráteis são comuns, qualquer tipo de solda
                em materiais flexiveis pode causar tais rugas. Nos toldos com
                visor transparente essas rugas são quase que inevitáveis pois os
                materiais soldados sofrem alterações com a mudanças de
                temperaturas em momentos diferentes, ficando ciente o
                Contratado.
                <br />
                <br />
                24. No caso de toldos retráteis alertamos que são toldos
                redutores de sol e chuva e não são bloqueadores, principalmente
                se for dentro de vão, pois sempre haverá um espaço nas lateriais
                do toldo que são naturais do seu sistema.
                <br />
                <br />
                25. Será enviado um manual de utilização ao Contratante com
                todas as informaçõe utéis porém em especial a questão de ventos
                fortes é necessário estar presente nesse documento que é de suma
                importancia o recolhimento imediato dos toldos retráteis pois é
                impossível garantir sua integridade, mesmo nos casos que haja
                sensor de vento, se houver uma rajada de vento repentina pode
                não haver tempo hábil para que o motor recolha o toldo.
              </Typography>
            </Box>

            <Box
              marginTop={5}
              marginBottom={2}
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "45px",
                  fontWeight: "500",
                  fontSize: "20px!important",
                }}
              >
                Declaro ter lido e estar de acordo com todas cláusulas deste
                contrato, e que estou recebendo uma via do contrato, e das
                cláusulas contratuais
              </Typography>
            </Box>

            <Box display="flex" marginTop={25}>
              <Box
                marginTop={5}
                marginBottom={2}
                sx={{
                  position: "relative",
                  width: "33%",
                  maxWidth: "33%",
                  height: "60px",
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: "black!important",
                    height: "5%",
                    width: "90%",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "500",
                    fontSize: "20px!important",
                  }}
                >
                  {" "}
                  Local e Data
                </Typography>
              </Box>
              <Box
                marginTop={5}
                marginBottom={2}
                sx={{
                  position: "relative",
                  width: "33%",
                  maxWidth: "33%",
                  height: "60px",
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: "black!important",
                    height: "5%",
                    width: "90%",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "500",
                    fontSize: "20px!important",
                  }}
                >
                  {" "}
                  Assinatura Contratante
                </Typography>
              </Box>
              <Box
                marginTop={5}
                marginBottom={2}
                sx={{
                  position: "relative",
                  width: "33%",
                  maxWidth: "33%",
                  height: "60px",
                }}
              >
                <Divider
                  sx={{
                    backgroundColor: "black!important",
                    height: "5%",
                    width: "90%",
                  }}
                />
                <Typography
                  sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    fontWeight: "500",
                    fontSize: "20px!important",
                  }}
                >
                  {" "}
                  Toldos Indaiatuba
                </Typography>
              </Box>
            </Box>
          </Box>
          {/* Botão para gerar PDF */}
          <Box marginTop={10}>
            <Button
              variant="contained"
              disabled={loadingPDF}
              onClick={() => {
                setLoadingPDF(true); // Habilita o indicador de carregamento
                generatePDF(recuperarCpnteudoParaPdf, persolanizacao)
                  .then(() => setLoadingPDF(false)) // Desabilita o indicador de carregamento após a conclusão
                  .catch((error) => {
                    console.error("Erro ao gerar PDF:", error);
                    setLoadingPDF(false); // Desabilita o indicador de carregamento em caso de erro
                  });
              }}
            >
              {loadingPDF ? "Gerando PDF..." : "Gerar PDF"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Venda;
