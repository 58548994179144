import { Routes, Route, Navigate } from "react-router-dom";
import {
  Categoria,
  Dashboard,
  EditProdutos,
  Entradas,
  Fornecedores,
  Login,
  Orcamento,
  Produtos,
  Saida,
  Servico,
  Usuario,
  TabelaOrcamento,
  TableOrcamento,
  Venda,
  TabelaVendas,
  Pendentes,
  EditFornecedor,
  TabelaCompraFinalizada,
  Revisao,
  AddProdutoCompra,
  Calendario,
  ServiceDay,
  NovaVenda,
  EntradaSaida,
  TableEntradaMes,
  TableSaidaMes,
  TableEntradaPrevista,
  TableSaidaPrevistaMes,
  TableSaidaAtrasada,
  EditClientes,
  Retorno,
  RegrasMultiplicacao,
  ControleMenu
} from "../pages";
import PrivateRouter from "./PrivateRouter";
import Clientes from "../pages/Vendas/Clientes";
import SubCategoria from "../pages/Almoxerifado/SubCategoria/SubCategoria";
import TableEntradaPrevistaAtrasada from "../pages/Financeiro/TableEntradaAtrasada";



export const AppRoutes = () => {
  return (
    <Routes>
      {/* Rotas admin */}
      <Route
        path="/dashboard"
        element={
          <PrivateRouter>
            {" "}
            <Dashboard />
          </PrivateRouter>
        }
      />
       <Route path="/configuracao" element={<PrivateRouter>  <ControleMenu /> </PrivateRouter>} />
      <Route
        path="/usuario"
        element={
          <PrivateRouter>
            {" "}
            <Usuario />
          </PrivateRouter>
        }
      />
      <Route
        path="/servico"
        element={
          <PrivateRouter>
            {" "}
            <Servico />
          </PrivateRouter>
        }
      />
      {/* Rotas Almoxerifado */}
      <Route
        path="/produtos"
        element={
          <PrivateRouter>
            {" "}
            <Produtos />
          </PrivateRouter>
        }
      />
      <Route
        path="/entradas"
        element={
          <PrivateRouter>
            {" "}
            <Entradas />
          </PrivateRouter>
        }
      />
      <Route
        path="/saida"
        element={
          <PrivateRouter>
            {" "}
            <Saida />
          </PrivateRouter>
        }
      />
      <Route
        path="/fornecedor"
        element={
          <PrivateRouter>
            {" "}
            <Fornecedores />
          </PrivateRouter>
        }
      />
      <Route
        path="/categoria"
        element={
          <PrivateRouter>
            {" "}
            <Categoria />
          </PrivateRouter>
        }
      />
      <Route
        path="/subcategoria"
        element={
          <PrivateRouter>
            {" "}
            <SubCategoria />
          </PrivateRouter>
        }
      />
      <Route
        path="/editprodutos/:produtoId"
        element={
          <PrivateRouter>
            {" "}
            <EditProdutos />
          </PrivateRouter>
        }
      />
      <Route
        path="/editfornecedor/:fornecedorId"
        element={
          <PrivateRouter>
            {" "}
            <EditFornecedor />
          </PrivateRouter>
        }
      />
      {/* rotas vendas */}
      <Route
        path="/clientes"
        element={
          <PrivateRouter>
            {" "}
            <Clientes />
          </PrivateRouter>
        }
      />
      <Route
        path="/editcliente/:clienteId"
        element={
          <PrivateRouter>
            {" "}
            <EditClientes />
          </PrivateRouter>
        }
      />
      <Route
        path="/orcamento"
        element={
          <PrivateRouter>
            {" "}
            <Orcamento />
          </PrivateRouter>
        }
      />
      <Route
        path="/tableorcamento"
        element={
          <PrivateRouter>
            {" "}
            <TableOrcamento />
          </PrivateRouter>
        }
      />
      <Route
        path="/retorno"
        element={
          <PrivateRouter>
            {" "}
            <Retorno />
          </PrivateRouter>
        }
      />
      <Route
        path="/venda"
        element={
          <PrivateRouter>
            {" "}
            <Venda />
          </PrivateRouter>
        }
      />
      <Route
        path="/todasvendas"
        element={
          <PrivateRouter>
            {" "}
            <TabelaVendas />
          </PrivateRouter>
        }
      />
      <Route
        path="/NovaVenda"
        element={
          <PrivateRouter>
            {" "}
            <NovaVenda />
          </PrivateRouter>
        }
      />
      {/* rotas compra */}
      <Route
        path="/comprapendentes"
        element={
          <PrivateRouter>
            {" "}
            <Pendentes />
          </PrivateRouter>
        }
      />
      <Route
        path="/comprafinalizadas"
        element={
          <PrivateRouter>
            {" "}
            <TabelaCompraFinalizada />
          </PrivateRouter>
        }
      />
      <Route
        path="/comprarevisao"
        element={
          <PrivateRouter>
            {" "}
            <Revisao />
          </PrivateRouter>
        }
      />
      {/* rotas calendario */}
      <Route
        path="/calendario"
        element={
          <PrivateRouter>
            {" "}
            <Calendario />
          </PrivateRouter>
        }
      />
      {/* rotas para produção */}
      <Route
        path="/dayservice"
        element={
          <PrivateRouter>
            {" "}
            <ServiceDay />
          </PrivateRouter>
        }
      />

      {/* rotas para financeiro */}
      <Route
        path="/entradasaida"
        element={
          <PrivateRouter>
            {" "}
            <EntradaSaida />
          </PrivateRouter>
        }
      />
      <Route
        path="/regras/multiplicacao"
        element={
          <PrivateRouter>
            {" "}
            <RegrasMultiplicacao />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/entrada/mes"
        element={
          <PrivateRouter>
            {" "}
            <TableEntradaMes />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/saida/mes"
        element={
          <PrivateRouter>
            {" "}
            <TableSaidaMes />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/entrada/prevista"
        element={
          <PrivateRouter>
            {" "}
            <TableEntradaPrevista />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/saida/prevista"
        element={
          <PrivateRouter>
            {" "}
            <TableSaidaPrevistaMes />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/entrada/atrasada"
        element={
          <PrivateRouter>
            {" "}
            <TableEntradaPrevistaAtrasada />
          </PrivateRouter>
        }
      />
      <Route
        path="/table/saida/atrasada"
        element={
          <PrivateRouter>
            {" "}
            <TableSaidaAtrasada />
          </PrivateRouter>
        }
      />


      <Route path="/login" element={<Login />} />
      <Route path="/dashboard" element={<Dashboard />} />
      <Route path="*" element={<Navigate to="/login" />} />
    </Routes>
  );
};
