import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
} from "@mui/material";

import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { changeloading } from "../../../store/actions/loading.action";
import { changeNotify } from "../../../store/actions/notify.actions";
import ProdutosService from "../../../services/ProdutosService";

function EditProdutos() {
  const location = useLocation();
  const produto = location.state ? location.state.produto : null;

  //select categorias
  const [categoria, setCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  //select subCategorias
  const [subcategoria, setSubCategoria] = useState([]);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState("");

  //select unidade de medidas
  const uniMedidas = [
    "barra",
    "peça",
    "metro quadrado",
    "chapa",
    "Unidade de Medidas",
    "Metro Linear",
  ];
  const [unidadeSelecionada, setUnidadeSelecionada] =
    useState("Unidade de Medidas");

  // States para os dados do produto
  const [nome, setNome] = useState("");
  const [descricao, setDescricao] = useState("");
  const [peso, setPeso] = useState("");
  const [largura, setLargura] = useState("");
  const [avanco, setAvanco] = useState("");
  const [valor, setValor] = useState("");
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleEdit = async (event) => {
    dispatch(changeloading({ open: true, msg: "Enviando.." }));
    event.preventDefault();
    // Remover formatação do valor e substituir vírgula por ponto

    const data = {
      nome,
      descricao,
      peso,
      uniMedida: unidadeSelecionada,
      categoria: categoriaSelecionada,
      subCategoria: subCategoriaSelecionada,
      largura,
      avanco,
      valor,
    };

    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await ProdutosService.Update(produto.produto_id, data);
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "success",
          msg: "Produto editado com sucesso",
        })
      );
      const primeiroNome = nome.split(" ")[0];
      navigate("/produtos", { state: { nome: primeiroNome } });
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: error.response.data.error,
        })
      );
    }
  };

  const getCategoria = async () => {
    const res = await ProdutosService.buscarCategoria();
    if (res) {
      setCategoria(res.categoria);
    }
  };
  const getSubCategoria = async () => {
    const res = await ProdutosService.buscarSubCategoria();
    if (res) {
      setSubCategoria(res.subCategoria);
    }
  };
  useEffect(() => {
    getCategoria();
    getSubCategoria();
  }, []);

  useEffect(() => {
    if (produto) {
      setNome(produto.nome || "");
      setDescricao(produto.descricao || "");
      setPeso(produto.peso || "");
      setUnidadeSelecionada(produto.uniMedida);
      setCategoriaSelecionada(produto.categoria);
      setSubCategoriaSelecionada(produto.subCategorias);
      setLargura(produto.largura || "");
      setAvanco(produto.avanco || "");
      setValor(produto.valor || "");
    }
  }, [produto]);

  return (
    <Box
      component={Paper}
      elevation={5}
      sx={{ flexGrow: 1 }}
      marginTop={1}
      padding={3}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={12} sm={12} md={12}>
          <form>
            <Grid container spacing={2}>
              <Grid item xs={12} sm={6} md={6} lg={3}>
                <TextField
                  label="Nome"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={nome}
                  onChange={(e) => setNome(e.target.value)}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={6} lg={4}>
                <TextField
                  label="Descrição"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setDescricao(e.target.value)}
                  value={descricao}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={2}>
                <TextField
                  label="Peso"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setPeso(e.target.value)}
                  value={peso}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={3}>
                <Select
                  label="UniMedidas"
                  id="uniMedida"
                  variant="outlined"
                  fullWidth
                  value={unidadeSelecionada}
                  size="small"
                  onChange={(e) => setUnidadeSelecionada(e.target.value)}
                >
                  {uniMedidas.map((uniMedida) => (
                    <MenuItem key={uniMedida} value={uniMedida}>
                      {uniMedida}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel id="categoria" sx={{ fontSize: "15px" }}>
                  {" "}
                  Categoria
                </InputLabel>
                <Select
                  id="categoria"
                  fullWidth
                  size="small"
                  value={categoriaSelecionada}
                  onChange={(e) => {
                    setCategoriaSelecionada(e.target.value);
                  }}
                >
                  {categoria.map((categoria) => (
                    <MenuItem key={categoria.id} value={categoria.categoria}>
                      {categoria.categoria}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={4} lg={3}>
                <InputLabel id="subCategoria" sx={{ fontSize: "15px" }}>
                  Sub Categoria
                </InputLabel>
                <Select
                  id="subCategoria"
                  fullWidth
                  size="small"
                  value={subCategoriaSelecionada}
                  onChange={(e) => {
                    setSubCategoriaSelecionada(e.target.value);
                  }}
                >
                  {subcategoria.map((subcategoria) => (
                    <MenuItem
                      key={subcategoria.id}
                      value={subcategoria.sub_categoria}
                    >
                      {subcategoria.sub_categoria}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={11} sm={4} md={4} lg={2} marginTop="21px">
                <TextField
                  label="Largura"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setLargura(e.target.value)}
                  value={largura}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={4} lg={2} marginTop="21px">
                <TextField
                  label="Avanço"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setAvanco(e.target.value)}
                  value={avanco}
                />
              </Grid>

              <Grid item xs={12} sm={4} md={6} lg={2} marginTop="21px">
                <TextField
                  label="Valor"
                  variant="outlined"
                  fullWidth
                  size="small"
                  onChange={(e) => setValor(e.target.value)}
                  value={valor}
                />
              </Grid>

              <Grid item xs={12} sm={3} md={1} lg={3}></Grid>

              <Grid item xs={12} sm={5} md={5} lg={3}>
                <Button
                  fullWidth
                  variant="contained"
                  size="small"
                  onClick={handleEdit}
                >
                  Editar
                </Button>
              </Grid>

              <Grid item xs={12} sm={6} md={5} lg={3}>
                <Button
                  fullWidth
                  size="small"
                  variant="contained"
                  color="warning"
                  onClick={() => navigate(-1)}
                >
                  voltar
                </Button>
              </Grid>
            </Grid>
          </form>
        </Grid>
      </Grid>
    </Box>
  );
}

export default EditProdutos;
