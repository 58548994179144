// components/SelectMesAno.js
import React from "react";
import { Grid, MenuItem, Select } from "@mui/material";

function SelectMesAno({
  mesSelecionado,
  setMesSelecionado,
  anoSelecionado,
  setAnoSelecionado,
}) {
  const meses = [
    { id: 1, name: "janeiro" },
    { id: 2, name: "fevereiro" },
    { id: 3, name: "março" },
    { id: 4, name: "abril" },
    { id: 5, name: "maio" },
    { id: 6, name: "junho" },
    { id: 7, name: "julho" },
    { id: 8, name: "agosto" },
    { id: 9, name: "setembro" },
    { id: 10, name: "outubro" },
    { id: 11, name: "novembro" },
    { id: 12, name: "dezembro" },
  ];
  const anos = [
    { id: 2024, name: "2024" },
    { id: 2025, name: "2025" },
    { id: 2026, name: "2026" },
    { id: 2027, name: "2027" },
  ];

  return (
    <>
      <Grid item xs={6} sm={6} md={2} lg={2} marginLeft={4} marginRight={4}>
        <Select
          label="Mes"
          id="mes"
          variant="outlined"
          fullWidth
          value={mesSelecionado}
          size="small"
          onChange={(e) => setMesSelecionado(e.target.value)}
        >
          {meses.map((mes) => (
            <MenuItem key={mes.id} value={mes.id}>
              {mes.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
      <Grid item xs={6} sm={6} md={2} lg={2} marginLeft={4} marginRight={4}>
        <Select
          label="Ano"
          id="ano"
          variant="outlined"
          fullWidth
          value={anoSelecionado}
          size="small"
          onChange={(e) => setAnoSelecionado(e.target.value)}
        >
          {anos.map((ano) => (
            <MenuItem key={ano.id} value={ano.id}>
              {ano.name}
            </MenuItem>
          ))}
        </Select>
      </Grid>
    </>
  );
}

export default SelectMesAno;
