import React from "react";
import { CardContent, CardMedia, Typography } from "@mui/material";

const CardBemVindo = () => {
  const [user, setUser] = React.useState({});

  React.useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem("user"));
    setUser(storedUser || {});
  }, []);
  const firstName =
    user.name && typeof user.name === "string" ? user.name.split(" ")[0] : "";

  return (
    <>
      <CardContent
        sx={{
          height: "80px",
          width: "90%",
          position: "relative",
          background: "radial-gradient(circle, #9e9e9e, #e0e0e0,#fff)",
        }}
      >
        <Typography
          color=""
          sx={{
            position: "absolute",
            top: "10px",
            left: "25px",
            fontSize: "30px!important",
          }}
        >
          Olá,
        </Typography>
        <Typography
          color="#455a64"
          sx={{
            position: "absolute",
            top: "15px",
            left: "95px",
            fontWeight: "bold",
            fontSize: "30px!important",
          }}
        >
          {firstName || "Usuário"}
        </Typography>

        <Typography
          color=""
          sx={{
            position: "absolute",
            top: "65px",
            left: "25px",
            fontSize: "30px!important",
          }}
        >
          Seja bem vindo !
        </Typography>
      </CardContent>
    </>
  );
};

export default CardBemVindo;
