import React, { useEffect, useMemo, useState } from "react";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import ProdutosService from "../../../../services/ProdutosService";
import { useDebounce } from "../../../../hooks/UseDebounce";
import { MaskLargura, MaskValor } from "../../../../utils/mascaras";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import ClienteService from "../../../../services/ClienteService";
import OrcamentoToldosService from "../../../../services/OrcamentoToldosService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import { changeIdOrcamento } from "../../../../store/actions/orcamento.actions";

const schema = yup.object({
  cliente_id: yup.string().required(),
  largura: yup.string().required(),
  projecao: yup.string().required(),
  kitArticulado: yup.string().required(),
  lona: yup.string().required(),
});

function FormCalcArticulado(props) {
  const { servicoSelecionado } = props;
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();

  //select kitArticulado
  const [kitSelecionada, setKitSelecionada] = useState("");
  const [kit, setKit] = useState([]);

  //select lona
  const [lonaSelecionada, setLonaSelecionada] = useState("");
  const [lona, setLona] = useState([]);

  //const auto complete
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [busca, setBusca] = useState("");
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);

  const [valorTotal, setValorTotal] = useState(0);
  const [valorButtonClicked, setValorButtonClicked] = useState(false);
  const [orcamentoId, setOrcamentoId] = useState("");

  useEffect(() => {
    getkits();
    getLonas();
    handleSearchCliente("");
  }, []);

  const handleSearchCliente = (searchValue) => {
    debounce(() => {
      ClienteService.get(searchValue).then((res) => {
        setOptionsCliente(res.clientes || []);
      });
    });
  };

  function handleSubmit(data) {
    if (!valorButtonClicked) {
      // Verifica se o botão de valor já foi clicado
      setValorButtonClicked(true); // Define que o botão foi clicado

      // O restante do código permanece o mesmo
      let newData = {
        ...data,
        cliente_id: selectedId,
        servicos_id: servicoSelecionado,
      };

      dispatch(changeloading({ open: true, msg: "calculando.." }));
      OrcamentoToldosService.calcularArticulado(newData)
        .then((resposta) => {
          setOrcamentoId(resposta.id_orcamento);
          setValorTotal(resposta.valorTotal);
          dispatch(changeloading({ open: false }));

          dispatch(
            changeIdOrcamento(
              resposta.id_orcamento,
              resposta.id_orcamento_estrutura,
              data.largura,
              data.projecao,
              data.modulacao
            )
          );
        })
        .catch((error) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: error.response.data.error,
            })
          );
        });
    }
  }
  const getkits = async () => {
    const res = await ProdutosService.buscarkits();
    if (res) {
      setKit(res.kitArticulado);
    }
  };

  const getLonas = async () => {
    const res = await ProdutosService.buscarLona();
    if (res) {
      setLona(res.lonas);
    }
  };

  useEffect(() => {
    if (valorTotal !== 0) {
      const maskedValor = MaskValor(valorTotal);
      setValue("valor", maskedValor);
    }
  }, [valorTotal]);

  return (
    <Box>
      {/* formulario de busca de valores para travessas */}
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" marginLeft={2} marginBottom="-35px">
              calcular estrutura{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={4} marginTop="24px">
            <Autocomplete
              disablePortal
              size="small"
              options={optionsCliente}
              getOptionLabel={(option) => option.nome}
              value={
                optionsCliente.find((option) => option.id === selectedId) ||
                null
              }
              onChange={(event, newValue) => {
                setSelectedId(newValue ? newValue.id : null);
                setValue("cliente_id", newValue ? newValue.id : "", {
                  shouldValidate: true,
                });
              }}
              onInputChange={(event, newInputValue) => {
                handleSearchCliente(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o cliente"
                  variant="outlined"
                />
              )}
            />
            <Typography variant="subtitle2">
              {errors?.cliente_id?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={3} marginTop="24px">
            <TextField
              label="Largura"
              variant="outlined"
              fullWidth
              size="small"
              {...register("largura")}
              onInput={(e) => {
                e.target.value = MaskLargura(e.target.value);
                setValue("largura", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant="subtitle2">
              {errors?.largura?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={3} marginTop="24px">
            <TextField
              label="Projeção"
              variant="outlined"
              fullWidth
              size="small"
              {...register("projecao")}
              onInput={(e) => {
                e.target.value = MaskLargura(e.target.value);
                setValue("projecao", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant="subtitle2">
              {errors?.projecao?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={3}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Kit articulado
            </InputLabel>
            <Select
              id="kitArticulado"
              fullWidth
              size="small"
              value={kitSelecionada}
              {...register("kitArticulado")}
              onChange={(e) => {
                setKitSelecionada(e.target.value);
              }}
            >
              {kit.map((kit) => (
                <MenuItem key={kit.id} value={kit.id}>
                  {kit.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.perfilTravessas?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={3}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Tipo de Lona
            </InputLabel>
            <Select
              id="tipoLona"
              fullWidth
              size="small"
              value={lonaSelecionada}
              {...register("lona")}
              onChange={(e) => {
                setLonaSelecionada(e.target.value);
              }}
            >
              {lona.map((lona) => (
                <MenuItem key={lona.id} value={lona.id}>
                  {lona.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">{errors?.lona?.message}</Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              {...register("valor", { value: valorTotal })}
              onClick={() => {
                onSubmit(handleSubmit)();
              }}
              disabled={valorButtonClicked} // Desabilita o campo se o botão de valor já foi clicado
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <Typography sx={{ fontSize: "14px" }}>
              numero do orçamento:
              <br />
              {orcamentoId}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default FormCalcArticulado;
