import React, { useEffect, useMemo, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import { ControlPoint } from "@mui/icons-material";
import { NumericFormat } from "react-number-format";
import CategoriaFinanceira from "../../services/CategoriaFinanceira";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import { changeNotify } from "../../store/actions/notify.actions";
import CaixaService from "../../services/CaixaService";
import VendasService from "../../services/VendasService";
import Listagem from "./Listagem";
import { useDebounce } from "../../hooks/UseDebounce";
import FornecedoresService from "../../services/FornecedoresService";
import ClienteService from "../../services/ClienteService";

function ModalForm({ open, onClose, getCategoria }) {
  const [name, setName] = useState("");
  const [error, setError] = useState("");

  const dispatch = useDispatch();

  const handleClose = () => {
    onClose();
  };
  const handleChange = (e) => {
    setName(e.target.value);
    setError(""); // Limpa o erro quando o usuário começa a digitar
  };

  const handleCategoria = async () => {
    if (name.trim() === "") {
      setError("Nome é obrigatório!");
    } else {
      setError("");
      const data = {
        nome: name,
      };
      dispatch(changeloading({ open: true }));
      try {
        await CategoriaFinanceira.create(data);
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: "Categoria criada com sucesso",
          })
        );
        await getCategoria();
        handleClose();
      } catch (error) {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: "Erro ao criar categoria",
          })
        );
      }
    }
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 300,
          bgcolor: "background.paper",
          p: 4,
          borderRadius: 3,
        }}
      >
        <Typography variant="h6" gutterBottom>
          Adicione a nova categoria
        </Typography>

        <TextField
          label="Nome"
          size="small"
          fullWidth
          variant="outlined"
          value={name}
          onChange={handleChange}
          error={error !== ""}
          helperText={error}
        />

        <Button
          sx={{ marginTop: "10px" }}
          onClick={() => handleCategoria()}
          variant="contained"
          color="primary"
          fullWidth
        >
          Enviar
        </Button>
      </Box>
    </Modal>
  );
}

function EntradaSaida() {
  const [descricao, setDescricao] = useState("");
  const [valor, setValor] = useState("");
  const [error, setError] = useState("");
  const [tipoPagamento, setTipoPagamento] = useState([]);
  const [tipoPagamentoSelecionada, setTipoPagamentoSelecionada] = useState("");
  const [categoria, setCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState("");
  const [tipoEntrada, setTipoEntrada] = useState("");
  const [erroValor, setErroValor] = useState("");
  const [date, setDate] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [caixaKey, setCaixaKey] = useState(0); // Adiciona um estado para atualizar a chave do componente Listagem
  const [fornecedorId, setFornecedorId] = useState("");
  const [clienteId, setClienteId] = useState("");

  const [isLoading, setIsLoading] = useState(false);

  //const auto complete
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [busca, setBusca] = useState("");
  const { debounce } = useDebounce();

  const [optionsCliente, setOptionsCliente] = useState([]);
  const [selectedIdCliente, setSelectedIdCliente] = useState(null);
  const [buscaCliente, setBuscaCliente] = useState("");

  const dispatch = useDispatch();

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleFornecedorSelection = (_, newValue) => {
    setSelectedId(newValue?.id);
    setFornecedorId(newValue?.id);
    setBusca("");
  };

  const handleClientSelection = (_, newValue) => {
    setSelectedIdCliente(newValue?.id);
    setClienteId(newValue?.id);
    setBusca("");
  };

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      FornecedoresService.get(busca).then((res) => {
        setIsLoading(false);
        setOptionsFornecedor(
          res.fornecedor.map((fornecedor) => ({
            id: fornecedor.id,
            label: fornecedor.razao_social,
          }))
        );
      });
    });
  }, [busca]);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ClienteService.get(buscaCliente).then((res) => {
        setIsLoading(false);
        setOptionsCliente(
          res.clientes.map((clientes) => ({
            id: clientes.id,
            label: clientes.nome,
          }))
        );
      });
    });
  }, [buscaCliente]);

  const autoCompleteSelectedOptionCliente = useMemo(() => {
    if (!selectedIdCliente) return null;
    const selectedOptionCliente = optionsCliente.find(
      (optionsCliente) => optionsCliente.id === selectedIdCliente
    );
    if (!selectedOptionCliente) return null;
    return selectedOptionCliente;
  }, [selectedIdCliente, optionsCliente]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    const selectedOption = optionsFornecedor.find(
      (optionsFornecedor) => optionsFornecedor.id === selectedId
    );
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId, optionsFornecedor]);

  const getCategoria = async () => {
    const res = await CategoriaFinanceira.buscarCategoria();
    if (res) {
      setCategoria(res.categoria);
    }
  };
  const getTipopagamento = async () => {
    try {
      const res = await VendasService.getTipopagamento();
      if (res) {
        setTipoPagamento(res.tipoPagamento);
      }
    } catch (error) {
      console.error("Erro ao obter técnicos:", error);
    }
  };
  useEffect(() => {
    getCategoria();
    getTipopagamento();
  }, []);

  const handleSubmit = async () => {
    if (descricao.trim() === "") {
      setError(" obrigatório!");
    } else if (valor.trim() === "") {
      setErroValor(" obrigatório!");
    } else {
      setError("");
      setErroValor("");
      const data = {
        descricao: descricao,
        valor_parcela: valor,
        tipo_movimentacao: tipoEntrada,
        categoria_financeiras_id: categoriaSelecionada,
        data: date,
        tipo_pagamento_id: tipoPagamentoSelecionada,
        fornecedorId: fornecedorId,
        clienteId: clienteId,
      };
      dispatch(changeloading({ open: true, msg: "salvando ..." }));
      CaixaService.create(data)
        .then((res) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({ open: true, class: "success", msg: res.message })
          );
          setCaixaKey((prevKey) => prevKey + 1); // Atualiza a chave do componente Listagem para forçar a renderização
        })
        .catch((error) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: error.response.data.error,
            })
          );
        });
    }
  };

  return (
    <Box>
      <Box
        component={Paper}
        elevation={5}
        bgcolor="#3f51b5"
        display="flex"
        alignItems="center"
        justifyContent="center"
        height={75}
        width="100%"
      >
        <Typography fontSize="25px!important" color="#eeeeee">
          Controle de movimentações Financeiras
        </Typography>
      </Box>
      <Box component={Paper} elevation={5} marginTop={4}>
        <Grid container spacing={2} padding={1}>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Descricão"
              variant="outlined"
              fullWidth
              size="small"
              value={descricao}
              onChange={(e) => {
                setDescricao(e.target.value);
                setError("");
              }}
            />
            <Typography variant="subtitle2" color="error">
              {error}
            </Typography>
          </Grid>
          <Grid
            item
            xs={5}
            sm={5}
            md={4}
            lg={3}
            xl={2}
            container
            alignItems="flex-end"
          >
            <FormControl fullWidth>
              <InputLabel id="categoria">Categoria</InputLabel>
              <Select
                labelId="categoria"
                size="small"
                fullWidth
                value={categoriaSelecionada}
                onChange={(e) => setCategoriaSelecionada(e.target.value)}
              >
                {categoria.map((categoria) => (
                  <MenuItem key={categoria.id} value={categoria.id}>
                    {categoria.nome}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid
            item
            xs={2}
            sm={1}
            md={1}
            lg={2}
            xl={1}
            container
            alignItems="flex-end"
            sx={{ marginLeft: "-17px", marginRight: "-125px" }}
          >
            <IconButton onClick={handleOpenModal}>
              <ControlPoint color="success" />
            </IconButton>
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem opções"
              loadingText="Carregando..."
              disablePortal
              loading={isLoading}
              options={optionsFornecedor}
              onChange={handleFornecedorSelection}
              onInputChange={(_, newValue) => setBusca(newValue)}
              popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
              value={autoCompleteSelectedOption} // Use diretamente o objeto selecionado
              getOptionLabel={(option) => option.label} // Defina como o Autocomplete deve acessar a propriedade label do objeto
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Fornecedor"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={fornecedorId}
                />
              )}
            />
          </Grid>
          <Grid item xs={6} sm={6} md={4} lg={3} xl={2}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem opções"
              loadingText="Carregando..."
              disablePortal
              loading={isLoading}
              options={optionsCliente}
              onChange={handleClientSelection}
              onInputChange={(_, newValue) => setBusca(newValue)}
              popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
              value={autoCompleteSelectedOptionCliente} // Use diretamente o objeto selecionado
              getOptionLabel={(option) => option.label} // Defina como o Autocomplete deve acessar a propriedade label do objeto
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="cliente"
                  variant="outlined"
                  fullWidth
                  size="small"
                  value={clienteId}
                />
              )}
            />
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1}>
            <FormControl fullWidth>
              <InputLabel id="tipoPagamento">Tipo</InputLabel>
              <Select
                labelId="tipoPagamento"
                size="small"
                fullWidth
                value={tipoPagamentoSelecionada}
                onChange={(e) => setTipoPagamentoSelecionada(e.target.value)}
              >
                {tipoPagamento.map((tipoPagamento) => (
                  <MenuItem key={tipoPagamento.id} value={tipoPagamento.id}>
                    {tipoPagamento.tipo_pagamento}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>

          <Grid item xs={6} sm={6} md={4} lg={3} xl={1}>
            <NumericFormat
              value={valor}
              onValueChange={(values) => {
                setValor(values.value);
                setError("");
              }}
              allowLeadingZeros={false}
              allowNegative={false}
              decimalScale={2}
              fixedDecimalScale={true}
              decimalSeparator=","
              allowedDecimalSeparators={["."]}
              prefix="R$ "
              thousandSeparator="."
              customInput={TextField}
              size="small"
              label="Valor"
              isAllowed={(values) => {
                if (values.value.length > 9) return false;
                return true;
              }}
            />
            <Typography variant="subtitle2" color="error">
              {erroValor}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <Grid container alignItems="center">
              <RadioGroup
                row
                value={tipoEntrada}
                onChange={(e) => setTipoEntrada(e.target.value)}
              >
                <FormControlLabel
                  sx={{ marginLeft: "5px" }}
                  value="entrada"
                  control={<Radio />}
                  label="Entrada"
                />
                <FormControlLabel
                  value="saida"
                  control={<Radio />}
                  label="Saída"
                />
              </RadioGroup>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3} xl={2}>
            <TextField
              label="Data"
              type="date"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              value={date}
              onChange={(e) => setDate(e.target.value)}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={2} xl={1}>
            <Button
              onClick={() => handleSubmit()}
              fullWidth
              variant="contained"
            >
              enviar
            </Button>
          </Grid>
        </Grid>
      </Box>
      <ModalForm
        open={openModal}
        onClose={handleCloseModal}
        getCategoria={getCategoria}
      />
      <Listagem key={caixaKey} />
    </Box>
  );
}

export default EntradaSaida;
