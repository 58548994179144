import React, { useState } from "react";
import {
  Box,
  Grid,
  Button,
  TextField,
  CircularProgress,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  IconButton,
} from "@mui/material";
import AddOrcamento from "./AddOrcamento";
import OrcamentoService from "../../../services/OrcamentoService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../../store/actions/loading.action";
import { changeNotify } from "../../../store/actions/notify.actions";
import generatePDF, { Margin } from "react-to-pdf";
import { MaskDate } from "../../../utils/mascaras";
import Logo from "../../../image/logo.jpg";
import FotoOrcamento from "../../../image/fotoOrcamento.jpg";
import { Number, StyledSpan, Circle } from "../Venda/Styled";
import CancelIcon from "@mui/icons-material/Cancel";

function Orcamento() {
  const dispatch = useDispatch();
  const [dadosRecebidos, setDadosRecebidos] = useState(false);
  const [idOrcamento, setIdOrcamento] = useState("");
  const [selecoes, setSelecoes] = useState([]);
  const [itens, setItens] = useState([]);
  const [cliente, setCliente] = useState([]);
  const [valorTotalOrcamento, setValorTotalOrcamento] = useState("");
  const [nomeCobertura, setNomeCobertura] = useState([]);
  const [condPagamento, setCondPagamento] = useState("");
  const [dataOrcamento, setDadaOrcamento] = useState("");
  const [dataValidade, setDataValidade] = useState("");
  const [observacoes, setObservacoes] = useState("");
  const [quantidade, setQuantidade] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [loadingPDF, setLoadingPDF] = useState(false);

  // Função para gerar o orçamento
  const gerarOrcamento = async () => {
    const dadosParaEnviar = {
      idOrcamento: selecoes,
      quantidade: quantidade,
    };
    console.log(dadosParaEnviar)

    try {
      dispatch(
        changeloading({ open: true, msg: "Gerando orçamento aguarde.." })
      );
      const resposta = await OrcamentoService.dadosOrcamento(dadosParaEnviar);
      setItens(resposta.resposta.itens);
      setCliente(resposta.resposta.cliente);
      setNomeCobertura(resposta.resposta.nomeCoberturas);
      setValorTotalOrcamento(resposta.resposta.valorTotalOrcamento);
      setDataValidade(resposta.resposta.datas.dataValidade);
      setDadaOrcamento(resposta.resposta.datas.dataOrcamento);
      setQuantidade(resposta.resposta.quantidade);
      setIsLoading(false);
      setDadosRecebidos(true);
      dispatch(
        changeNotify({ open: true, class: "success", msg: resposta.message })
      );
    } catch (error) {
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: error.response.data.error,
        })
      );
    } finally {
      dispatch(changeloading({ open: false }));
    }
  };

  const persolanizacao = {
    //se for abrir method open/ salvar = save
    method: "save",
    fileName: `${cliente.nome}.pdf`,
    page: {
      // margin is in MM, default is Margin.NONE = 0
      margin: Margin.SMALL,
      // default is 'A4'
      format: "A4",
      // default is 'portrait'
      orientation: "portrait",
    },
  };
  const recuperarCpnteudoParaPdf = () => document.getElementById("conteudo");


  function handleAddSelecao() {
    if (idOrcamento && idOrcamento.trim() !== "") {
      const ids = idOrcamento.split(",").map((id) => id.trim());
      const newSelecoes = ids.map((id) => ({ id_orcamento: id }));

      setSelecoes([...selecoes, ...newSelecoes]);
      setIdOrcamento("");
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    setIdOrcamento(value);

    if (value && value.slice(-1) === ",") {
      handleAddSelecao();
    }
  }

  function handleRemoveSelecao(id) {
    setSelecoes(selecoes.filter((selecao) => selecao.id_orcamento !== id));
  }
  console.log(dataValidade)
  return (
    <Box marginTop="-35px">
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <AddOrcamento />
        </Grid>
        <Grid item xs={12}>
          {!dadosRecebidos && (
            <Typography variant="h5">Para fazer a junção de orçamentos coloque cada Id e separe por virgula.</Typography>
          )}
        </Grid>
        <Grid item xs={4} sm={3} md={3} lg={3}>
          {!dadosRecebidos && (
            <TextField
              variant="outlined"
              label="Ids Orçamentos"
              fullWidth
              size="small"
              value={idOrcamento}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              InputProps={{
                endAdornment: selecoes.map((selecao, index) => (
                  <React.Fragment key={index}>
                    {index > 0 && ", "}
                    <StyledSpan>
                      <Circle>
                        <Number>{selecao.id_orcamento}</Number>
                      </Circle>
                      <IconButton
                        size="small"
                        onClick={() => handleRemoveSelecao(selecao.id_orcamento)}
                      >
                        <CancelIcon />
                      </IconButton>
                    </StyledSpan>
                  </React.Fragment>
                )),
              }}
            />
          )}
        </Grid>
        <Grid item xs={2} sm={2} md={1} lg={1}>
          {!dadosRecebidos && (
            <TextField
              type="number"
              fullWidth
              size="small"
              label="Qnt"
              value={quantidade}
              onChange={(e) => setQuantidade(e.target.value)}
            />
          )}
        </Grid>
        <Grid item xs={8} sm={6} md={3} lg={3}>
          {!dadosRecebidos && (
            <Button variant="contained" onClick={gerarOrcamento}>
              Gerar Orçamento
            </Button>
          )}
        </Grid>
      </Grid>
      {dadosRecebidos && (
        <>
          <Box id="conteudo">
            <Box sx={{ width: "100%", height: "100%", overflow: "hidden" }}>
              <img
                src={FotoOrcamento}
                alt="display"
                style={{ width: "100%", height: "100%", objectFit: "contain" }}
              />
            </Box>
            <div
              style={{
                position: "absolute",
                top: 950,
                left: 210,
                width: "70%",
                height: "50%",
                backgroundImage: `url(${Logo})`,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
                backgroundSize: "contain",
                opacity: 0.03, // Ajuste a opacidade conforme necessário
                pointerEvents: "none", // A marca d'água não é interativa
              }}
            ></div>

            {nomeCobertura &&
              nomeCobertura.map((cobertura, index) => (
                <Box
                  key={`cobertura-box-${index}`}
                  marginTop={1}
                  marginBottom={1}
                  sx={{
                    position: "relative",
                    width: "100%",
                    maxWidth: "100%",
                    height: "40px",
                    backgroundColor: "grey.200",
                  }}
                >
                  <Typography
                    key={`modelo-${index}`}
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "5px",
                      fontWeight: "600",
                    }}
                  >
                    Tipo de serviço: {cobertura.servico}
                  </Typography>
                  <Typography
                    key={`modelo-${index}`}
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "355px",
                      fontWeight: "600",
                    }}
                  >
                    Modelo: {cobertura.tipoCobertura}
                  </Typography>

                  <Typography
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "600px",
                      fontWeight: "600",
                    }}
                  >
                    Largura: {cobertura.larguraEstrutura} mm
                  </Typography>
                  <Typography
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "750px",
                      fontWeight: "600",
                    }}
                  >
                    Projeção: {cobertura.projecaoEstrutura} mm
                  </Typography>

                  <Typography
                    sx={{
                      position: "absolute",
                      top: "8px",
                      left: "920px",
                      fontWeight: "600",
                    }}
                  >
                    Data de  vencimento: {MaskDate(dataValidade)}
                  </Typography>
                </Box>
              ))}





            {/* Cabeçalho */}

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "130px",
              }}
            >
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "10px",
                  fontSize: "16px!important",
                }}
              >
                Nome: {cliente.nome}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "10px",
                  fontSize: "16px!important",
                }}
              >
                End: {cliente.endereco}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "75px",
                  left: "10px",
                  fontSize: "16px!important",
                }}
              >
                Cidade: {cliente.cidade}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "110px",
                  left: "10px",
                  fontSize: "16px!important",
                }}
              >
                Email: {cliente.email}
              </Typography>
              {/* fila da direita */}
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "5px",
                  left: "810px",
                  fontSize: "16px!important",
                }}
              >
                Cpf/Cnpj: {cliente.cpf || cliente.cnpj}
              </Typography>
              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "40px",
                  left: "810px",
                  fontSize: "16px!important",
                }}
              >
                Telefone: {cliente.telefone}
              </Typography>

              <Typography
                variant="h4"
                sx={{
                  position: "absolute",
                  top: "75px",
                  left: "810px",
                  fontSize: "16px!important",
                }}
              >
                Bairro: {cliente.bairro}
              </Typography>
              {dataOrcamento && (
                <Typography
                  variant="h4"
                  sx={{
                    position: "absolute",
                    top: "110px",
                    left: "810px",
                    fontSize: "16px!important",
                  }}
                >
                  Data: {MaskDate(dataOrcamento)}
                </Typography>
              )}
            </Box>

            {/* tabela */}
            <Box marginBottom={2} marginTop={2}>
              <TableContainer>
                <Table>
                  <TableHead>
                    <TableRow sx={{ backgroundColor: "grey.200" }}>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Material utilizado
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Quantidade
                      </TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>Categoria</TableCell>
                      <TableCell sx={{ fontSize: "15px" }}>
                        Unidade de Medidas
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {isLoading ? (
                      <TableRow>
                        <TableCell colSpan={4} align="center">
                          <CircularProgress />
                        </TableCell>
                      </TableRow>
                    ) : (
                      itens.map((row, index) => (
                        <TableRow key={index}>
                          <TableCell sx={{ fontSize: "15px" }}>
                            {row.nomeProduto}
                          </TableCell>
                          <TableCell sx={{ fontSize: "15px" }}>
                            {row.quantidade}
                          </TableCell>
                          <TableCell sx={{ fontSize: "15px" }}>
                            {row.subCategoria}
                          </TableCell>
                          <TableCell sx={{ fontSize: "15px" }}>
                            {row.uniMedida}
                          </TableCell>
                        </TableRow>
                      ))
                    )}
                  </TableBody>
                </Table>
              </TableContainer>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "40px",
                backgroundColor: "grey.400",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "810px",
                  fontWeight: "800",
                  fontSize: "16px!important",
                }}
              >
                Valor Total: {valorTotalOrcamento}
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "40px",
                backgroundColor: "grey.300",
                marginTop: "15px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "450px",
                  fontWeight: "500",
                  fontSize: "16px!important",
                }}
              >
                Condições de Pagamento
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
              }}
            >
              <TextField
                fullWidth
                multiline
                maxRows={6}
                value={condPagamento}
                onChange={(e) => setCondPagamento(e.target.value)}
              />
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
                marginTop: "10px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "3px",
                  left: "450px",
                  fontWeight: "500",
                  fontSize: "16px!important",
                }}
              >
                Horário de Instalação de segunda à sexta das 8 às 17:30h
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "40px",
                backgroundColor: "grey.300",
                marginTop: "-28px",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "8px",
                  left: "450px",
                  fontWeight: "500",
                  fontSize: "16px!important",
                }}
              >
                Observações
              </Typography>
            </Box>

            <Box
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "60px",
              }}
            >
              <TextField
                fullWidth
                multiline
                maxRows={6}
                value={observacoes}
                onChange={(e) => setObservacoes(e.target.value)}
              />
            </Box>
            <Box
              marginTop={5}
              marginBottom={2}
              sx={{
                position: "relative",
                width: "100%",
                maxWidth: "100%",
                height: "800px",
                backgroundColor: "grey.200",
              }}
            >
              <Typography
                sx={{
                  position: "absolute",
                  top: "3px",
                  left: "30px",
                  fontWeight: "700",
                  fontSize: "30px!important",
                }}
              >
                É importante saber:
              </Typography>
              <Typography
                sx={{
                  position: "absolute",
                  top: "63px",
                  left: "30px",
                  fontWeight: "500",
                  fontSize: "20px!important",
                }}
              >
                Nossos prazos de entrega variam de 21 até 90 dias dependendo do
                tipo de material escolhido e da época do ano, consulte nossos
                representantes a respeito do seu prazo.
                <br />
                Após o aceite desta proposta comercial enviaremos um contrato
                completo para sua aprovação. É de extrema importancia a leitura
                para aprovação ou não das clausulas descritas.
                <br />
                Este contrato será enviado pelos canais da Toldos Indaiatuba,
                whatsapp, email ou pessoalmente e a forma de pagamento acordada
                será realizada somente pelos canais disponibizados em nome da
                Toldos Indaiatuba - OMV Garcia Comercio de Toldos ME inscrito no
                CNPJ. 20.119.622/0001-40.
                <br />
                Oferecemos garantia total de 3 meses a 1 ano dependendo do
                produto escolhido. Exemplo reformas 3 meses Vedações 6 meses e
                estrutura geral 1 ano. Após esse período se houver algum
                problema de fabricação de algum material, acionaremos o
                fabricante para avaliação.
                <br />
                Nosso pessoal de instalação possui certificado de equipamentos
                de proteção individual NR 6, de segurança no trabalho e
                equipamentos NR 12, de segurança e saúde no trabalho na
                industria da construção NR 18, de segurança para trabalhos a
                quente NR 34,5 e também certificado de trabalho em altura NR 35,
                todos com registro e atestado de Saúde ocupacional (ASO), eles
                estarão uniformizados para sua identificação e também treinados
                em relação a postura e padrão de comportamento na casa do
                cliente.
                <br />
                Para sanar qualquer dúvida referente a proposta comercial
                estamos a disposição para realizar uma visita técnica sem custo
                adicional em nossa região. Salientamos que essa visita é de
                extrema importancia para que nosso técnico tenha a possibilidade
                de explicar a forma que trabalhamos dentro das normas regidas
                pela ABNT, dentre elas a SEGURANÇA que impacta diretamente na
                escolha dos materiais corretos, resultando em uma durabilidade
                maior nos produtos e baixa manutenção.
                <br />
                Ao analisar sua proposta comercial sugerimos também que consulte
                as empresas participantes da cotação através das plataformas
                independentes como Reclame Aqui e avaliações dos cliente através
                do Google .
              </Typography>
            </Box>
          </Box>
          {/* Botão para gerar PDF */}
          <Box marginTop={10}>
            <Button
              variant="contained"
              disabled={loadingPDF}
              onClick={() => {
                setLoadingPDF(true); // Habilita o indicador de carregamento
                generatePDF(recuperarCpnteudoParaPdf, persolanizacao)
                  .then(() => setLoadingPDF(false)) // Desabilita o indicador de carregamento após a conclusão
                  .catch((error) => {
                    console.error("Erro ao gerar PDF:", error);
                    setLoadingPDF(false); // Desabilita o indicador de carregamento em caso de erro
                  });
              }}
            >
              {loadingPDF ? "Gerando PDF..." : "Gerar PDF"}
            </Button>
          </Box>
        </>
      )}
    </Box>
  );
}

export default Orcamento;