
import { Http } from "../api";

class CompraService {
  static async getCompraPendente(obj) {
    let res = null;
    await Http.get("/compra", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async getCompraFinalizada(page, perPage, searchTerm) {
    let res = null;
    await Http.get(`/comprafinalizada?page=${page}&perPage=${perPage}&searchTerm=${searchTerm}`)
      .then((response) => {
        res = response.data;     
      });
    return res;
  }
  
  static async getCompraRevisao(obj) {
    let res = null;
    await Http.get("/revisao", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async Update(id, data) {
    let res = null;

    await Http.put(`compra/${id}`,data).then((response) => {
      res = response.data;

    });
    return res;
  }

  static async mudarCompra (id) {
    let res = null;
    await Http.put(`mudarstatus/${id}`).then((response) => {  
      res = response.data;     
    });
    return res;
 
  }
  static async mudarParaCompraPendente (id) {
    let res = null;
    await Http.put(`mudarparacomprapendente/${id}`).then((response) => {  
      res = response.data;     
    });
    return res;
  }
  static async naoComprar (id) {
    let res = null;
    await Http.put(`naocomprar/${id}`).then((response) => {  
      res = response.data;     
    });
    return res;
  }

  static async addicionarProduto(obj) {
    let res = null;
    await Http.post("/adicionarproduto", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  
  static async addicionarDias(obj) {
    let res = null;
    await Http.post("/adicionardias", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
}
export default CompraService;