import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { TableComponet } from "../../../components";
import { useDebounce } from "../../../hooks/UseDebounce";
import { useDispatch } from "react-redux";
import VendasService from "../../../services/VendasService";

const headers = [
  {
    id: "id",
    label: "Id",
    props: {
      align: "right",
    },
  },
  {
    id: "cliente",
    label: "Nome Cliente",
    props: {
      align: "left",
    },
  },
  {
    id: "vendedor",
    label: "Vendedor",
    props: {
      align: "left",
    },
  },

  {
    id: "data_venda",
    label: "Data",
    props: {
      align: "right",
    },
  },
  {
    id: "valor_total",
    label: "Valor Total",
    props: {
      align: "right",
    },
  },
  {
    id: "desconto",
    label: "Desconto",
    props: {
      align: "right",
    },
  },
  {
    id: "valor_total_liquido",
    label: "Valor Liquido",
    props: {
      align: "right",
    },
  },
];

function TabelaVendas() {
  const dispatch = useDispatch();
  //const da tabela
  const [vendas, SetVendas] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
  //const auto complete
  const [optionsOrcamento, setOptionsOrcamento] = useState([]);
  const [busca, setBusca] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const { register } = useForm({ resolver: yupResolver() });
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      VendasService.getVendas(busca).then((res) => {
        setIsLoading(false);
        SetVendas(res.vendas || []);
        setOptionsOrcamento(
          res.vendas.map((vendas) => ({
            id: vendas.id,
            label: vendas.nomeCliente,
          }))
        );
      });
    });
  }, [busca, tableUpdateTrigger]);

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={5}>
        <Autocomplete
          size="small"
          openText="Abrir"
          closeText="Fechar"
          noOptionsText="Sem opções"
          loadingText="Carregando..."
          disablePortal
          options={optionsOrcamento}
          onInputChange={(_, newValue) => setBusca(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("users_id")}
              label="Pesquisar..."
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          voltar
        </Button>
      </Grid>

      <Grid item xs={12}>
        <TableComponet
          headers={headers}
          data={vendas}
          labelCaption={"Nenhum Venda encontrado!!"}
          labelTable={"Vendas"}
          handlerEditarAction={""}
          handlerDeletarAction={""}
          request
          qdtPage={totalPage}
          loading={loading}
          setData={SetVendas}
          tableUpdateTrigger={tableUpdateTrigger}
          handlerRequest={async (page, size) => {
            setLoading(true);
            VendasService.getVendas("", page, size).then((data) => {
              setLoading(false);
              SetVendas(data.vendas || []);
              setTotalPages(data.totalPages || 0);
              return data;
            });
            return [];
          }}
        />
      </Grid>
    </Grid>
  );
}

export default TabelaVendas;
