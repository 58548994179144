import React, { useEffect, useMemo, useState } from "react";
import CompraService from "../../services/CompraService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Modal,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
  Tooltip,
} from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  PublishedWithChanges,
  AddCircleOutline,
  Block,
  CalendarMonth,
} from "@mui/icons-material";
import { useDebounce } from "../../hooks/UseDebounce";
import FornecedoresService from "../../services/FornecedoresService";
import { changeNotify } from "../../store/actions/notify.actions";
import { Confirm } from "../../components";
import { useNavigate } from "react-router-dom";
import AddProdutoCompra from "./AddProdutoCompra";
import AddDiasTrabalhos from "./AddDiasTrabalhos";

function Revisao() {
  const [compras, setCompras] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const dispatch = useDispatch();

  const handleNaoComprar = (id) => {
    dispatch(changeloading({ open: true, msg: "bloqueando a compra .." }));
    CompraService.naoComprar(id)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        getCompras();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "error", msg: error.message })
        );
      });
  };

  async function getCompras() {
    const res = await CompraService.getCompraRevisao();
    if (res) {
      setCompras(res.compras);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getCompras();
        dispatch(changeloading({ open: false }));
      }
    };
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  const handleOpenEditModal = (produto) => {
    setSelectedProduct(produto);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setEditModalOpen(false);
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell />
              <TableCell>id Orçamento</TableCell>
              <TableCell>id Venda</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Status da compra</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {compras.map((compra, index) => (
              <Row
                key={index}
                compra={compra}
                onOpenEditModal={handleOpenEditModal}
                handleNaoComprar={handleNaoComprar}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <EditModal
        open={editModalOpen}
        handleClose={handleCloseEditModal}
        item={selectedProduct}
      />
    </Box>
  );
}

function Row({ compra, handleNaoComprar }) {
  const [open, setOpen] = useState(false);
  const [openOne, setOpenOne] = useState(false);
  const [openModalDias, setOpenModalDias] = useState(false);
  const dispatch = useDispatch();

  // Função para abrir o modal
  const handleOpenModal = () => {
    setOpenOne(true);
  };
  // Função para abrir o modal
  const handleOpenModalDias = () => {
    setOpenModalDias(true);
  };

  // Função para fechar o modal
  const handleCloseModal = () => {
    setOpenOne(false);
  };
  // Função para fechar o modal
  const handleCloseModalDias = () => {
    setOpenModalDias(false);
  };

  const handleMandarCompras = (id) => {
    dispatch(changeloading({ open: true, msg: "Enviando para  compras .." }));
    CompraService.mudarParaCompraPendente(id)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        window.location.reload();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "error", msg: error.message })
        );
      });
  };

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.orcamento_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.venda_id}
        </TableCell>
        <TableCell>{compra.nomeCliente || compra.razaoSocial}</TableCell>
        <TableCell>{compra.status}</TableCell>
        <TableCell>
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tooltip title="Adicionar mais produto">
              <IconButton onClick={handleOpenModal}>
                <AddCircleOutline sx={{ color: "#388e3c" }} />
              </IconButton>
            </Tooltip>

            <Tooltip title="Adicionar quantidade de dias para instalação">
              <IconButton onClick={handleOpenModalDias}>
                <CalendarMonth sx={{ color: "#3f51b5" }} />
              </IconButton>
            </Tooltip>
            <Tooltip title="Mandar para compras">
              <IconButton
                onClick={() => handleMandarCompras(compra.orcamento_id)}
                color="primary"
              >
                <PublishedWithChanges sx={{ color: "#4a148c" }} />
              </IconButton>
            </Tooltip>
          </div>
        </TableCell>
      </TableRow>
      {/* segunda tabela */}
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="produtos">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "grey.200" }}>
                    <TableCell>Id</TableCell>
                    <TableCell align="right">Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Unidade de Medida</TableCell>
                    <TableCell align="right">Status da Compra</TableCell>
                    <TableCell align="right">
                      Data da Compra Individual
                    </TableCell>
                    <TableCell align="right">Ações</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compra.produtos.map((produto, index) => (
                    <TableRow key={index}>
                      <TableCell>{produto.orcamento_estrutura}</TableCell>
                      <TableCell align="right">{produto.nomeProduto}</TableCell>
                      <TableCell align="right">{produto.quantidade}</TableCell>
                      <TableCell align="right">{produto.uniMedida}</TableCell>
                      <TableCell align="right">
                        {produto.statusCompra}
                      </TableCell>
                      <TableCell align="right">
                        {produto.dataCompraIndividual}
                      </TableCell>
                      <TableCell align="right">
                        <Tooltip title="Não comprar essse produto">
                          <IconButton
                            onClick={() =>
                              handleNaoComprar(produto.orcamento_estrutura)
                            }
                            color="primary"
                          >
                            <Block sx={{ color: "#c62828" }} />
                          </IconButton>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
      <Modal open={openOne} onClose={handleCloseModal}>
        <Box>
          <AddProdutoCompra compra={compra} onClose={handleCloseModal} />
        </Box>
      </Modal>
      <Modal open={openModalDias} onClose={handleCloseModalDias}>
        <Box>
          <AddDiasTrabalhos compra={compra} onClose={handleCloseModalDias} />
        </Box>
      </Modal>
    </React.Fragment>
  );
}

function EditModal({ open, handleClose, item, handleSaveChanges }) {
  const [editedItem, setEditedItem] = useState({});
  const [fornecedorId, setFornecedorId] = useState("");

  //select unidade de medidas
  const statusCompra = ["aguardando compra", "finalizado"];
  const [statusSelecionada, setStatusSelecionada] =
    useState("aguardando compra");

  //const auto complete
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [busca, setBusca] = useState("");
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);

  const handleClientSelection = (_, newValue) => {
    setSelectedId(newValue?.id);
    setFornecedorId(newValue?.id);
    setBusca("");
    setEditedItem({ ...editedItem, fornecedorId: newValue?.id });
  };

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      FornecedoresService.get(busca).then((res) => {
        setIsLoading(false);
        setOptionsFornecedor(
          res.fornecedor.map((fornecedor) => ({
            id: fornecedor.id,
            label: fornecedor.razao_social,
          }))
        );
      });
    });
  }, [busca]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    const selectedOption = optionsFornecedor.find(
      (optionsFornecedor) => optionsFornecedor.id === selectedId
    );
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId, optionsFornecedor]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  useEffect(() => {
    // Define o item editado como o item atual quando o modal é aberto
    if (item) {
      setEditedItem(item);
    }
  }, [item]);

  const handleSave = () => {
    // Adicione o status da compra ao objeto editedItem
    const editedItemWithStatus = {
      ...editedItem,
      statusCompra: statusSelecionada,
    };
    // Envie o objeto editedItem com o status atualizado para a função handleSaveChanges
    handleSaveChanges(editedItemWithStatus);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography marginBottom={2} variant="h5" component="h2">
          Editar produto para compra
        </Typography>
        <Autocomplete
          openText="Abrir"
          closeText="Fechar"
          noOptionsText="Sem opções"
          loadingText="Carregando..."
          disablePortal
          loading={isLoading}
          options={optionsFornecedor}
          onChange={handleClientSelection}
          onInputChange={(_, newValue) => setBusca(newValue)}
          popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
          value={autoCompleteSelectedOption} // Use diretamente o objeto selecionado
          getOptionLabel={(option) => option.label} // Defina como o Autocomplete deve acessar a propriedade label do objeto
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fornecedor"
              variant="outlined"
              fullWidth
              size="small"
              value={fornecedorId}
            />
          )}
        />
        <Select
          label="Status da Compra"
          id="status"
          variant="outlined"
          fullWidth
          sx={{ marginTop: "10px" }}
          value={statusSelecionada}
          size="small"
          onChange={(e) => setStatusSelecionada(e.target.value)}
        >
          {statusCompra.map((statusCompra) => (
            <MenuItem key={statusCompra} value={statusCompra}>
              {statusCompra}
            </MenuItem>
          ))}
        </Select>
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Salvar Alterações
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default Revisao;
