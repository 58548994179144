import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, connect } from "react-redux";
import ProdutosService from "../../../../services/ProdutosService";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeNotify } from "../../../../store/actions/notify.actions";
import { changeloading } from "../../../../store/actions/loading.action";
import { MaskValor } from "../../../../utils/mascaras";

function FormCalcularChapa({
  idOrcamentoEtapa4,
  IdOrcamentoEstrutura,
  orcamentoId,
}) {
  const dispatch = useDispatch();

  const [calhaSelecionada, setCalhaSelecionada] = useState("");
  const [calhas, setCalhas] = useState([]);
  const [selecoes, setSelecoes] = useState([]); // Lista para rastrear as seleções do usuário
  const [valor_chapa, setValor_chapa] = useState("");
  const [orcamentoEstruturaEtapaQuatro, setOrcamentoEstruturaEtapaQuatro] =
    useState("");

  const getCalhas = async () => {
    const res = await ProdutosService.buscarCalhas();
    if (res) {
      setCalhas(res.calhas);
    }
  };

  useEffect(() => {
    getCalhas();
  }, []);

  function handleAddSelecao(selecao) {
    if (selecao && selecao !== "") {
      setSelecoes([...selecoes, { id_calha: selecao, quantidade: "" }]);
      setCalhaSelecionada("");
    }
  }

  function handleChangeQuantidade(index, event) {
    const newSelecoes = [...selecoes];
    newSelecoes[index].quantidade = event.target.value;
    setSelecoes(newSelecoes);
  }

  const handleClick = (data) => {
    if (orcamentoId) {
      handleEdit(data);
    } else {
      handleCalcularCalha(data);
    }
  };

  function handleCalcularCalha(data) {
    data.preventDefault();

    // Crie um objeto contendo as seleções e o idOrcamento
    const dadosParaEnviar = {
      selecoes: selecoes,
      idOrcamento: idOrcamentoEtapa4,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      valor: valor_chapa,
    };

    // Enviar os dadosParaEnviar para o backend
    OrcamentoService.calularChapa(dadosParaEnviar)
      .then((res) => {
        setValor_chapa(res.preco_total);
        dispatch(changeloading({ open: false }));
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });

    // Resetar seleções
    setSelecoes([]);
  }
  const handleEdit = () => {
    // Crie um objeto contendo as seleções e o idOrcamento
    const dadosParaEnviar = {
      selecoes: selecoes,
      orcamento_id: orcamentoId,
      OrcamentoEstruturaId: orcamentoEstruturaEtapaQuatro,
    };

    dispatch(changeloading({ open: true, msg: "editando" }));
    OrcamentoService.editEtapaQuatro(orcamentoId, dadosParaEnviar)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        // Atualizar seleções com os produtos recebidos
        const novasSelecoes = res.produtos.map((produto) => ({
          id_calha: produto.produto_id,
          quantidade: produto.quantidade,
        }));
        setSelecoes(novasSelecoes);
        setValor_chapa(res.preco_total);
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: "editado com sucesso",
          })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaQuatro(id);
    if (response) {
      const orcamento = response;
      setOrcamentoEstruturaEtapaQuatro(orcamento.estrutura_id);
      setValor_chapa(orcamento.valor_chapa);

      // Atualizar seleções com os produtos recebidos
      const novasSelecoes = orcamento.produtos.map((produto) => ({
        id_calha: produto.produto_id,
        quantidade: produto.quantidade,
      }));
      setSelecoes(novasSelecoes);

      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  return (
    <Box>
      <form handleSubmit={handleCalcularCalha}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" marginLeft={2} marginTop={2}>
              calcular calhas
            </Typography>
          </Grid>

          <Grid item xs={12} sm={7} md={4} lg={4}>
            <Select
              id="calha"
              fullWidth
              size="small"
              value={calhaSelecionada}
              onChange={(e) => {
                setCalhaSelecionada(e.target.value);
                handleAddSelecao(e.target.value);
              }}
            >
              <MenuItem value="">Selecione...</MenuItem>
              {calhas.map((calha) => (
                <MenuItem key={calha.id} value={calha.id}>
                  {calha.nome}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          {selecoes.map((selecao, index) => (
            <Grid key={index} container item spacing={2} alignItems="center">
              <Grid item xs={7} sm={5} md={3} lg={2}>
                <Typography>
                  {" "}
                  {calhas.find((calha) => calha.id === selecao.id_calha)?.nome}
                </Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <TextField
                  label="Quantidade"
                  variant="outlined"
                  size="small"
                  value={selecao.quantidade}
                  onChange={(event) => handleChangeQuantidade(index, event)}
                />
              </Grid>
            </Grid>
          ))}

          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              value={MaskValor(valor_chapa)}
              onChange={(e) => setValor_chapa(e.target.value)}
              onClick={handleClick}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamentoEtapa4: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}

export default connect(mapStateToProps)(FormCalcularChapa);
