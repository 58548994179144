import { Box, Button, Grid, TextField } from "@mui/material";

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { changeloading } from "../../../store/actions/loading.action";
import ClienteService from "../../../services/ClienteService";
import { changeNotify } from "../../../store/actions/notify.actions";
import { CustomTextField } from "../../../components";

// import { Container } from './styles';

function EditClientes() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const cliente = location.state ? location.state.clienteId : null;


  const [nome, setNome] = useState("");
  const [razaoSocial, setRazaoSocial] = useState("");
  const [email, setEmail] = useState("");
  const [telefone, setTelefone] = useState("");
  const [cpf, setCpf] = useState("");
  const [cnpj, setCnpj] = useState("");
  const [endereco, setEndereco] = useState("");
  const [condominio, setCondominio] = useState("");
  const [bairro, setBairro] = useState("");
  const [cidade, setCidade] = useState("");

  useEffect(() => {
    if (cliente) {
      setNome(cliente.nome || "");
      setRazaoSocial(cliente.razaoSocial || "");
      setEmail(cliente.email || "");
      setTelefone(cliente.telefone || "");
      setCpf(cliente.cpf || "");
      setCnpj(cliente.cnpj || "");
      setEndereco(cliente.endereco || "");
      setCondominio(cliente.condominio || "");
      setBairro(cliente.bairro || "");
      setCidade(cliente.cidade || "");
    }
  }, [cliente]);

  const handleEdit = async (event) => {
    dispatch(changeloading({ open: true, msg: "Enviando.." }));

    const data = {
      nome,
      razaoSocial,
      email,
      telefone,
      cpf,
      cnpj,
      endereco,
      condominio,
      bairro,
      cidade,
    };

    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await ClienteService.Update(cliente.id, data);
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "success",
          msg: "Cliente editado com sucesso",
        })
      );
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: error.response.data.error,
        })
      );
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            label="Nome"
            variant="outlined"
            fullWidth
            size="small"
            value={nome}
            onChange={(e) => setNome(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            label="Razao Social"
            variant="outlined"
            fullWidth
            size="small"
            value={razaoSocial}
            onChange={(e) => setRazaoSocial(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <TextField
            label="Email"
            type="email"
            variant="outlined"
            fullWidth
            size="small"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <CustomTextField
            label="Telefone"
            format="(##) # ####-####"
            mask="_"
            allowEmptyFormatting
            isAllowed={(values) => {
              const { formattedValue } = values;
              return !formattedValue || formattedValue.length <= 16;
            }}
            fullWidth
            size="small"
            value={telefone}
            onValueChange={(values) => setTelefone(values.value)}
          />
        </Grid>

        <Grid item xs={11} sm={6} md={6} lg={4}>
          <CustomTextField
            label="Cnpj"
            format="###.###.###-##" //XX.XXX.XXX/0001-XX
            mask="_"
            allowEmptyFormatting
            isAllowed={(values) => {
              const { formattedValue } = values;
              return !formattedValue || formattedValue.length <= 14;
            }}
            fullWidth
            size="small"
            value={cpf}
            onValueChange={(values) => setCpf(values.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={4}>
          <CustomTextField
            label="Cnpj"
            format="##.###.###/####-##" //XX.XXX.XXX/0001-XX
            mask="_"
            allowEmptyFormatting
            isAllowed={(values) => {
              const { formattedValue } = values;
              return !formattedValue || formattedValue.length <= 20;
            }}
            fullWidth
            size="small"
            value={cnpj}
            onValueChange={(values) => setCnpj(values.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={5}>
          <TextField
            label="Endereço"
            variant="outlined"
            fullWidth
            size="small"
            value={endereco}
            onChange={(e) => setEndereco(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            label="Condominio"
            variant="outlined"
            fullWidth
            size="small"
            value={condominio}
            onChange={(e) => setCondominio(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={2}>
          <TextField
            label="Bairro"
            variant="outlined"
            fullWidth
            size="small"
            value={bairro}
            onChange={(e) => setBairro(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={6} lg={3}>
          <TextField
            label="Cidade"
            variant="outlined"
            fullWidth
            size="small"
            value={cidade}
            onChange={(e) => setCidade(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={3} md={1} lg={2}>
          <Button
            fullWidth
            size="small"
            variant="contained"
            color="warning"
            onClick={() => navigate(-1)}
          >
            voltar
          </Button>
        </Grid>

        <Grid item xs={12} sm={5} md={5} lg={3}>
          <Button
            fullWidth
            variant="contained"
            size="small"
            onClick={handleEdit}
          >
            Enviar
          </Button>
        </Grid>

        <Grid item xs={12} sm={6} md={5} lg={3}></Grid>
      </Grid>
    </Box>
  );
}

export default EditClientes;
