import React, { useEffect, useState } from "react";
import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import { useDispatch, connect } from "react-redux"; // Importando o hook useSelector
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import ProdutosService from "../../../../services/ProdutosService";
import OrcamentoService from "../../../../services/OrcamentoService";
import { MaskValor } from "../../../../utils/mascaras";

const schema = yup.object({
  cobertura: yup.string().required(),
});

function FormaCalcularCobertura({
  idOrcamentoEtapa3,
  IdOrcamentoEstrutura,
  largura,
  avanco,
  orcamentoId
}) {
  const {
    register,
    handleSubmit: onSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();

  const [valorTotalCobertura, setValorTotalCobertura] = useState(0);
  const [travessaSelecionada, setTravessaSelecionada] = useState("");
  const [travessas, setTravesas] = useState([]);
  const [valorButtonClicked, setValorButtonClicked] = useState(false);
  const [orcamentoEstruturaEtapaTres, setOrcamentoEstruturaEtapaTres] = useState('');


  const getCobertura = async () => {
    const res = await ProdutosService.buscarCobertura();
    if (res) {
      setTravesas(res.cobertura);
    }
  };

  useEffect(() => {
    getCobertura();
  }, []);

  useEffect(() => {
    if (valorTotalCobertura !== 0) {
      const maskedValorPe = MaskValor(valorTotalCobertura);
      setValue("valorCobertura", maskedValorPe);
    }
  }, [valorTotalCobertura]);


  const handleClick = (data) => {
    if (orcamentoId) {
      handleEdit(data);
    } else {
      handleCalcularCobertura(data);
    }
  };

  function handleCalcularCobertura(data) {
    if (!valorButtonClicked) {
      // Verifica se o botão de valor já foi clicado
      setValorButtonClicked(true); // Define que o botão foi clicado
      // Usando o valor de idOrcamento do Redux
      let newData = {
        ...data,
        orcamento_id: idOrcamentoEtapa3,
        IdOrcamentoEstrutura: IdOrcamentoEstrutura,
        largura: largura,
        avanco: avanco,
      };

      dispatch(changeloading({ open: true, msg: "calculando.." }));
      OrcamentoService.calularCobertura(newData)
        .then((res) => {
          setValorTotalCobertura(res.valorCobertura);
          dispatch(changeloading({ open: false }));
        })
        .catch((error) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: error.response.data.error,
            })
          );
        });
    }
  }

  const handleEdit = (data) => {
   
    let newData = {
      ...data,
      etapa_id: 3,  
      OrcamentoEstruturaId: orcamentoEstruturaEtapaTres,
      orcamento_id: orcamentoId ,
      largura: largura,
      avanco: avanco,  
    };  

    dispatch(changeloading({ open: true, msg:'editando' }));
    OrcamentoService.editEtapaTres(orcamentoId, newData)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        setValue("valorCobertura", res.valorCobertura);
        dispatch(
          changeNotify({ open: true, class: "success", msg: 'editado com sucesso'})
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  }

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: 'buscando ...' }));
    const response = await OrcamentoService.trazerEtapaTres(id);
    if (response) {
      const orcamento = response;
      setOrcamentoEstruturaEtapaTres(orcamento.estrutura_id)
      setValue("valorCobertura", orcamento.valor_cobertura);      
      orcamento.produtos.forEach((produto) => {      
        setTravessaSelecionada(produto.produto_id);
            setValue("cobertura", produto.produto_id);
   });
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
 
  };

  useEffect(() => {  
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

 

  return (
    <Box>
      <form onSubmit={onSubmit(handleCalcularCobertura)}>
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography
              variant="h5"
              marginLeft={2}
              marginTop={2}
              marginBottom="-35px"
            >
              Calcular Cobertura
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={4}>
            <Select
              id="cobertura"
              fullWidth
              size="small"
              value={travessaSelecionada}
              {...register("cobertura")}
              onChange={(e) => {
                setTravessaSelecionada(e.target.value);
              }}
            >
              {travessas.map((travessas) => (
                <MenuItem key={travessas.id} value={travessas.id}>
                  {travessas.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.cobertura?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2}>
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              {...register("valorCobertura", { value: valorTotalCobertura })}
              onClick={onSubmit(handleClick)}
              //disabled={valorButtonClicked}
            />
            <Typography variant="subtitle2">
              {errors?.valorCobertura?.message}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={3} lg={3} marginTop="24px">
            <Typography sx={{ fontSize: "14px" }}>
              numero do orçamento: {idOrcamentoEtapa3}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}
function mapStateToProps(state) {
  return {
    idOrcamentoEtapa3: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
    largura: state.orcamentoReducer.largura,
    avanco: state.orcamentoReducer.avanco,
  };
}
export default connect(mapStateToProps)(FormaCalcularCobertura);
