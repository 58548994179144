
import { Http } from "../api";

class AgendaService {
  static async create(obj) {
    let res = null;
    await Http.post("/agenda", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async atualizarTarefa(obj) {
    let res = null;
    await Http.post("/atualizartarefa", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async getAgendas(obj) {
    let res = null;
    await Http.get("/agenda", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  
  static async getAgendasDia(obj) {
    let res = null;
    await Http.get("/agendadia", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  
  static async getTecnicos(obj) {
    let res = null;
    await Http.get("/buscartecnicos", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async getVendas(obj) {
    let res = null;
    await Http.get("/aguardandoagenda", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async updateEventDate(id, data) {
    let res = null;

    await Http.put(`agenda/${id}`,data).then((response) => {
      res = response.data;

    });
    return res;
  }


  static async Delete(id) {
    let res = null;
    await Http.delete(`agenda/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

}
export default AgendaService;