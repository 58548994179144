import React, {  useState } from 'react';
import { Box, Grid,  TextField, Typography,Button } from '@mui/material';
import { connect, useDispatch } from 'react-redux';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';
import { MaskValor } from '../../../../utils/mascaras';
import OrcamentoToldosService from '../../../../services/OrcamentoToldosService';




function FormCalcMaoDeObraArticulado({  IdOrcamentoEstrutura }) {
  const dispatch = useDispatch();

  const [valorTotalMaoDeObra, setValorTotalMaoDeObra] = useState(0);
  const [valorButtonClicked, setValorButtonClicked] = useState(false);

  function handleCalcularMaodeObra() {
    setValorButtonClicked(true)
    const dadosParaEnviar = {
      valor_mao_de_obra: valorTotalMaoDeObra,   
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,  
    };
    dispatch(changeloading({ open: true }));
    OrcamentoToldosService.calcularMaodeObra(dadosParaEnviar).then((res) => {
      setValorTotalMaoDeObra(res.valorTotal)     
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  }

  return (
    <Box>
     
        <Grid container spacing={2}>
          <Grid item xs={12} marginBottom={3}>
            <Typography variant='h5' marginLeft={2} marginTop={3}>Calcular Mão de Obra</Typography>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} >
            <Button
              type='submit'
              variant='contained'
              onClick={handleCalcularMaodeObra}
              disabled={valorButtonClicked}
            >Gerar valor</Button>
          </Grid>

          <Grid item xs={12} sm={3} md={2} lg={2} >
            <TextField
              label='Valor'
              variant='outlined'
              fullWidth
              size='small'
              value={MaskValor(valorTotalMaoDeObra)}
              onChange={(e) => setValorTotalMaoDeObra(e.target.valeu)}
              disabled
            />
          </Grid>

        </Grid>   
    </Box>
  )
}

function mapStateToProps(state) {
  return {  
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,     
  }
}

export default connect(mapStateToProps) (FormCalcMaoDeObraArticulado);
