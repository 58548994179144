import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import OrcamentoToldosService from "../../../../services/OrcamentoToldosService";

function FormCalcularEletrica({
  idOrcamento,
  IdOrcamentoEstrutura,
  orcamentoId,
}) {
  const [motorSelecionado, setMotorSelecionado] = useState("");
  const [motor, setMotor] = useState([]);
  const [eletricaSelecionado, setEletricaSelecionado] = useState("");
  const [eletrica, setEletrica] = useState([]);
  const [valor, setValor] = useState("");

  const dispatch = useDispatch();

  const getMotores = async () => {
    const res = await OrcamentoToldosService.buscarMotores();
    if (res) {
      setMotor(res.motores);
      setEletrica(res.motores);
    }
  };

  useEffect(() => {
    getMotores();
  }, []);

  const handleCalcularViga = () => {
    const dadosParaEnviar = {
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      idOrcamento: idOrcamento,
      IdMotor: motorSelecionado,
      IdEletrica: eletricaSelecionado,
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.calcularEletrica(dadosParaEnviar)
      .then((res) => {
        setValor(res.valorTotal);
        dispatch(changeloading({ open: false }));
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const handleEdit = () => {
    const dadosParaEnviar = {
      idOrcamento: orcamentoId,
      IdMotor: motorSelecionado,
      IdEletrica: eletricaSelecionado,
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.editEtapaDez(orcamentoId, dadosParaEnviar)
      .then((res) => {
        setValor(res.valorTotal);
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: "Eletrica editada com sucesso",
          })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaDez(id);
    if (response) {
      const orcamento = response;
      orcamento.produtos.forEach((produto) => {
        if (produto.tipo_produto === "motor") {
          setMotorSelecionado(produto.produto_id);
        } else if (produto.tipo_produto === "mao_obra") {
          setEletricaSelecionado(produto.produto_id);
        }
      });
      setValor(orcamento.valor_forro);
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = () => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleCalcularViga();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h5" marginLeft={2} marginTop={2}>
            Calcular Motor
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Typography>Selecione o motor:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id="motor"
            size="small"
            fullWidth
            value={motorSelecionado}
            onChange={(e) => setMotorSelecionado(e.target.value)}
          >
            {motor.map((motor) => (
              <MenuItem key={motor.id} value={motor.id}>
                {motor.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={4}></Grid>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Typography>Selecione mão de obra da eletrica:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id="eletrica"
            size="small"
            fullWidth
            value={eletricaSelecionado}
            onChange={(e) => setEletricaSelecionado(e.target.value)}
          >
            {eletrica.map((eletrica) => (
              <MenuItem key={eletrica.id} value={eletrica.id}>
                {eletrica.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={3} md={3} lg={2}>
          <TextField
            label="Valor"
            variant="outlined"
            fullWidth
            size="small"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            onClick={handleClick}
            sx={{ textAlign: "right" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}

export default connect(mapStateToProps)(FormCalcularEletrica);
