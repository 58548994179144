import React from "react";
import { Container, StyledLink } from "./styles";
import CountUp from "react-countup";
import { Grid } from "@mui/material";

function CardCuston({ color, title, amount, footerLabel, icon, to }) {
  return (
    <Grid xs={10} sm={5} md={4} lg={3} xl={2} marginLeft={4} marginRight={4}>
      {/* Utilize o StyledLink para envolver o Container */}
      <StyledLink to={to}>
        <Container color={color}>
          <span>{title}</span>
          <h1>          
            <CountUp end={amount} />
          </h1>
          <small>{footerLabel}</small>
          <img src={icon} alt={title} />
        </Container>
      </StyledLink>
    </Grid>
  );
}

export default CardCuston;
