import { Http } from "../api";

class CaixaService {
  static async create(obj) {
    let res = null;
    await Http.post("/caixa", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async buscarMovimentacaoMes(obj) {
    let res = null;
    await Http.post("/movimentacaomes", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async get(obj) {
    let res = null;
    await Http.get("/caixa", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async getTransacaoMes(obj) {
    let res = null;
    await Http.post("/transacaomes", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async getTransacoesAtrasadas(obj) {
    let res = null;
    await Http.post("/atrasadas", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async mudarStatus(obj) {
    let res = null;
    await Http.post("/mudarstatus", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getTotalAno(obj) {
    let res = null;
    await Http.get("/totalano").then((response) => {
      res = response.data;
    });
    return res;
  }
}
export default CaixaService;
