import { ArrowUpward, ArrowDownward } from "@mui/icons-material";
import { Box, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CaixaService from "../../services/CaixaService";

function Listagem() {
  const [caixa, setCaixa] = useState([]);

  const getCaixa = async () => {
    const res = await CaixaService.get();
    if (res) {
      setCaixa(res);
    }
  };

  useEffect(() => {
    getCaixa();
  }, []);

  const handleMouseEnter = (index) => {
    setCaixa((prevCaixa) => {
      const updatedCaixa = [...prevCaixa];
      updatedCaixa[index].isHovered = true;
      return updatedCaixa;
    });
  };

  const handleMouseLeave = (index) => {
    setCaixa((prevCaixa) => {
      const updatedCaixa = [...prevCaixa];
      updatedCaixa[index].isHovered = false;
      return updatedCaixa;
    });
  };

  return (
    <Box marginTop={5}>
      {caixa.map((item, index) => (
        <Paper
          key={index}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={() => handleMouseLeave(index)}
          sx={{
            position: "relative",
            width: "700px",
            height: "50px",
            backgroundColor:
              item.tipo_movimentacao === "entrada" ? "#388e3c" : "#e53935",
            transition: "all 0.3s ease",
            marginLeft: item.isHovered ? "50px" : "0", // Adiciona margem à esquerda quando hover
            marginBottom: "10px", // Espaçamento entre os boxes
          }}
        >
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: "10px",
              left: "18px",
              fontWeight: "600",
            }}
          >
            {item.descricao}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: "10px",
              left: "128px",
              fontWeight: "600",
            }}
          >
            {item.nome}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: "10px",
              left: "258px",
              fontWeight: "600",
            }}
          >
            {item.tipo_pagamento}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: "10px",
              left: "398px",
              fontWeight: "600",
            }}
          >
            {item.valor_parcela}
          </Typography>
          <Typography
            variant="subtitle1"
            sx={{
              position: "absolute",
              top: "10px",
              left: "528px",
              fontWeight: "600",
            }}
          >
            {item.data}
          </Typography>
          {item.tipo_movimentacao === "entrada" ? (
            <ArrowUpward
              sx={{
                position: "absolute",
                left: "638px",
                top: "-13px",
                fontWeight: "600",
                fontSize: "75px",
                opacity: 0.5,
              }}
            />
          ) : (
            <ArrowDownward
              sx={{
                position: "absolute",
                left: "638px",
                top: "-13px",
                fontWeight: "600",
                fontSize: "75px",
                opacity: 0.5,
              }}
            />
          )}
        </Paper>
      ))}
    </Box>
  );
}

export default Listagem;
