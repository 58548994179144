import React from 'react';
import { Box, Button, Card, CardContent, CardMedia, TextField, Typography } from '@mui/material';

// react hook form
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import * as yup from "yup"

import Logo from '../image/logoSemFundo.png'
import { useDispatch } from 'react-redux';
import { login } from '../store/actions/authActions';
import { useNavigate } from 'react-router-dom';
import { changeNotify } from '../store/actions/notify.actions';


const schema = yup
  .object({
    email: yup.string().email().required('obrigatorio'),
    password: yup.string().required('campo obrigatorio').min(5, 'a senha tem que ter pelo menos 5 caracteries'),
  })

function Login() {
  const { register, handleSubmit: onSubmit, formState: { errors }, } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSubmit = async (credentials) => {
    try {
      await dispatch(login(credentials));
      navigate("/dashboard");    
     
    } catch (error) {
      return
    }
  };

  return (
    <Box width='100vw' height='100vh' display='flex' alignItems='center' justifyContent='center'
    sx={{ background: 'linear-gradient(to bottom, #333333, #666666 30%, #999999 70%, #DDDDDD, #333333)', }}>
      <Card sx={{ backgroundColor: '#fff' }}>
        <CardContent sx={{ backgroundColor: '#fff' }}>
          <Box display='flex' flexDirection='column' gap={2} width={420} height={500} alignItems='center' >
            <CardMedia
              component="img"
              height={260}
              image={Logo}
              alt="logo"
              style={{ objectFit: 'cover' }}
            />
            <form onSubmit={onSubmit(handleSubmit)}>
              <Box display="flex" flexDirection="column" width={300}>
                <Box marginTop="20px">
                  <TextField
                    label="Email"
                    type="email"
                    size='small'
                    fullWidth
                    {...register("email")}
                    autoComplete='email'
                    color="secondary"
                    sx={{ backgroundColor: '#E5E5E5', borderRadius: '6px' }}
                  />
                  <Typography color='#F65C5C'>{errors?.email?.message}</Typography>
                </Box>

                <Box marginTop="20px">
                  <TextField
                    label="Senha"
                    type="password"
                    fullWidth
                    size='small'
                    {...register("password")}
                    color="secondary"
                    sx={{ backgroundColor: '#E5E5E5', borderRadius: '6px' }}
                  />
                  <Typography color='#F65C5C'>{errors?.password?.message}</Typography>
                </Box>
                <Box marginTop="20px">
                  <Button type='submit' variant='contained' fullWidth>Enviar</Button>
                </Box>

              </Box>
            </form>
          </Box>
        </CardContent>
      </Card>

    </Box>
  )

}

export default Login;