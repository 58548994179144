import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  DialogContent,
  Grid,
  Modal,
  Paper,
  Typography,
  Stepper,
  Step,
  StepLabel,
  Select,
  MenuItem,
  InputLabel,
  TextField,
} from "@mui/material";
import { Add } from "@mui/icons-material";
import FormCalcularEstrurura from "./FormulariosOrcamentos/FormCalcularEstrutura";
import FormCalcularPe from "./FormulariosOrcamentos/FormCalcularPe";
import FormCalcularCobertura from "./FormulariosOrcamentos/FormCalcularCobertura";
import FormCalcularCalha from "./FormulariosOrcamentos/FormCalcularCalha";
import ProdutosService from "../../../services/ProdutosService";
import OrcamentoService from "../../../services/OrcamentoService";
import FormCalcularAcessorios from "./FormulariosOrcamentos/FormCalcularAcessorios";
import FormCalcularVigaW from "./FormulariosOrcamentos/FormCalcularVigaW";
import FormCalcularTrelica from "./FormulariosOrcamentos/FormCalcularTrelica";
import FormCalcularPintura from "./FormulariosOrcamentos/FormCalcularPintura";
import FormCalcularForro from "./FormulariosOrcamentos/FormCalcularForro";
import FormCalcularMaodeObra from "./FormulariosOrcamentos/FormCalcularMaodeObra";
import FormCalcularComissao from "./FormulariosOrcamentos/FormCalcularComissao";
import { Link } from "react-router-dom";
import FormCalcArticulado from "./FormulariosCalcToldos/FormCalcArticulado";
import FormCalcMaoDeObraArticulado from "./FormulariosCalcToldos/FormCalcMaoDeObraArticulado";
import FormCalcMotor from "./FormulariosCalcToldos/FormCalcMotor";
import FormCalcularEletrica from "./FormulariosOrcamentos/FormCalcularEletrica";
import FormAdicionarProdutosAleatorio from "./FormulariosOrcamentos/FormAdicionarProdutosAleatorio";
import FormTotalPrecoEtapas from "./FormulariosOrcamentos/FormTotalPrecoEtapas";
import { connect } from "react-redux";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: "95%",
  backgroundColor: "#fff",
  border: "2px solid #002",
  boxShadow: 24,
  overflow: "auto",
};

function AddOrcamento({ idOrcamento }) {
  const [open, setOpen] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [stepLabels, setStepLabels] = useState([""]);
  const [servico, setServico] = useState([]);
  const [servicoSelecionado, setServicoSelecionado] = useState("");
  const [orcamentoId, setOrcamentoId] = useState("");

  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const getServico = async () => {
    const res = await ProdutosService.buscarServicos();
    if (res) {
      setServico(res.servico);
    }
  };

  useEffect(() => {
    getServico();
  }, []);

  const handleBack = () => {
    setActiveStep((prevStep) => prevStep - 1);
    
    if (idOrcamento) {
      setOrcamentoId(idOrcamento);
    }
  };

  const handleNext = () => {
    setActiveStep((prevStep) => prevStep + 1);
  };

  const handleOptionChange = (event) => {
    setServicoSelecionado(event.target.value);
    setActiveStep(0); // Resetar o passo ativo ao trocar de opção

    // Definir os nomes das etapas dinamicamente com base na opção selecionada
    switch (event.target.value) {
      case 1:
        setStepLabels([
          "Estrutura",
          "Pé",
          "Cobertura",
          "Calha",
          "Acessórios",
          "Viga W",
          "Treliça",
          "Pintura",
          "Forro",
          "Eletrica",
          "+ Produtos",
          "Mao de Obra",
          "Comissão",
          "Preços das etapas",
        ]);
        break;
      case 2:
        setStepLabels(["calha"]);
        break;
      case 3:
        setStepLabels(["Toldos Avançados", "Outra Etapa", "Mais uma Etapa"]);
        break;
      case 4:
        setStepLabels([
          "Estrutura",
          "Motor",
          "Calha /Rufo",
          "Mão de obra",
          "Comissão",
          "Preços das etapas",
        ]);
        break;
      default:
        break;
    }
  };

  const handleOrcamentoIdKeyDown = async (e) => {
    if (e.key === "Enter") {
      const id = e.target.value;

      setOrcamentoId(id);

      if (id) {
        try {
          const response = await OrcamentoService.trazerTipoCobertura(id);

          const servicoId = response.servico_id;
          setServicoSelecionado(servicoId);

          switch (servicoId) {
            case 1:
              setStepLabels([
                "Estrutura",
                "Pé",
                "Cobertura",
                "Calha",
                "Acessórios",
                "Viga W",
                "Treliça",
                "Pintura",
                "Forro",
                "Eletrica",
                "+ Produtos",
                "Mao de Obra",
                "Comissão",
                "Preços das etapas",
              ]);
              break;
            case 2:
              setStepLabels(["calha"]);
              break;
            case 3:
              setStepLabels([
                "Toldos Avançados",
                "Outra Etapa",
                "Mais uma Etapa",
              ]);
              break;
            case 4:
              setStepLabels([
                "Estrutura",
                "Motor",
                "Calha /Rufo",
                "Mão de obra",
                "Comissão",
                "Preços das etapas",
              ]);
              break;
            default:
              break;
          }
        } catch (error) {
          console.error("Erro ao buscar o tipo de cobertura:", error);
        }
      }
    }
  };

  const renderModalContent = () => {
    switch (servicoSelecionado) {
      case 1:
        return (
          <>
            {activeStep === 0 && (
              <FormCalcularEstrurura
                servicoSelecionado={servicoSelecionado}
                orcamentoId={orcamentoId}
              />
            )}
            {activeStep === 1 && <FormCalcularPe orcamentoId={orcamentoId} />}
            
            {activeStep === 2 && (
              <FormCalcularCobertura orcamentoId={orcamentoId} />
            )}
            {activeStep === 3 && (
              <FormCalcularCalha orcamentoId={orcamentoId} />
            )}
            {activeStep === 4 && (
              <FormCalcularAcessorios orcamentoId={orcamentoId} />
            )}
            {activeStep === 5 && (
              <FormCalcularVigaW orcamentoId={orcamentoId} />
            )}
            {activeStep === 6 && (
              <FormCalcularTrelica orcamentoId={orcamentoId} />
            )}
            {activeStep === 7 && (
              <FormCalcularPintura orcamentoId={orcamentoId} />
            )}
            {activeStep === 8 && (
              <FormCalcularForro orcamentoId={orcamentoId} />
            )}
            {activeStep === 9 && (
              <FormCalcularEletrica orcamentoId={orcamentoId} />
            )}
            {activeStep === 10 && (
              <FormAdicionarProdutosAleatorio orcamentoId={orcamentoId} />
            )}
            {activeStep === 11 && (
              <FormCalcularMaodeObra orcamentoId={orcamentoId} />
            )}
            {activeStep === 12 && (
              <FormCalcularComissao orcamentoId={orcamentoId} />
            )}
            {activeStep === 13 && (
              <FormTotalPrecoEtapas orcamentoId={orcamentoId} />
            )}
          </>
        );
      case 2:
        return (
          <>
            {activeStep === 0 && (
              <FormCalcArticulado
                servicoSelecionado={servicoSelecionado}
                orcamentoId={orcamentoId}
              />
            )}
          </>
        );
      case 3:
        return <></>;
      case 4:
        return (
          <>
            {activeStep === 0 && (
              <FormCalcArticulado
                servicoSelecionado={servicoSelecionado}
                orcamentoId={orcamentoId}
              />
            )}
            {activeStep === 1 && <FormCalcMotor orcamentoId={orcamentoId} />}
            {activeStep === 2 && (
              <FormCalcularCalha orcamentoId={orcamentoId} />
            )}
            {activeStep === 3 && (
              <FormCalcMaoDeObraArticulado orcamentoId={orcamentoId} />
            )}
            {activeStep === 4 && (
              <FormCalcularComissao orcamentoId={orcamentoId} />
            )}
            {activeStep === 5 && (
              <FormTotalPrecoEtapas orcamentoId={orcamentoId} />
            )}
          </>
        );
      default:
        return null;
    }
  };

  const handleStepClick = (index) => {
    setActiveStep(index);
  };

  return (
    <Box
      component={Paper}
      elevation={5}
      sx={{ flexGrow: 1 }}
      marginTop={1}
      padding={1}
    >
      <Grid container spacing={2} justifyContent="center">
        <Grid item xs={7} sm={8} md={5}>
          <Typography variant="h1" fontSize="35px!important">
            Orçamentos
          </Typography>
        </Grid>

        <Grid item xs={12} sm={6} md={3}>
          <Link to="/tableorcamento" style={{ textDecoration: "none" }}>
            <Button fullWidth variant="contained" size="small" color="success">
              Todos Orçamentos
            </Button>
          </Link>
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button
            variant="contained"
            fullWidth
            size="small"
            onClick={handleOpen}
            startIcon={<Add />}
          >
            Orçamento
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, maxHeight: "80vh", overflowY: "auto" }}>
              <DialogContent sx={{ overflow: "auto" }}>
                <Box display='flex' >                 
                  <Box width={300} marginBottom={4}>
                    <InputLabel id="servico" sx={{ fontSize: "15px" }}>
                      Tipo de Serviço?
                    </InputLabel>
                    <Select
                      id="servico"
                      fullWidth
                      size="small"
                      value={servicoSelecionado}
                      onChange={handleOptionChange}
                    >
                      {servico.map((servico) => (
                        <MenuItem key={servico.id} value={servico.id}>
                          {servico.servico}
                        </MenuItem>
                      ))}
                    </Select>
                  </Box>
                  <Box marginLeft={5}>
                    <InputLabel id="orcamentoId" sx={{ fontSize: "15px" }}>
                      Qual Orçamento?
                    </InputLabel>
                    <TextField
                      id="orcamentoId"
                      fullWidth
                      size="small"
                      value={orcamentoId}
                      onKeyDown={handleOrcamentoIdKeyDown}
                      onChange={(e) => setOrcamentoId(e.target.value)}
                      placeholder="Digite o ID do orçamento"
                    />
                  </Box>
                </Box>
                {stepLabels.length > 0 && ( // Renderizar o Stepper apenas se houver etapas definidas
                  <Stepper activeStep={activeStep} alternativeLabel>
                    {stepLabels.map((label, index) => (
                      <Step key={index}>
                        <StepLabel sx={{ cursor: 'pointer' }} onClick={() => handleStepClick(index)}>{label}</StepLabel>
                      </Step>
                    ))}
                  </Stepper>
                )}
                {renderModalContent()}
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    marginTop: "20px",
                  }}
                >
                  <Button disabled={activeStep === 0} onClick={handleBack}>
                    Voltar
                  </Button>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={handleNext}
                  >
                    {activeStep === stepLabels.length - 1
                      ? "Finalizar"
                      : "Próximo"}
                  </Button>
                </Box>
              </DialogContent>
            </Box>
          </Modal>
        </Grid>
      </Grid>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}
export default connect(mapStateToProps)(AddOrcamento);
