import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { SelectMesAno, TableComponet } from "../../components";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import CaixaService from "../../services/CaixaService";

const headers = [
  {
    id: "id",
    label: "Id",
    props: {
      align: "left",
    },
  },
  {
    id: "nome",
    label: "Venda",
    props: {
      align: "right",
    },
  },

  {
    id: "tipo_pagamento",
    label: "Pagamento",
    props: {
      align: "right",
    },
  },
  {
    id: "categoria_financeira",
    label: "Categoria",
    props: {
      align: "right",
    },
  },
  {
    id: "descricao",
    label: "Descrição",
    props: {
      align: "right",
    },
  },
  {
    id: "valor_parcela",
    label: "Valor",
    props: {
      align: "right",
    },
  },
  {
    id: "data",
    label: "Data",
    props: {
      align: "right",
    },
  },
];

function TableEntradaPrevista() {
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [anoSelecionado, setAnoSelecionado] = useState("");
  const [mounted, setMounted] = useState(true);
  const [dataMes, setDataMes] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getLastUpdateTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return `${hours}:${minutes} - ${day}/${month}/${year}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMesSelecionado(currentMonth);
    setAnoSelecionado(currentYear);
  }, []);

  async function getTransacaoMes() {
    setLoading(true);
    const data = {
      ano: anoSelecionado,
      mes: mesSelecionado,
      tipo: "entrada",
      status: "pendente",
    };
    const res = await CaixaService.getTransacaoMes(data);

    if (res) {
      setDataMes(res);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getTransacaoMes();
        dispatch(changeloading({ open: false }));
      }
    };

    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMesSelecionado(currentMonth);
    setAnoSelecionado(currentYear);
  }, []);

  useEffect(() => {
    getTransacaoMes();
  }, [mesSelecionado, anoSelecionado]);

  return (
    <Box>
      <Box
        component={Paper}
        elevation={5}
        sx={{ flexGrow: 1 }}
        marginTop={1}
        padding={1}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={7} sm={6} md={6}>
            <Typography variant="h1" fontSize="35px!important">
              Entrada Mês
            </Typography>
          </Grid>
          <SelectMesAno
            mesSelecionado={mesSelecionado}
            setMesSelecionado={setMesSelecionado}
            anoSelecionado={anoSelecionado}
            setAnoSelecionado={setAnoSelecionado}
          />
        </Grid>
      </Box>
      <Box marginTop={4}>
        <TableComponet
          headers={headers}
          data={dataMes}
          labelCaption={"Nenhuma registro encontrada!!"}
          loading={loading}
          labelTable={"Caixa"}
          handlerEditarAction={""}
          handlerDeletarAction={""}
        />
      </Box>
    </Box>
  );
}

export default TableEntradaPrevista;
