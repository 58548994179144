import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import UsersService from "../../../../services/UsersService";

function FormCalcularComissao({ idOrcamento, IdOrcamentoEstrutura, orcamentoId }) {
  const [vendedorSelecionado, setVendedorSelecionado] = useState("");
  const [vendedor, setVendedor] = useState([]);
  const [vendedorError, setVendedorError] = useState(false);
  const [valorTotalOrcamento, setValorTotalOrcamento] = useState("");
  const [id, setId] = useState("");

  const [porcentagemVendedor, setPorcentagemVendedor] = useState("5,5");
  const [porcentagemImposto, setPorcentagemImposto] = useState("6");
  const [porcentagemInterna, setPorcentagemInterna] = useState("2");

  const [valor, setValor] = useState("");
  const dispatch = useDispatch();

  const getVendedor = async () => {
    const res = await UsersService.buscarVendedor();
    if (res) {
      setVendedor(res.vendedor);
    }
  };
  useEffect(() => {
    getVendedor();
  }, []);

  function handleCalcularComissao() {
    if (!vendedorSelecionado) {
      setVendedorError(true);
      return;
    }
  
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      orcamentoId:orcamentoId,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      vendedor: vendedorSelecionado,
      porcentagemVendedor: porcentagemVendedor,
      porcentagemImposto: porcentagemImposto,
      porcentagemInterna: porcentagemInterna,
    };
    dispatch(changeloading({ open: true }));

    OrcamentoService.calcularComissao(dadosParaEnviar)
      .then((res) => {
        setValor(res.valorTotal);
        dispatch(changeloading({ open: false }));
        trazerValorTotal();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  }

  const trazerValorTotal = async () => {
    const data = {
      idOrcamento: idOrcamento,
      orcamentoId:orcamentoId,
      quantidade: "1",
    };
    const res = await OrcamentoService.valorTotalUltimaEtapa(data);
    setValorTotalOrcamento(res.valor_total);
    setId(res.id);
  };



  const handleClick = () => {
    if (orcamentoId) {
      handleCalcularComissao();
    } else {
      handleCalcularComissao();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h5" marginLeft={2} marginTop={2}>
            Calcular comissão e impostos
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <Typography>Selecione o vendedor:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <FormControl fullWidth error={vendedorError}>
            <Select
              id="vendedor"
              size="small"
              fullWidth
              value={vendedorSelecionado}
              onChange={(e) => {
                setVendedorSelecionado(e.target.value);
                setVendedorError(false);
              }}
            >
              <MenuItem value="" disabled>
                Selecione o vendedor
              </MenuItem>
              {vendedor.map((vendedor) => (
                <MenuItem key={vendedor.id} value={vendedor.id}>
                  {vendedor.name}
                </MenuItem>
              ))}
            </Select>
            {vendedorError && (
              <FormHelperText>vendedor obrigatorio</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={2}>
          <TextField
            type="text"
            fullWidth
            size="small"
            label="porcentagem %"
            value={porcentagemVendedor}
            onChange={(e) => setPorcentagemVendedor(e.target.value)}
          />
        </Grid>

        {/* divisa */}

        <Grid container spacing={2} marginTop={2} marginLeft="2px">
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography>Imposto:</Typography>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={2}>
            <TextField
              type="text"
              fullWidth
              size="small"
              label="Porcentagem %"
              value={porcentagemImposto}
              onChange={(e) => setPorcentagemImposto(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}></Grid>
        </Grid>
        <Grid container spacing={2} marginTop={2} marginLeft="2px">
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography>Comissão interna:</Typography>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={2}>
            <TextField
              type="text"
              fullWidth
              size="small"
              label="Porcentagem %"
              value={porcentagemInterna}
              onChange={(e) => setPorcentagemInterna(e.target.value)}
            />
          </Grid>
          <Grid item xs={4} sm={3} md={3} lg={2}>
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              value={valor}
              onChange={(e) => setValor(e.target.value)}             
              onClick={handleClick}
              sx={{ textAlign: "right" }}
             
            />
          </Grid>
          <Grid item xs={6} sm={6} md={6} lg={3}>
            <Typography>
              Valor Total do orçamento: <b>{valorTotalOrcamento}</b>
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Typography>numero do orçamento: {idOrcamento ? idOrcamento : id }</Typography>
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}
function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}
export default connect(mapStateToProps)(FormCalcularComissao);
