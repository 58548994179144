import { Http } from "../api";

class CategoriaFinanceira {
  static async create(obj) {
    let res = null;
    await Http.post("/categoriafinanceira", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async buscarCategoria(obj) {
    let res = null;
    await Http.get("/categoriafinanceira", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async getRegras(obj) {
    let res = null;
    await Http.get("regras/multiplicacao", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async updateRegra(id, data) {
    let res = null;

    await Http.put(`regras/multiplicacao/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
}
export default CategoriaFinanceira;
