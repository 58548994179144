import {
    Home,
    AppRegistration,
    ContentCopy,
    Note,
    Build,
    LocalHospital,
    Settings,
    RequestQuote,
    AddHomeWork,
    Person,
    Assignment,
    MonetizationOn,
    Shop,
    CalendarMonth,
    Engineering,
  } from "@mui/icons-material";
  
  export const iconMap = {
    Home: Home,
    AppRegistration: AppRegistration,
    ContentCopy: ContentCopy,
    Note: Note,
    Build: Build,
    LocalHospital: LocalHospital,
    Settings: Settings,
    RequestQuote: RequestQuote,
    AddHomeWork: AddHomeWork,
    Person: Person,
    Assignment: Assignment,
    MonetizationOn: MonetizationOn,
    Shop: Shop,
    CalendarMonth: CalendarMonth,
    Engineering: Engineering,
   
  };
  