import React from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ProdutosService from '../../../services/ProdutosService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';

const schema = yup.object({
  servico: yup.string().required().min(3),
});

function Servico() {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();

  function handleSubmit(data) {
    dispatch(changeloading({ open: true, msg: 'cadastrando..' }))
    ProdutosService.cadastrarServico(data).then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  }
  return (
    <Box component={Paper} sx={{ flexGrow: 1 }} marginTop={10} padding={3}>
      <Typography variant='h1' sx={{ fontSize: '25px!important', marginBottom: '15px' }}>Cadastrar novo tipo de Serviço </Typography>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={8}>
            <TextField
              label='Serviço'
              variant='outlined'
              fullWidth
              size='small'
              {...register("servico")}
            />
            <Typography variant='subtitle2'>{errors?.servico?.message}</Typography>

          </Grid>
          <Grid item xs={12} md={4}>
            <Button type='submit' variant='contained'>Salvar</Button>
          </Grid>

        </Grid>
      </form>
    </Box>
  )
}

export default Servico;