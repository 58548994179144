import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  TextField,
  Typography,
  IconButton
} from "@mui/material";
import { useDispatch, connect } from "react-redux";
import ProdutosService from "../../../../services/ProdutosService";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeNotify } from "../../../../store/actions/notify.actions";
import { changeloading } from "../../../../store/actions/loading.action";
import { MaskValor } from "../../../../utils/mascaras";
import { useDebounce } from "../../../../hooks/UseDebounce";
import DeleteIcon from '@mui/icons-material/Delete';

function FormAdicionarProdutosAleatorio({ idOrcamento, IdOrcamentoEstrutura, orcamentoId }) {
  const dispatch = useDispatch();

  const [selecoes, setSelecoes] = useState([]);
  const [valor_chapa, setValor_chapa] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [optionsProdutos, setOptionsProdutos] = useState([]);
  const [busca, setBusca] = useState("");
  const { debounce } = useDebounce();

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ProdutosService.get(busca).then((res) => {
        setIsLoading(false);
        setOptionsProdutos(
          res.produtos.map((produto) => ({
            id: produto.produto_id,
            label: produto.nome,
          }))
        );
      });
    });
  }, [busca]);

  const adicionarSelecao = (produto) => {
    setSelecoes([
      ...selecoes,
      { id: produto.id, label: produto.label, quantidade: 1 },
    ]);
  };

  const handleDeleteAccessory = async (index, id) => {
    dispatch(changeloading({ open: true, msg: 'excluindo ...' }));
    try {
      await OrcamentoService.excluirProduto(id); // Função para excluir o acessório no backend
      const acessoriosAtualizados = selecoes.filter((_, i) => i !== index);
      setSelecoes(acessoriosAtualizados);
      dispatch(changeloading({ open: false }));
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
  };

  function handleSubmit() {
    const dadosParaEnviar = {
      selecoes: selecoes,
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      valor: "",
    };   
    // Chamar a função de serviço para calcular o preço da chapa
    OrcamentoService.calularProdutosAleatorios(dadosParaEnviar)
      .then((res) => {
        setValor_chapa(res.preco_total);
        dispatch(changeloading({ open: false }));
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  }

  const handleEdit = () => {
    const dadosParaEnviar = {
      selecoes: selecoes,
      idOrcamento: orcamentoId,
     
    };

    dispatch(changeloading({ open: true, msg: "editando" }));
    OrcamentoService.editEtapaOnze(orcamentoId, dadosParaEnviar)
      .then((res) => {
        dispatch(changeloading({ open: false }));     
        setValor_chapa(res.preco_total);
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: "Editado com sucesso",
          })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaOnze(id);
    if (response) {
      const orcamento = response;
   
      setValor_chapa(orcamento.valor_chapa);

      const novasSelecoes = orcamento.produtos.map((produto) => ({
        id: produto.produto_id,
        label: produto.produto_nome,
        quantidade: produto.quantidade,
      }));
      setSelecoes(novasSelecoes);

      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleChangeQuantidade = (index, event) => {
    const newSelecoes = [...selecoes];
    newSelecoes[index].quantidade = event.target.value;
    setSelecoes(newSelecoes);
  };

  const handleClick = () => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleSubmit();
    }
  };

  return (
    <Box>
      <form onSubmit={(e) => { e.preventDefault(); handleSubmit(); }}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" marginLeft={2} marginTop={2}>
              Adicionar produtos se necessário
            </Typography>
          </Grid>

          <Grid item xs={12} sm={7} md={4} lg={4}>
            <Autocomplete
              openText="Abrir"
              closeText="Fechar"
              noOptionsText="Sem opções"
              loadingText="Carregando..."
              disablePortal
              loading={isLoading}
              options={optionsProdutos}
              onChange={(event, newValue) => {
                if (newValue) {
                  adicionarSelecao(newValue);
                  setBusca("");
                }
              }}
              onInputChange={(_, newValue) => setBusca(newValue)}
              popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Produto"
                  variant="outlined"
                  fullWidth
                  size="small"
                />
              )}
            />
          </Grid>

          {selecoes.map((selecao, index) => (
            <Grid key={index} container item spacing={2} alignItems="center">
              <Grid item xs={7} sm={5} md={3} lg={2}>
                <Typography>{selecao.label}</Typography>
              </Grid>
              <Grid item xs={6} sm={6} md={2}>
                <TextField
                  label="Quantidade"
                  type="number"
                  variant="outlined"
                  size="small"
                  value={selecao.quantidade}
                  onChange={(event) => handleChangeQuantidade(index, event)}
                />
              </Grid>
              <Grid item xs={2}>
                <IconButton onClick={() => handleDeleteAccessory(index, selecao.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
            </Grid>
          ))}

          <Grid item xs={6} sm={6} md={2}>
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              value={MaskValor(valor_chapa)}
              onClick={handleClick}
            />
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
  };
}

export default connect(mapStateToProps)(FormAdicionarProdutosAleatorio);
