import {
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ProdutosService from "../../../../services/ProdutosService";
import { connect, useDispatch } from "react-redux";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";

function FormCalcularForro({
  idOrcamento,
  IdOrcamentoEstrutura,
  largura,
  avanco,
  modulacao,
  orcamentoId,
}) {
  const [forroSelecionado, setPinturaForroSelecionado] = useState("");
  const [forro, setForro] = useState([]);
  const [valor, setValor] = useState("");
  const dispatch = useDispatch();

  const getForro = async () => {
    const res = await ProdutosService.buscarForros();
    if (res) {
      setForro(res.forros);
    }
  };
  useEffect(() => {
    getForro();
  }, []);

  function handleCalcularViga() {
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      idForro: forroSelecionado,
      largura: largura,
      avanco: avanco,
      modulacao: modulacao,
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.calcularForros(dadosParaEnviar)
      .then((res) => {
        setValor(res.valorTotal);
        dispatch(changeloading({ open: false }));
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  }

  const handleEdit = () => {
    const dadosParaEnviar = {
      idOrcamento: orcamentoId,
      idForro: forroSelecionado,
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.editEtapaNove(orcamentoId, dadosParaEnviar)
      .then((res) => {
        setValor(res.valorTotal);
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: "Forro editada com sucesso",
          })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaNove(id);
    if (response) {
      const orcamento = response;
      orcamento.produtos.forEach((produto) => {
        setPinturaForroSelecionado(produto.produto_id);
      });
      setValor(orcamento.valor_forro);
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = () => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleCalcularViga();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant="h5" marginLeft={2} marginTop={2}>
            Calcular Forro
          </Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Typography>Selecione o tipo de Forro:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id="forro"
            size="small"
            fullWidth
            value={forroSelecionado}
            onChange={(e) => setPinturaForroSelecionado(e.target.value)}
          >
            {forro.map((forro) => (
              <MenuItem key={forro.id} value={forro.id}>
                {forro.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={3} md={3} lg={2}>
          <TextField
            label="Valor"
            variant="outlined"
            fullWidth
            size="small"
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            onClick={handleClick}
            sx={{ textAlign: "right" }}
          />
        </Grid>
      </Grid>
    </Box>
  );
}
function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
    largura: state.orcamentoReducer.largura,
    avanco: state.orcamentoReducer.avanco,
    modulacao: state.orcamentoReducer.modulacao,
  };
}
export default connect(mapStateToProps)(FormCalcularForro);
