import { combineReducers } from "redux";
import loadingReducer from "./loading.reducer";
import notifyReducer from "./notify.reducer"
import alertReducer from "./alert.reducer";
import orcamentoReducer from "./orcamentoReducer";
import retornoReducer from "./retornoReducer";

const rootReducer = combineReducers({
    loadingReducer,   notifyReducer, alertReducer, orcamentoReducer, retornoReducer
});

export default rootReducer;