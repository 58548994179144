import React, { useEffect, useState } from 'react';
import { Autocomplete, Box, CircularProgress, Grid, IconButton, InputAdornment, TextField, } from '@mui/material';
import { Confirm, TableComponet } from '../../../components';
import SearchIcon from '@mui/icons-material/Search';
import { useDebounce } from '../../../hooks/UseDebounce';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import AddFornecedores from './AddFornecedores';
import FornecedoresService from '../../../services/FornecedoresService';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';

const headers = [

  {
    id: "razao_social",
    label: "razao Social",
    props: {
      align: 'right',
    },
  },

  {
    id: 'email',
    label: 'Email',
    props: {
      align: 'right',
    },
  },
  {
    id: 'telefone',
    label: 'Telefone',
    props: {
      align: 'right',
    },
  },
  {
    id: 'cnpj',
    label: 'Cnpj',
    props: {
      align: 'right',
    },
  },
  {
    id: 'endereco',
    label: 'Endereço',
    props: {
      align: 'right',
    },
  },
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];
function Fornecedores() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  //const da tabela
  const [fornecedor, setFornecedor] = useState([])
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
    //const auto complete
    const [optionsFornecedor, setOptionsFornecedor] = useState([]);
    const [busca, setBusca] = useState('');
 

    const [isLoading, setIsLoading] = useState(false);
    const { debounce } = useDebounce();
    const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

    useEffect(() => {
      setIsLoading(true);
      debounce(() => {
        FornecedoresService.get(busca)
          .then((res) => {
            setIsLoading(false);
            setFornecedor(res.fornecedor || [])
            setOptionsFornecedor(res.fornecedor.map(fornecedor => ({ id: fornecedor.id, label: fornecedor.razao_social })))
          })
      },);
    }, [busca, tableUpdateTrigger]);

    const handleEdit = (fornecedor) => {
      navigate(`/editfornecedor/${fornecedor.id}`, { state: { fornecedor } });
    };

     //const do confirm
  const handleOpenConfirmDialog = (id) => {   
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });


  const handleDelete = () => {
    const idToDelete = confirmar.id.id;
    FornecedoresService.Delete(idToDelete)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({open: true,class: 'success',msg: res.message,}) );
        setTableUpdateTrigger((prev) => prev + 1);})
      .catch((error) => {
      
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({  open: true,  class: 'error',  msg: error.response.data.error,  }));
      });
    handleCloseConfirmDialog();
  };



  return (
    <Box >
      <Grid container spacing={2} >

        <Grid item xs={12}>
          <AddFornecedores />
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Box padding={2} width={400} marginTop='-20px' marginLeft='-10px'>
              <Autocomplete
                openText='Abrir'
                closeText='Fechar'
                noOptionsText='Sem opções'
                loadingText='Carregando...'
                disablePortal
                options={optionsFornecedor}
                onInputChange={(_, newValue) => setBusca(newValue)}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    
                    label='Pesquisar...'
                    variant='outlined'
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
             <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse fornecedor?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
              />
            </Box>
            <Box>
              <TableComponet
                headers={headers}
                data={fornecedor}
                labelCaption={'Nenhum Fornecedor encontrado!!'}
                labelTable={'Fornecedores'}
                handlerEditarAction={(fornecedorId) => { handleEdit(fornecedorId) }}
                handlerDeletarAction={(fornecedorId) => { handleOpenConfirmDialog(fornecedorId) }}
                request
                qdtPage={totalPage}
                loading={loading}
                setData={setFornecedor}
                tableUpdateTrigger={tableUpdateTrigger}
                handlerRequest={async (page, size) => {
                  setLoading(true)
                  FornecedoresService.get('', page, size).then(
                    (data) => {
                      setLoading(false)
                      setFornecedor(data.fornecedor || [])
                      setTotalPages(data.totalPages || 0);
                      return data
                    },
                  )
                  return []
                }}
              />
            </Box>
          </Box>
        </Grid>



      </Grid>
    </Box>
  )
}

export default Fornecedores;