import { Http } from "../api";

class UsersService {
  static async create(obj) {
    let res = null;
    await Http.post("/users", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async get(obj) {
    let res = null;
    await Http.get("/users", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
 

  static async buscarVendedor(id) {
    let res = null;
    await Http.get("/buscarvendedor").then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarFotoPerfil(id) {
    let res = null;
    await Http.get(`buscarfotoperfil/${id}`).then((response) => {
      res = response.data;     
    });
    return res;
  }

  
}
export default UsersService;