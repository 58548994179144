import React, { useEffect, useMemo, useState } from "react";
import CompraService from "../../services/CompraService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Modal,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
} from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Edit,
  PublishedWithChanges,
} from "@mui/icons-material";
import { useDebounce } from "../../hooks/UseDebounce";
import FornecedoresService from "../../services/FornecedoresService";
import { changeNotify } from "../../store/actions/notify.actions";
import { Confirm, CustomTextField } from "../../components";
import moment from "moment";

function Pendentes() {
  const [compras, setCompras] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);

  const dispatch = useDispatch();

  const getStatusColor = (status) => {
    switch (status) {
      case "aguardando compra":
        return "#FFFFCC"; // Amarelo claro
      case "finalizado":
        return "#CCFFCC"; // Verde claro
      default:
        return "inherit"; // Cor padrão
    }
  };

  async function getCompras() {
    const res = await CompraService.getCompraPendente();
    if (res) {
      setCompras(res.compras);
    }
  }
  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getCompras();
        dispatch(changeloading({ open: false }));
      }
    };
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  const handleOpenEditModal = (produto) => {
    setSelectedProduct(produto);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setEditModalOpen(false);
  };

  const handleSaveChanges = async (editedItemWithStatus) => {
    dispatch(changeloading({ open: true, msg: "Alterando.." }));

    const data = {
      fornecedor_id: editedItemWithStatus.fornecedorId,
      compra: editedItemWithStatus.statusCompra,
      dataCompra: editedItemWithStatus.dataCompra,
    };

    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await CompraService.Update(
        editedItemWithStatus.orcamento_estrutura,
        data
      );
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "success",
          msg: "status da compra alterado com sucesso",
        })
      );
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: error.response.data.error,
        })
      );
    }
    getCompras();
    setEditModalOpen(false);
  };

  //const do confirm
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });

  const handleOpenConfirmDialog = (id) => {
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };

  const handleFinaizar = () => {
    const idToFinaizar = confirmar.id;
    dispatch(changeloading({ open: true, msg: "Finalizando compras .." }));
    CompraService.mudarCompra(idToFinaizar)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        getCompras();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "error", msg: error.message })
        );
      });
    handleCloseConfirmDialog();
  };

  return (
    <Box>
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell />
              <TableCell>Id Orçamento</TableCell>
              <TableCell>Id Venda</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Status da Compra</TableCell>
              <TableCell>Data da Venda</TableCell>
              <TableCell>Mudar para Finalizado</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {compras.map((compra, index) => (
              <Row
                key={index}
                compra={compra}
                getStatusColor={getStatusColor}
                onOpenEditModal={handleOpenEditModal}
                handleOpenConfirmDialog={handleOpenConfirmDialog}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Confirm
        fontSize="25px"
        open={confirmar.confirmDialogOpen}
        title="Concluiu todas as compras desse cliente?"
        onClose={handleCloseConfirmDialog}
        onConfirm={handleFinaizar}
      />
      <EditModal
        open={editModalOpen}
        handleClose={handleCloseEditModal}
        item={selectedProduct}
        handleSaveChanges={handleSaveChanges}
      />
    </Box>
  );
}

function Row({
  compra,
  getStatusColor,
  onOpenEditModal,
  handleOpenConfirmDialog,
}) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.orcamento_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.venda_id}
        </TableCell>
        <TableCell>{compra.nomeCliente || compra.razaoSocial}</TableCell>
        <TableCell>{compra.status}</TableCell>
        <TableCell>{compra.dataVenda}</TableCell>
        <TableCell>
          <IconButton
            onClick={() => handleOpenConfirmDialog(compra.orcamento_id)}
            color="primary"
          >
            <PublishedWithChanges sx={{ color: "#388e3c" }} />
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="produtos">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "grey.200" }}>
                    <TableCell>Id</TableCell>
                    <TableCell align="right">Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Unidade de Medida</TableCell>
                    <TableCell align="right">Status da Compra</TableCell>
                    <TableCell align="right">Ação</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compra.produtos.map((produto, index) => (
                    <TableRow
                      key={index}
                      sx={{
                        backgroundColor: getStatusColor(produto.statusCompra),
                      }}
                    >
                      <TableCell>{produto.orcamento_estrutura}</TableCell>
                      <TableCell align="right">{produto.nomeProduto}</TableCell>
                      <TableCell align="right">{produto.quantidade}</TableCell>
                      <TableCell align="right">{produto.uniMedida}</TableCell>
                      <TableCell align="right">
                        {produto.statusCompra}
                      </TableCell>
                      <TableCell align="right">
                        <IconButton
                          onClick={() => onOpenEditModal(produto)}
                          color="primary"
                          aria-label="edit"
                        >
                          <Edit />
                        </IconButton>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

function EditModal({ open, handleClose, item, handleSaveChanges }) {
  const [editedItem, setEditedItem] = useState({});
  const [fornecedorId, setFornecedorId] = useState("");
  const [dataCompra, setDataCompra] = useState(moment().format("DD/MM/YYYY"));

  //select unidade de medidas
  const statusCompra = ["aguardando compra", "finalizado"];
  const [statusSelecionada, setStatusSelecionada] =
    useState("aguardando compra");

  //const auto complete
  const [optionsFornecedor, setOptionsFornecedor] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [busca, setBusca] = useState("");
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);

  const handleClientSelection = (_, newValue) => {
    setSelectedId(newValue?.id);
    setFornecedorId(newValue?.id);
    setBusca("");
    setEditedItem({ ...editedItem, fornecedorId: newValue?.id });
  };

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      FornecedoresService.get(busca).then((res) => {
        setIsLoading(false);
        setOptionsFornecedor(
          res.fornecedor.map((fornecedor) => ({
            id: fornecedor.id,
            label: fornecedor.razao_social,
          }))
        );
      });
    });
  }, [busca]);

  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    const selectedOption = optionsFornecedor.find(
      (optionsFornecedor) => optionsFornecedor.id === selectedId
    );
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId, optionsFornecedor]);

  const handleChange = (event) => {
    const { name, value } = event.target;
    setEditedItem({ ...editedItem, [name]: value });
  };

  useEffect(() => {
    // Define o item editado como o item atual quando o modal é aberto
    if (item) {
      setEditedItem(item);
    }
  }, [item]);

  const [error, setError] = useState("");

  const handleSave = () => {
    if (!fornecedorId) {
      setError("Selecione um fornecedor."); // Define uma mensagem de erro se o fornecedor não for selecionado
      return;
    }
    // Adicione o status da compra e a data ao objeto editedItem
    const editedItemWithStatus = {
      ...editedItem,
      statusCompra: statusSelecionada,
      dataCompra: dataCompra, // Adicione a data ao objeto editedItemWithStatus
    };
    // Envie o objeto editedItem com o status atualizado para a função handleSaveChanges
    handleSaveChanges(editedItemWithStatus);
  };

  return (
    <Modal open={open} onClose={handleClose}>
      <Box
        sx={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          width: 400,
          bgcolor: "background.paper",
          boxShadow: 24,
          p: 4,
        }}
      >
        <Typography marginBottom={2} variant="h5" component="h2">
          Editar produto para compra
        </Typography>
        <Autocomplete
          openText="Abrir"
          closeText="Fechar"
          noOptionsText="Sem opções"
          loadingText="Carregando..."
          disablePortal
          loading={isLoading}
          options={optionsFornecedor}
          onChange={handleClientSelection}
          onInputChange={(_, newValue) => setBusca(newValue)}
          popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
          value={autoCompleteSelectedOption} // Use diretamente o objeto selecionado
          getOptionLabel={(option) => option.label} // Defina como o Autocomplete deve acessar a propriedade label do objeto
          renderInput={(params) => (
            <TextField
              {...params}
              label="Fornecedor"
              variant="outlined"
              fullWidth
              size="small"
              value={fornecedorId}
              error={Boolean(error)} // Adicione uma classe de erro se houver um erro
              helperText={error}
              onBlur={() => {
                if (fornecedorId) setError("");
              }}
            />
          )}
        />
        <Select
          label="Status da Compra"
          id="status"
          variant="outlined"
          fullWidth
          sx={{ marginTop: "10px" }}
          value={statusSelecionada}
          size="small"
          onChange={(e) => setStatusSelecionada(e.target.value)}
        >
          {statusCompra.map((statusCompra) => (
            <MenuItem key={statusCompra} value={statusCompra}>
              {statusCompra}
            </MenuItem>
          ))}
        </Select>
        <CustomTextField
          sx={{ marginTop: "10px" }}
          label="Data"
          format="##/##/####"
          mask="_"
          allowEmptyFormatting
          isAllowed={(values) => {
            const { formattedValue } = values;
            return !formattedValue || formattedValue.length <= 10;
          }}
          fullWidth
          size="small"
          value={dataCompra}
          onValueChange={(values) => setDataCompra(values.formattedValue)}
        />
        <Box sx={{ display: "flex", justifyContent: "flex-end", mt: 2 }}>
          <Button
            variant="contained"
            color="primary"
            onClick={() => handleSave()}
          >
            Salvar Alterações
          </Button>
        </Box>
      </Box>
    </Modal>
  );
}

export default Pendentes;
