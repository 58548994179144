import {
  Box,
  CircularProgress,
  IconButton,
  InputAdornment,
  Modal,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
  Button,
  Autocomplete,
  Popover,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import SearchIcon from "@mui/icons-material/Search";
import { styled } from "@mui/material/styles";
import OrcamentoService from "../../../../services/OrcamentoService";
import { useDebounce } from "../../../../hooks/UseDebounce";
import { Delete, Edit } from "@mui/icons-material";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import { connect, useDispatch } from "react-redux";
import { SelectMesAno } from "../../../../components";

const StyledTableHead = styled(TableHead)(({ theme }) => ({
  backgroundColor: theme.palette.grey[300],
  "& .MuiTableCell-head": {
    fontWeight: "bold",
    color: theme.palette.common.black,
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const EditIcon = styled(Edit)(({ theme }) => ({
  color: theme.palette.success.main,
}));

const DeleteIcon = styled(Delete)(({ theme }) => ({
  color: theme.palette.error.main,
}));

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
  outline: "none",
};

function TableRetorno({ tableUpdateTriggerPai, dataProximoRetorno }) {
  const { debounce } = useDebounce(500);
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [retorno, setRetorno] = useState([]);
  const [busca, setBusca] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [selectedRetorno, setSelectedRetorno] = useState(null);
  const [statusOptions, setStatusOptions] = useState([]);
  const [loadingStatus, setLoadingStatus] = useState(false);

  const currentDate = new Date();
  const currentMonth = currentDate.getMonth() + 1; // Janeiro é 0
  const currentYear = currentDate.getFullYear();

  const [mesSelecionado, setMesSelecionado] = useState(currentMonth);
  const [anoSelecionado, setAnoSelecionado] = useState(currentYear);

  const [anchorEl, setAnchorEl] = useState(null);
  const [selectedDeleteId, setSelectedDeleteId] = useState(null);



  const fetchRetorno = (searchString) => {
    setIsLoading(true);
    const data = {
      ano: anoSelecionado,
      mes: mesSelecionado,      
    };
    OrcamentoService.buscarRetorno(searchString, data).then((res) => {
      setIsLoading(false);
      setRetorno(res || []);
    });
  };

  const fetchStatusOptions = () => {
    setLoadingStatus(true);
    OrcamentoService.getStaus().then((res) => {
      setStatusOptions(res.status || []);
      setLoadingStatus(false);
    });
  };

  useEffect(() => {
    debounce(() => fetchRetorno(busca));
    fetchStatusOptions();
  }, [busca, tableUpdateTriggerPai, debounce, mesSelecionado, anoSelecionado ]);

  const handleEdit = (id) => {
    OrcamentoService.buscarRetornoId(id).then((res) => {
      setSelectedRetorno(res);
      setOpenModal(true);
    });
  };

  const handleCloseModal = () => {
    setOpenModal(false);
    setSelectedRetorno(null);
  };

  const handleSave = () => {
    let data = {
      data: selectedRetorno.data,
      dataProximoRetorno: selectedRetorno.dataProximoRetorno,
      tipo_status: selectedRetorno.status.id,
      observacao: selectedRetorno.observacao,
    };
    OrcamentoService.salvarRetornoId(selectedRetorno.id, data)
      .then((res) => {
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
    handleCloseModal();
    fetchRetorno();
  };

  const handleDeleteClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setSelectedDeleteId(id);
  };

  const handleDeleteConfirm = () => {
    setAnchorEl(null);
    OrcamentoService.deletarRetornoId(selectedDeleteId)
      .then((res) => {
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        fetchRetorno();
      })
      .catch((error) => {
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
    setSelectedDeleteId(null);
  };

  const handleDeleteCancel = () => {
    setAnchorEl(null);
    setSelectedDeleteId(null);
  };




  const handleButtonClick = () => {
    const today = new Date();
    const formattedDate = today.toLocaleDateString('pt-BR', {
      day: '2-digit',
      month: '2-digit',
      year: 'numeric'
    });
    setBusca(formattedDate);
  };

  return (
    <Box>
      <Box display="flex">
        <Box padding={2} width={400} marginTop="-20px" marginLeft="-10px">
          <TextField
            label="Pesquisar"
            value={busca}
            onChange={(e) => setBusca(e.target.value)}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <SearchIcon />
                </InputAdornment>
              ),
            }}
            variant="outlined"
            fullWidth
          />
        </Box>
     
          <SelectMesAno
            mesSelecionado={mesSelecionado}
            setMesSelecionado={setMesSelecionado}
            anoSelecionado={anoSelecionado}
            setAnoSelecionado={setAnoSelecionado}
          />
      
      <Box>
        <Button variant="contained" size="small" color="warning" onClick={handleButtonClick} >retorno de hoje</Button>
      </Box>
      </Box>
      <TableContainer>
        <Table aria-label="simple table">
          <StyledTableHead>
            <TableRow>
              <TableCell>Cliente</TableCell>
              <TableCell>Data Contato</TableCell>
              <TableCell>Data Prox.Contato</TableCell>
              <TableCell>Status</TableCell>
              <TableCell>Observações</TableCell>
              <TableCell>Ações</TableCell>
            </TableRow>
          </StyledTableHead>
          <TableBody>
            {isLoading ? (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  <CircularProgress />
                </TableCell>
              </TableRow>
            ) : (
              retorno.map((row) => (
                <StyledTableRow key={row.id}>
                  <TableCell>{row.nome}</TableCell>
                  <TableCell>{row.data}</TableCell>
                  <TableCell>{row.dataProximoRetorno}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>{row.observacao}</TableCell>
                  <TableCell>
                    <IconButton onClick={() => handleEdit(row.id)}>
                      <EditIcon />
                    </IconButton>
                    <IconButton onClick={(e) => handleDeleteClick(e, row.id)}>
                      <DeleteIcon />
                    </IconButton>
                  </TableCell>
                </StyledTableRow>
              ))
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <Popover
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleDeleteCancel}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Box p={2}>
          <Typography>Deseja realmente excluir?</Typography>
          <Box mt={2} display="flex" justifyContent="space-between">
            <Button size="small" onClick={handleDeleteCancel} color="secondary">
              Não
            </Button>
            <Button
              onClick={handleDeleteConfirm}
              color="primary"
              variant="contained"
              size="small"
            >
              Sim
            </Button>
          </Box>
        </Box>
      </Popover>
      <Modal
        open={openModal}
        onClose={handleCloseModal}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box sx={modalStyle}>
          <Typography variant="h4">Editar Retorno</Typography>
          {selectedRetorno && (
            <>
              <Typography variant="h5">
                orçamento: {selectedRetorno.orcamento_id}
              </Typography>
              <TextField
                label="Data Contato"
                type="date"
                value={selectedRetorno.data}
                onChange={(e) =>
                  setSelectedRetorno({
                    ...selectedRetorno,
                    data: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              />
              <TextField
                label="Data Prox. Contato"
                type="date"
                value={selectedRetorno.dataProximoRetorno}
                onChange={(e) =>
                  setSelectedRetorno({
                    ...selectedRetorno,
                    dataProximoRetorno: e.target.value,
                  })
                }
                fullWidth
                margin="normal"
              />
              <Box marginBottom={1}>
                <Autocomplete
                  fullWidth
                  size="small"
                  options={statusOptions}
                  loading={loadingStatus}
                  getOptionLabel={(option) => option.nome}
                  value={selectedRetorno.status}
                  onChange={(event, newValue) => {
                    setSelectedRetorno({
                      ...selectedRetorno,
                      status: newValue,
                      tipo_status: newValue ? newValue.id : "",
                    });
                  }}
                  isOptionEqualToValue={(option, value) =>
                    option.id === value.id
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Status"
                      variant="outlined"
                      margin="normal"
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <React.Fragment>
                            {loadingStatus ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </React.Fragment>
                        ),
                      }}
                    />
                  )}
                />
              </Box>
              <TextField
                label="Observações"
                value={selectedRetorno.observacao}
                onChange={(e) =>
                  setSelectedRetorno({
                    ...selectedRetorno,
                    observacao: e.target.value,
                  })
                }
                multiline
                minRows={1}
                maxRows={4}
                fullWidth
                margin="normal"
              />
              <Box mt={2} display="flex" justifyContent="space-between">
                <Button onClick={handleCloseModal} color="secondary">
                  Cancelar
                </Button>
                <Button
                  onClick={handleSave}
                  color="primary"
                  variant="contained"
                >
                  Salvar
                </Button>
              </Box>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

function mapStateToProps(state) {

  return {
    dataProximoRetorno: state.retornoReducer.dataProximoRetorno,
  };
}
export default connect(mapStateToProps)(TableRetorno);
