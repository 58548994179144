import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProdutosService from '../../../../services/ProdutosService';
import { connect, useDispatch } from 'react-redux';
import OrcamentoService from '../../../../services/OrcamentoService';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';

function FormCalcularPintura({ idOrcamento, IdOrcamentoEstrutura, largura, avanco, orcamentoId }) {
  const [pinturaSelecionada, setPinturaSelecionada] = useState('');
  const [pintura, setPintura] = useState([]);
  const [valor, setValor] = useState('');
 
  const dispatch = useDispatch();

  const getPintura = async () => {
    const res = await ProdutosService.buscarPintura();
    if (res) {
      setPintura(res.pintura);
    }
  };

  useEffect(() => {
    getPintura();
  }, []);

  const handleCalcularPintura = () => {
   
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      idPintura: pinturaSelecionada,
      avanco: avanco,
      largura: largura
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.calcularPintura(dadosParaEnviar).then((res) => {
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const handleEdit = () => {
    const dadosParaEnviar = {
      idOrcamento: orcamentoId,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      idPintura: pinturaSelecionada,
      avanco: avanco,
      largura: largura
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.editEtapaOito(orcamentoId, dadosParaEnviar).then((res) => {
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Pintura editada com sucesso' }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaOito(id);
    if (response) {
      const orcamento = response;
      orcamento.produtos.forEach(produto => {
        setPinturaSelecionada(produto.produto_id);
      });
      setValor(orcamento.valor_pintura);
      dispatch(changeloading({ open: false }));
    }else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = (event) => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleCalcularPintura();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5' marginLeft={2} marginTop={2}>Calcular Pintura</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Typography>Selecione o tipo de tinta:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id='pintura'
            size='small'
            fullWidth
            value={pinturaSelecionada}
            onChange={(e) => setPinturaSelecionada(e.target.value)}
          >
            {pintura.map((pintura) => (
              <MenuItem key={pintura.id} value={pintura.id}>
                {pintura.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={3} md={3} lg={2}>
          <TextField
            label='Valor'
            variant='outlined'
            fullWidth
            size='small'
            value={valor}
            onChange={(e) => setValor(e.target.value)}
            onClick={handleClick}
            sx={{ textAlign: 'right' }}
          
          />
        </Grid>
      </Grid>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
    largura: state.orcamentoReducer.largura,
    avanco: state.orcamentoReducer.avanco
  };
}

export default connect(mapStateToProps)(FormCalcularPintura);
