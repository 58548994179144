import { Box, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { Confirm, SelectMesAno, TableComponet } from "../../components";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import CaixaService from "../../services/CaixaService";
import { changeNotify } from "../../store/actions/notify.actions";

const headers = [
  {
    id: "id",
    label: "Id",
    props: {
      align: "left",
    },
  },
  {
    id: "nome",
    label: "Venda",
    props: {
      align: "right",
    },
  },

  {
    id: "tipo_pagamento",
    label: "Pagamento",
    props: {
      align: "right",
    },
  },
  {
    id: "categoria_financeira",
    label: "Categoria",
    props: {
      align: "right",
    },
  },
  {
    id: "descricao",
    label: "Descrição",
    props: {
      align: "right",
    },
  },
  {
    id: "valor_parcela",
    label: "Valor",
    props: {
      align: "right",
    },
  },
  {
    id: "data",
    label: "Data",
    props: {
      align: "right",
    },
  },
  {
    id: "actionRows",
    label: "Ações",
    props: {
      align: "right",
    },
  },
];

function TableSaidaAtrasada() {
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [anoSelecionado, setAnoSelecionado] = useState("");
  const [mounted, setMounted] = useState(true);
  const [dataMes, setDataMes] = useState([]);
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const getLastUpdateTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return `${hours}:${minutes} - ${day}/${month}/${year}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMesSelecionado(currentMonth);
    setAnoSelecionado(currentYear);
  }, []);

  async function getTransacaoMes() {
    setLoading(true);
    const data = {
      ano: anoSelecionado,
      mes: mesSelecionado,
      tipo: "saida",
      status: "atrasado",
    };
    const res = await CaixaService.getTransacaoMes(data);

    if (res) {
      setDataMes(res);
      setLoading(false);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getTransacaoMes();
        dispatch(changeloading({ open: false }));
      }
    };

    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMesSelecionado(currentMonth);
    setAnoSelecionado(currentYear);
  }, []);

  useEffect(() => {
    getTransacaoMes();
  }, [mesSelecionado, anoSelecionado]);

  //const do confirm
  const handleOpenConfirmDialog = (id) => {
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });
  const handleConfirmado = () => {
    const idCOnfirmado = confirmar.id.id;
    const data = {
      idConfirmado: idCOnfirmado,
    };
    CaixaService.mudarStatus(data)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: res.message,
          })
        );
        getTransacaoMes();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.message,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  return (
    <Box>
      <Box
        component={Paper}
        elevation={5}
        sx={{ flexGrow: 1 }}
        marginTop={1}
        padding={1}
      >
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={7} sm={6} md={6}>
            <Typography variant="h1" fontSize="35px!important">
              Saida em Atraso
            </Typography>
          </Grid>
          <SelectMesAno
            mesSelecionado={mesSelecionado}
            setMesSelecionado={setMesSelecionado}
            anoSelecionado={anoSelecionado}
            setAnoSelecionado={setAnoSelecionado}
          />
        </Grid>
      </Box>
      <Box marginTop={4}>
        <TableComponet
          headers={headers}
          data={dataMes}
          labelCaption={"Nenhuma registro encontrada!!"}
          loading={loading}
          labelTable={"Caixa"}
          handlerEstornarAction={(event) => {
            handleOpenConfirmDialog(event);
          }}
          handlerDeletarAction={""}
        />
        <Confirm
          open={confirmar.confirmDialogOpen}
          title="Essa saida ja esta paga ?"
          onClose={handleCloseConfirmDialog}
          onConfirm={handleConfirmado}
        />
      </Box>
    </Box>
  );
}

export default TableSaidaAtrasada;
