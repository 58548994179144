import { Box } from '@mui/material';
import React from 'react';



function LengendaCores() {
  return (
  <Box display='flex'>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#424242' }}></div>
      <span>agendado instalacao</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#ff9800' }}></div>
      <span>agendado retorno</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#64b5f6' }}></div>
      <span>Início Preparação</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#ffd600' }}></div>
      <span>Finalizado Preparação</span>
    </div>
    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#01579b' }}></div>
      <span>Início Instalação</span>
    </div>

    <div style={{ display: 'flex', alignItems: 'center', marginLeft: '10px' }}>
      <div style={{ width: '15px', height: '15px', marginRight: '5px', backgroundColor: '#1b5e20' }}></div>
      <span>Finalizado Instalação</span>
    </div>
  </Box>
  )
}

export default LengendaCores;