import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProdutosService from '../../../../services/ProdutosService';
import { connect, useDispatch } from 'react-redux';
import OrcamentoService from '../../../../services/OrcamentoService';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';

function FormCalcularVigaW({ idOrcamento, IdOrcamentoEstrutura, orcamentoId }) {
  const [vigaWSelecionada, setVigaWSelecionada] = useState('');
  const [vigaW, setVigaW] = useState([]);
  const [vigaWSelecionadaDois, setVigaWSelecionadaDois] = useState('');
  const [vigaWDois, setVigaWDois] = useState([]);
  const [quantidadeLargura, setQuantidadeLargura] = useState('');
  const [quantidadeAvanco, setQuantidadeAvanco] = useState('');
  const [valor, setValor] = useState('');

  const dispatch = useDispatch();

  const getVigaW = async () => {
    const res = await ProdutosService.buscarVigas();
    if (res) {
      setVigaW(res.vigas);
      setVigaWDois(res.vigas);
    }
  };

  useEffect(() => {
    getVigaW();
  }, []);

  const handleCalcularViga = () => {  
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      produtoLargura: vigaWSelecionada,
      produtoAvanco: vigaWSelecionadaDois,
      quantidadeLargura: quantidadeLargura,
      quantidadeAvanco: quantidadeAvanco
    }; 
    dispatch(changeloading({ open: true }));
   
    OrcamentoService.calcularVigas(dadosParaEnviar).then((res) => {    
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const handleEdit = () => {
    const dadosParaEnviar = {
      idOrcamento: orcamentoId,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      produtoLargura: vigaWSelecionada,
      produtoAvanco: vigaWSelecionadaDois,
      quantidadeLargura: quantidadeLargura,
      quantidadeAvanco: quantidadeAvanco
    };
   
    dispatch(changeloading({ open: true }));
    OrcamentoService.editEtapaSeis(orcamentoId, dadosParaEnviar).then((res) => {
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Viga editada com sucesso' }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaSeis(id);
    if (response) {
      const orcamento = response;
      orcamento.produtos.forEach(produto => {
        if (produto.tipo_produto === 'viga_largura') {
          setVigaWSelecionada(produto.produto_id);
          setQuantidadeLargura(produto.quantidade);
        } else if (produto.tipo_produto === 'viga_avanco') {
          setVigaWSelecionadaDois(produto.produto_id);
          setQuantidadeAvanco(produto.quantidade);
        }
      });
      setValor(orcamento.valor_vigas);
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = (event) => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleCalcularViga();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5' marginLeft={2} marginTop={2}>Calcular Vigas</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <Typography>Viga para Largura:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id='vigaW'
            size='small'
            fullWidth
            value={vigaWSelecionada}
            onChange={(e) => setVigaWSelecionada(e.target.value)}
          >
            {vigaW.map((vigaW) => (
              <MenuItem key={vigaW.id} value={vigaW.id}>
                {vigaW.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={2}>
          <TextField
            type='number'
            fullWidth
            size='small'
            label='Quantidade'
            value={quantidadeLargura}
            onChange={(e) => setQuantidadeLargura(e.target.value)}
          />
        </Grid>

        {/* divisa */}

        <Grid container spacing={2} marginTop={2} marginLeft='2px'>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography>Viga para Avanço:</Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={3}>
            <Select
              id='vigaW'
              size='small'
              fullWidth
              value={vigaWSelecionadaDois}
              onChange={(e) => setVigaWSelecionadaDois(e.target.value)}
            >
              {vigaWDois.map((vigaWDois) => (
                <MenuItem key={vigaWDois.id} value={vigaWDois.id}>
                  {vigaWDois.nome}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={2}>
            <TextField
              type='number'
              fullWidth
              size='small'
              label='Quantidade'
              value={quantidadeAvanco}
              onChange={(e) => setQuantidadeAvanco(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} sm={3} md={3} lg={2}>
            <TextField
              label='Valor'
              variant='outlined'
              fullWidth
              size='small'
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              onClick={handleClick}
              sx={{ textAlign: 'right' }}
              
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura, 
  };
}

export default connect(mapStateToProps)(FormCalcularVigaW);
