import { Http } from "../api";

class EstoqueService {
  static async create(obj) {
    let res = null;
    await Http.post("/estoque/entrada", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async get(obj) {
    let res = null;
    await Http.get("/estoque/entrada", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  
  static async createSaida(obj) {
    let res = null;
    await Http.post("/estoque/saida", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async getSaida(obj) {
    let res = null;
    await Http.get("/estoque/saida", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  
}
export default EstoqueService;