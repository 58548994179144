export const MaskLargura = (value) => {
  // Certifique-se de que value é uma string
  const stringValue = String(value);

  // Remover caracteres não numéricos, exceto ponto decimal
  const onlyNumbersAndDot = stringValue.replace(/[^\d.]/g, '');

  // Adicionar a unidade de medida (mm ou m) no final
  const valorFinal = `${onlyNumbersAndDot} mm`;

  return valorFinal.trim(); // Remover espaços adicionais, se houver
};
