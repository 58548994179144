import React, { useEffect, useState } from "react";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from "recharts";

import { Box, Paper, Typography } from "@mui/material";
import CaixaService from "../../services/CaixaService";

const SimpleLineChart = () => {
  const [totalMes, setTotalMes] = useState([]);

  const mesesNomes = {
    1: "Jan",
    2: "Fev",
    3: "Mar",
    4: "Abr",
    5: "Mai",
    6: "Jun",
    7: "Jul",
    8: "Ago",
    9: "Set",
    10: "Out",
    11: "Nov",
    12: "Dez",
  };

  async function getTotalAno() {
    const res = await CaixaService.getTotalAno();
    if (res && res.dados_por_mes) {
      const mesesDoAno = Array.from({ length: 12 }, (_, index) => index + 1);
      const dadosCompletos = mesesDoAno.map((mes) => ({
        mes: mesesNomes[mes],
        valor_liquido: res.dados_por_mes[mes].valor_liquido || 0,
        total_entrada: res.dados_por_mes[mes].total_entrada || 0,
        total_saida: res.dados_por_mes[mes].total_saida || 0,
      }));
      setTotalMes(dadosCompletos);
    }
  }

  useEffect(() => {
    getTotalAno();
  }, []);

  return (
    <Box
      component={Paper}
      padding={2}
      elevation={5}
      bgcolor="#9e9e9e"
      width="100%"
      marginLeft={2}
    >
      <Typography variant="h3" color="#cecece" marginLeft={25}>
        Grafico movimentação anual
      </Typography>
      <LineChart
        width={650}
        height={300}
        data={totalMes}
        margin={{ top: 25, right: 30, left: 35, bottom: 10 }}
      >
        <CartesianGrid
          vertical={false}
          strokeDasharray="5 5"
          stroke="#cecece"
        />
        <XAxis dataKey="mes" stroke="#cecece" />
        <YAxis />
        <Tooltip formatter={(value) => Number(value)} />
        <Legend stroke="#cecece" />
        <Line
          type="monotone"
          dataKey="valor_liquido"
          stroke="blue"
          strokeWidth={3}
          dot={{ r: 5 }}
          activeDot={{ r: 8 }}
          name="Valor Líquido"
        />
        <Line
          type="monotone"
          dataKey="total_entrada"
          stroke="green"
          strokeWidth={3}
          dot={{ r: 5 }}
          activeDot={{ r: 8 }}
          name="Total de Entrada"
        />
        <Line
          type="monotone"
          dataKey="total_saida"
          stroke="red"
          strokeWidth={3}
          dot={{ r: 5 }}
          activeDot={{ r: 8 }}
          name="Total de Saída"
        />
      </LineChart>
    </Box>
  );
};

export default SimpleLineChart;
