import { Box, CardActions, CardContent, Grid, Paper, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button } from '@mui/material';
import React, { useEffect, useState } from 'react';
import AgendaService from '../../services/AgendaService';
import LengendaCores from '../Agendas/LengendaCores';
import { changeNotify } from '../../store/actions/notify.actions';
import { useDispatch } from 'react-redux';

function ServiceDay() {
  const dispatch = useDispatch();
  const currentDate = new Date();
  const currentHour = currentDate.getHours();
  let greeting;
  if (currentHour >= 5 && currentHour < 12) {
    greeting = 'Bom dia';
  } else if (currentHour >= 12 && currentHour < 18) {
    greeting = 'Boa tarde';
  } else {
    greeting = 'Boa noite';
  }
  const formattedDate = currentDate.toLocaleDateString('pt-BR');

  const [agendas, setAgendas] = useState([]);
  const [openDialog, setOpenDialog] = useState(false);
  const [selectedAgenda, setSelectedAgenda] = useState(null);

  const getAgendasDia = async () => {
    try {
      const res = await AgendaService.getAgendasDia();
      if (res && res.agendas) {
        setAgendas(res.agendas);
      }
    } catch (error) {
      console.error("Erro ao obter agendas:", error);
    }
  };

  useEffect(() => {
    getAgendasDia();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      getAgendasDia(); // Faça uma solicitação para obter as agendas mais recentes
    }, 120000); // Defina o intervalo de tempo em milissegundos (por exemplo, a cada 1 minuto)

    return () => clearInterval(intervalId); // Limpa o intervalo quando o componente é desmontado
  }, []);

  const getStatusColor = (statusAgenda) => {
    let color;
    switch (statusAgenda) {
      case 'agendado instalacao':
        color = '#424242'; // Laranja claro
        break;
      case 'agendado retorno':
        color = '#ff9800';
        break;
      case 'inicio preparacao':
        color = '#64b5f6';
        break;
      case 'finalizado preparacao':
        color = '#ffd600'; // Amarelo escuro
        break;
      case 'inicio instalacao':
        color = '#01579b';
        break;
      case 'finalizado instalacao':
        color = '#1b5e20'; // Verde escuro
        break;
      default:
        color = '#000000';
    }
    return color;
  };

  const handleAgendaClick = (agenda) => {
    setSelectedAgenda(agenda);
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleConfirm = async () => {
   const data = {
    orcamento_id:selectedAgenda.idOrcamento,
    statusAgenda:selectedAgenda.statusAgenda,
    }
    try {
      const res = await AgendaService.atualizarTarefa(data);
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Agenda finaliza com sucesso' }));
      getAgendasDia();
    } catch (error) {
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
    setOpenDialog(false);
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant='h1'>{greeting}</Typography>
          <Box marginTop={2}>
            <Typography variant='h3'>Data: {formattedDate} </Typography>
            <Typography variant='h3'>Confira as atividades de hoje!! </Typography>
          </Box>

        </Grid>

        {agendas.map((agenda, index) => (
          <Grid key={index} item xs={12} sm={6} md={4} lg={3} sx={{ cursor: 'pointer' }} >
            <Box
              component={Paper}
              elevation={4}
              padding={2}
              bgcolor={getStatusColor(agenda.statusAgenda)}
              onClick={() => handleAgendaClick(agenda)}
            >
              <Typography sx={{ fontSize: '20px!important' }}><b>Titulo:</b> {agenda.titulo}</Typography>
              <Typography sx={{ fontSize: '20px!important' }}><b>Status :</b> {agenda.statusAgenda}</Typography>
              <Typography sx={{ fontSize: '20px!important' }}><b>Nome do cliente:</b> {agenda.nomeCliente}</Typography>
              <Typography sx={{ fontSize: '20px!important' }}><b>Hora inicio:</b>   {agenda.horaInicio} </Typography>
              <Typography sx={{ fontSize: '20px!important' }}><b>Hora fim: </b>  {agenda.horaFim}</Typography>
              <Typography sx={{ fontSize: '20px!important' }}><b>Tecnicos escalados:</b> </Typography>
              {agenda.nomeTecnico.map((tecnico, idx) => (
                <Typography sx={{ fontSize: '20px!important' }} key={idx}>{tecnico}</Typography>
              ))}
            </Box>
          </Grid>
        ))}
      </Grid>
      <Dialog open={openDialog} onClose={handleCloseDialog}>        
        <DialogContent>
          <Typography variant="body1">
            Tem certeza que deseja concluir esta etapa?
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button  onClick={handleCloseDialog} color="error">
            Não
          </Button>
          <Button onClick={() => handleConfirm()} color="success">
            Sim
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ServiceDay;

