import React, { useEffect, useState } from "react";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  InputAdornment,
  TextField,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useNavigate } from "react-router-dom";
import { Confirm, TableComponet } from "../../../../components";
import { useDebounce } from "../../../../hooks/UseDebounce";
import OrcamentoService from "../../../../services/OrcamentoService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";

const headers = [
  {
    id: "id",
    label: "Id",
    props: {
      align: "right",
    },
  },
  {
    id: "nomeCliente",
    label: "Nome Cliente",
    props: {
      align: "left",
    },
  },
  {
    id: "vendedor",
    label: "Vendedor",
    props: {
      align: "left",
    },
  },

  {
    id: "data",
    label: "Data",
    props: {
      align: "right",
    },
  },
  {
    id: "valorTotal",
    label: "Valor Total",
    props: {
      align: "right",
    },
  },
  {
    id: "status",
    label: "Status",
    props: {
      align: "right",
    },
  },
  {
    id: "actionRows",
    label: "Ações",
    props: {
      align: "right",
    },
  },
];

function TableOrcamento() {
  const dispatch = useDispatch();
  //const da tabela
  const [orcamento, SetOrcamento] = useState([]);
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);
  //const auto complete
  const [optionsOrcamento, setOptionsOrcamento] = useState([]);
  const [busca, setBusca] = useState("");

  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const { register } = useForm({ resolver: yupResolver() });
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      OrcamentoService.getOrcamento(busca).then((res) => {
        setIsLoading(false);
        SetOrcamento(res.orcamento || []);
        setOptionsOrcamento(
          res.orcamento.map((orcamento) => ({
            id: orcamento.id,
            label: orcamento.nomeCliente,
          }))
        );
      });
    });
  }, [busca, tableUpdateTrigger]);

  const deleteOrcamento = () => {
    OrcamentoService.DeletarPreOrcamento()
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "error", msg: error.message })
        );
      });
  };

  //const do confirm
  const handleOpenConfirmDialog = (id) => {
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });
  const handleDelete = () => {
    const idToDelete = confirmar.id.id;

    OrcamentoService.Delete(idToDelete)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        setTableUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  return (
    <Grid container spacing={2}>
      <Grid item xs={12} sm={6} md={4} lg={5}>
        <Autocomplete
          size="small"
          openText="Abrir"
          closeText="Fechar"
          noOptionsText="Sem opções"
          loadingText="Carregando..."
          disablePortal
          options={optionsOrcamento}
          onInputChange={(_, newValue) => setBusca(newValue)}
          renderInput={(params) => (
            <TextField
              {...params}
              {...register("users_id")}
              label="Pesquisar..."
              variant="outlined"
              fullWidth
              size="small"
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading ? (
                      <CircularProgress size={20} color="inherit" />
                    ) : (
                      <IconButton>
                        <SearchIcon />
                      </IconButton>
                    )}
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          onClick={() => navigate(-1)}
        >
          voltar
        </Button>
      </Grid>
      <Grid item xs={12} sm={6} md={4} lg={4}>
        <Button
          fullWidth
          size="small"
          variant="contained"
          color="error"
          onClick={() => deleteOrcamento()}
        >
          apagar pre orcamento vencido
        </Button>
      </Grid>
      <Grid item xs={12}>
        <TableComponet
          headers={headers}
          data={orcamento}
          labelCaption={"Nenhum Produto encontrado!!"}
          labelTable={"orcamentos"}
          handlerEditarAction={""}
          handlerDeletarAction={(id) => {
            handleOpenConfirmDialog(id);
          }}
          request
          qdtPage={totalPage}
          loading={loading}
          setData={SetOrcamento}
          tableUpdateTrigger={tableUpdateTrigger}
          handlerRequest={async (page, size) => {
            setLoading(true);
            OrcamentoService.getOrcamento("", page, size).then((data) => {
              setLoading(false);
              SetOrcamento(data.orcamento || []);
              setTotalPages(data.totalPages || 0);
              return data;
            });
            return [];
          }}
        />
        <Confirm
          open={confirmar.confirmDialogOpen}
          title="Deseja realmente excluir esse fornecedor?"
          onClose={handleCloseConfirmDialog}
          onConfirm={handleDelete}
        />
      </Grid>
    </Grid>
  );
}

export default TableOrcamento;
