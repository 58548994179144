import React, { useEffect, useMemo, useState } from "react";
import { useDispatch } from "react-redux";
import {
  Box,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  IconButton,
  Collapse,
  Typography,
  Modal,
  TextField,
  Button,
  Autocomplete,
  CircularProgress,
  Select,
  MenuItem,
  TablePagination,
} from "@mui/material";
import {
  KeyboardArrowUp as KeyboardArrowUpIcon,
  KeyboardArrowDown as KeyboardArrowDownIcon,
  Edit,
  PublishedWithChanges,
} from "@mui/icons-material";

import CompraService from "../../services/CompraService";

import { changeloading } from "../../store/actions/loading.action";

function TabelaCompraFinalizada() {
  const [compras, setCompras] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [editModalOpen, setEditModalOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [currentPage, setCurrentPage] = useState(0); // currentPage começa em 0
  const [searchTerm, setSearchTerm] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [totalCount, setTotalCount] = useState(0);
  const dispatch = useDispatch();

  const ITEMS_PER_PAGE = 10;

  async function fetchCompras() {
    dispatch(changeloading({ open: true, msg: "Carregando" }));
    const res = await CompraService.getCompraFinalizada(
      currentPage + 1,
      ITEMS_PER_PAGE,
      searchTerm
    ); // currentPage + 1 para ajustar para 1-based index
    if (res) {
      setCompras(res.compras);
      setTotalCount(res.totalCount);
    }
    dispatch(changeloading({ open: false }));
  }

  useEffect(() => {
    fetchCompras();
    return () => {
      setMounted(false);
    };
  }, [currentPage, searchTerm]);

  const handleOpenEditModal = (produto) => {
    setSelectedProduct(produto);
    setEditModalOpen(true);
  };

  const handleCloseEditModal = () => {
    setSelectedProduct(null);
    setEditModalOpen(false);
  };

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage);
  };

  const handleSearchTermChange = (event, value) => {
    setSearchTerm(value);
  };

  return (
    <Box>
      <Autocomplete
        freeSolo
        disableClearable
        options={[]}
        onChange={handleSearchTermChange}
        renderInput={(params) => (
          <TextField
            {...params}
            label="Buscar compra"
            variant="outlined"
            placeholder="Faça uma busca com filtro, nome do cliente, numero, data da compra (ano-mes-dia é obrigatório nesse padrão se for passar a data) ... clique enter"
          />
        )}
        sx={{ marginBottom: "10px" }}
      />
      <TableContainer component={Paper}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "grey.200" }}>
              <TableCell />
              <TableCell>id Orçamento</TableCell>
              <TableCell>id Venda</TableCell>
              <TableCell>Cliente</TableCell>
              <TableCell>Status da compra</TableCell>
              <TableCell>Data da compra</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {compras.map((compra, index) => (
              <Row
                key={index}
                compra={compra}
                onOpenEditModal={handleOpenEditModal}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        colSpan={3}
        count={totalCount} // totalCount em vez de rows.length
        rowsPerPage={ITEMS_PER_PAGE}
        page={currentPage}
        onPageChange={handlePageChange}
        labelRowsPerPage="Itens por página"
      />
    </Box>
  );
}

function Row({ compra }) {
  const [open, setOpen] = useState(false);

  return (
    <React.Fragment>
      <TableRow
        sx={{
          "&:hover": {
            backgroundColor: "rgba(0, 0, 0, 0.04)",
          },
          "& > *": {
            borderBottom: "unset",
          },
        }}
      >
        <TableCell>
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.orcamento_id}
        </TableCell>
        <TableCell component="th" scope="row">
          {compra.venda_id}
        </TableCell>
        <TableCell>{compra.nomeCliente || compra.razaoSocial}</TableCell>
        <TableCell>{compra.status}</TableCell>
        <TableCell>{compra.dataCompra}</TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Table size="small" aria-label="produtos">
                <TableHead>
                  <TableRow sx={{ backgroundColor: "grey.200" }}>
                    <TableCell>Id</TableCell>
                    <TableCell align="right">Produto</TableCell>
                    <TableCell align="right">Quantidade</TableCell>
                    <TableCell align="right">Unidade de Medida</TableCell>
                    <TableCell align="right">Status da Compra</TableCell>
                    <TableCell align="right">
                      Data da Compra Individual
                    </TableCell>
                    <TableCell align="right">Fornecedor</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {compra.produtos.map((produto, index) => (
                    <TableRow key={index}>
                      <TableCell>{produto.orcamento_estrutura}</TableCell>
                      <TableCell align="right">{produto.nomeProduto}</TableCell>
                      <TableCell align="right">{produto.quantidade}</TableCell>
                      <TableCell align="right">{produto.uniMedida}</TableCell>
                      <TableCell align="right">
                        {produto.statusCompra}
                      </TableCell>
                      <TableCell align="right">
                        {produto.dataCompraIndividual}
                      </TableCell>
                      <TableCell align="right">
                        {produto.nomeFornecedor}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default TabelaCompraFinalizada;
