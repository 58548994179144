import React, { useEffect, useState } from "react";
import { Box, Grid, MenuItem, Select } from "@mui/material";
import CardBemVindo from "../../components/Cards/CardBemVindo";
import { CardCuston, CardFinanças, SelectMesAno, SimpleLineChart } from "../../components";
import Dolar from "../../assets/dolar.svg";
import Entrada from "../../assets/arrow-up.svg";
import Saida from "../../assets/arrow-down.svg";
import Atencao from "../../assets/attention.svg";
import Return from "../../assets/return.svg";
import CaixaService from "../../services/CaixaService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import OrcamentoService from "../../services/OrcamentoService";
import { changeRetorno } from "../../store/actions/retorno.actions ";

function Dashboard() {
  const storedUser = JSON.parse(localStorage.getItem("user"));
  const userName = storedUser ? storedUser.name : "Usuário";
  const dispatch = useDispatch();

  const [lastUpdateTime, setLastUpdateTime] = useState(null);
  const [mesSelecionado, setMesSelecionado] = useState("");
  const [anoSelecionado, setAnoSelecionado] = useState("");
  const [saldoMes, setSaldoMes] = useState("");
  const [totalEntrada, setTotalEntrada] = useState("");
  const [totalSaida, setTotalsaida] = useState("");
  const [entradaPrevista, setEntradaPrevista] = useState("");
  const [saidaPrevista, setSaidaPrevista] = useState("");
  const [entradaAtrasadas, setEntradaAtrasada] = useState("");
  const [saidaAtrasadas, setSaidaAtrasada] = useState("");
  const [retornodoDia, setRetornodoDia] = useState("");

  const getLastUpdateTime = () => {
    const currentDate = new Date();
    const hours = currentDate.getHours();
    const minutes = currentDate.getMinutes();
    const day = currentDate.getDate();
    const month = currentDate.getMonth() + 1;
    const year = currentDate.getFullYear();
    return `${hours}:${minutes} - ${day}/${month}/${year}`;
  };

  useEffect(() => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();

    setMesSelecionado(currentMonth);
    setAnoSelecionado(currentYear);

    const updateTime = getLastUpdateTime();
    setLastUpdateTime(updateTime);
  }, []);

  const buscarMovimentacaoMes = async () => {
    const data = {
      ano: anoSelecionado,
      mes: mesSelecionado,
    };
    dispatch(changeloading({ open: true }));
    const res = await CaixaService.buscarMovimentacaoMes(data);
    if (res) {
      dispatch(changeloading({ open: false }));
      setSaldoMes(res.saldoMes);
      setTotalEntrada(res.totalEntrada);
      setTotalsaida(res.totalSaida);
      setEntradaPrevista(res.entradaPrevista);
      setSaidaPrevista(res.saidaPrevista);
      setEntradaAtrasada(res.entradaAtrasadas);
      setSaidaAtrasada(res.saidaAtrasadas);
    }
  };

  const retornoDia = async () => {
    const res = await OrcamentoService.buscaQuantidadeRetornoDia();
    if (res) {
      setRetornodoDia(res.quantidade);         
    }
  };


  useEffect(() => {
    buscarMovimentacaoMes();
  }, [mesSelecionado, anoSelecionado]);

  useEffect(() => {
    retornoDia();
  }, []);

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
          <CardBemVindo />
        </Grid>

        <SelectMesAno
          mesSelecionado={mesSelecionado}
          setMesSelecionado={setMesSelecionado}
          anoSelecionado={anoSelecionado}
          setAnoSelecionado={setAnoSelecionado}
        />

        <Grid item xs={12} md={12}></Grid>
        <CardFinanças
          title="Saldo Mês"
          color={saldoMes < 0 ? "#dd2c00" : "#1976d2"}
          icon={Dolar}
          amount={saldoMes}
          footerLabel={`última atualização às ${lastUpdateTime}`}
        />
        <CardFinanças
          title="Entrada Mês"
          color="#4caf50"
          icon={Entrada}
          amount={totalEntrada}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/entrada/mes"}
        />
        <CardFinanças
          title="Saida Mês"
          color="#ff5722"
          icon={Saida}
          amount={totalSaida}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/saida/mes"}
        />
        <CardFinanças
          title="Entrada Prevista"
          color="#4caf50"
          icon={Entrada}
          amount={entradaPrevista}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/entrada/prevista"}
        />
        <CardFinanças
          title="Saida Prevista"
          color="#ff5722"
          icon={Saida}
          amount={saidaPrevista}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/saida/prevista"}
        />
        <CardFinanças
          title="Entrada Atrasada"
          color="#f57c00"
          icon={Atencao}
          amount={entradaAtrasadas}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/entrada/atrasada"}
        />
        <CardFinanças
          title="Saida Atrasada"
          color="#f57c00"
          icon={Atencao}
          amount={saidaAtrasadas}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/table/saida/atrasada"}
        />
        <CardCuston
          title="Retornos de orçamentos para hoje:"
          color="#3f51b5"
          icon={Return}
          amount={retornodoDia}
          footerLabel={`última atualização às ${lastUpdateTime}`}
          to={"/retorno"}         
        />
      </Grid>
      <Grid container spacing={2} marginTop={2}>
        <Grid item xs={11} sm={10} md={10} lg={10} xl={5}>
          <SimpleLineChart />
        </Grid>
      </Grid>
    </Box>
  );
}

export default Dashboard;
