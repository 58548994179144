import { Http } from "../api";

class ProdutosService {
  static async create(obj) {
    let res = null;
    await Http.post("/produtos", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async cadastrarCategoria(obj) {
    let res = null;
    await Http.post("/categoria", obj).then((response) => {
      res = response.data;     
    });
    return res;
  
  }
  static async cadastrarSubCategoria(obj) {
    let res = null;
    await Http.post("/subcategoria", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async cadastrarServico(obj) {
    let res = null;
    await Http.post("/servico", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async get(searchString, page = 1, pageSize = 10) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/produtos", params);
      res = response.data;    
    } catch (error) {
      // Lida com erros, se necessário
     
    }

    return res;
  }
  
  static async buscarServicos(obj) {
    let res = null;
    await Http.get("/servico", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarVigas(obj) {
    let res = null;
    await Http.get("/buscarvigas", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarPintura(obj) {
    let res = null;
    await Http.get("/buscarpintura", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarForros(obj) {
    let res = null;
    await Http.get("/buscarforros", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarModulacao(obj) {
    let res = null;
    await Http.get("/buscarmodulacao", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarModelo(obj) {
    let res = null;
    await Http.get("/modelocobertura", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarCategoria(obj) {
    let res = null;
    await Http.get("/categoria", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarSubCategoria(obj) {
    let res = null;
    await Http.get("/subcategoria", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  
  static async buscarTravessas(obj) {
    let res = null;
    await Http.get("/perfilTravessas", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarkits(obj) {
    let res = null;
    await Http.get("/buscakit", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarLona(obj) {
    let res = null;
    await Http.get("/buscalona", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarCalhas(obj) {
    let res = null;
    await Http.get("/buscarcalhas", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarCobertura(obj) {
    let res = null;
    await Http.get("/buscarcobertura", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async buscarTrilhos(obj) {
    let res = null;
    await Http.get("/buscartrilhos", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async buscarAcessorios(obj) {
    let res = null;
    await Http.get("/buscaracessorios", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  

  static async TrazerValorPerfil(obj) {
    let res = null;
    await Http.post("/trazervalorperfil", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }


  static async Update(id, data) {
    let res = null;

    await Http.put(`produtos/${id}`,data).then((response) => {
      res = response.data;

    });
    return res;
  }

  static async Edit(id, categoria) {
    let res = null;

    const data = {
      id: id,
      categoria: categoria,
    };

    await Http.put(`produtos/${id}`, data).then((response) => {
      res = response.data;
    });

    return res;
  }

  static async Delete(id) {
    let res = null;
    await Http.delete(`produtos/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }
}
export default ProdutosService;