import { Autocomplete, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../hooks/UseDebounce';
import ProdutosService from '../../services/ProdutosService';
import CompraService from '../../services/CompraService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';


function AddProdutoCompra({ compra,  onClose  }) {

  const dispatch = useDispatch();
  const [optionsProdutos, setOptionsProdutos] = useState([]);
  const [busca, setBusca] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const [selectedOption, setSelectedOption] = useState(null);
  const [quantidade, setQuantidade] = useState('');


  const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #002',
    boxShadow: 24,
    p: 4,
  };

  
  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ProdutosService.get(busca)
        .then((res) => {
          setIsLoading(false);
          setOptionsProdutos(res.produtos.map(produtos => ({ id: produtos.produto_id, label: produtos.nome })));
        })
    },);
  }, [busca]);

  const handleClientSelection = (_, newValue) => {
    setSelectedOption(newValue);
    setBusca('');
  };

  const handleSubmit = async () => {
    dispatch(changeloading({ open: true, msg: 'Adiconando produto..' }));
    const data = {
      orcamento_id: compra.orcamento_id,
      produto_id: selectedOption ? selectedOption.id : null,
      quantidade: quantidade
    };
    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await CompraService.addicionarProduto( data);
      dispatch(changeloading({ open: false, }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Produto adicionado com sucesso' }));
      onClose();
      window.location.reload(); 
  
    } catch (error) {
      dispatch(changeloading({ open: false, }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
  };
  

  return (
    <Box sx={style} component={Paper} elevation={3} padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} padding={2}>
          <Typography variant='h3' sx={{fontSize:'20px!important'}}>Selecione o produto e quantidade que queira acrescentar na compra.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Autocomplete
            openText='Abrir'
            closeText='Fechar'
            noOptionsText='Sem opções'
            loadingText='Carregando...'
            disablePortal
            options={optionsProdutos}
            onChange={handleClientSelection}
            onInputChange={(_, newValue) => setBusca(newValue)}
            popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
            value={selectedOption}
            renderInput={(params) => (
              <TextField
                {...params}
                label='Produtos'
                variant='outlined'
                fullWidth
                size="small"
              />
            )}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label='Quantidade'
            variant='outlined'
            fullWidth
            size='small'
            value={quantidade}
            onChange={(e) => setQuantidade(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Button variant='contained' fullWidth onClick={handleSubmit}>Adicionar</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddProdutoCompra;
