import React, { useState } from 'react';
import { Box, Button, Grid, Paper, TextField, Typography } from '@mui/material';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ProdutosService from '../../../services/ProdutosService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import { TableComponet } from '../../../components';

const schema = yup.object({
  categoria: yup.string().required().min(3),
});
const headers = [
  {
    id: "id",
    label: "ID",
    props: {
      align: 'left',
    },

  },
  {
    id: "categoria",
    label: "Categoria",
    props: {
      align: 'right',
    },
  },

];

function Categoria() {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [categoria, setCategoria ] = useState([])
  const [mounted, setMounted] = useState(true);

  async function getCategoria() {
    const res = await ProdutosService.buscarCategoria();
  
    if (res) { setCategoria(res.categoria); } }

  React.useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando", }) );
      if (mounted) {
        await getCategoria();
        dispatch(changeloading({ open: false, }) );
       }
    };

    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  function handleSubmit(data) {
    dispatch(changeloading({ open: true, msg: 'cadastrando..' }))
    ProdutosService.cadastrarCategoria(data).then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  }
  return (
    <Box component={Paper} sx={{ flexGrow: 1 }} marginTop={3} padding={3}>
      <Typography variant='h1' sx={{ fontSize: '25px!important', marginBottom: '15px' }}>Cadastrar uma Categoria </Typography>
      <form onSubmit={onSubmit(handleSubmit)}>
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={8}>
            <TextField
              label='Categoria'
              variant='outlined'
              fullWidth
              size='small'
              {...register("categoria")}
            />
            <Typography variant='subtitle2'>{errors?.categoria?.message}</Typography>
          </Grid>
          <Grid item xs={12} md={4}>
            <Button type='submit' variant='contained'>Cadastrar</Button>
          </Grid>

        </Grid>
      </form>
      <Box marginTop={4}>
        <TableComponet
          headers={headers}
          data={categoria}
          labelCaption={'Nenhuma categoria encontrada!!'}
          labelTable={'Categoria'}
          handlerEditarAction={''}
          handlerDeletarAction={''}
        />
      </Box>
    </Box>
  )
}

export default Categoria;