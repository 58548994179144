import React, { useState } from 'react';
import { Box, Button, Grid, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { MaskCpf, MaskNome, MaskPhone } from '../../../utils/mascaras';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from 'react-redux';
import UsersService from '../../../services/UsersService';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';

const schema = yup.object({
  name: yup.string().required().min(3),
  perfils_id: yup.string().required(),
  telefone: yup.string().required(),
  password: yup.string().required(),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #002',
  boxShadow: 24,
  p: 4,
};


function AddUsruario() {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  //select perfil
  const perfil = ['Admin', 'Comercial', 'Vendedor', 'Almoxarifado', 'Produção', 'Escritorio', 'sub admin','selecione perfil'];
  const [perfilIdSelecionado, setPerfilIdSelecionado] = useState(8)

  function handleSubmit(data) {
   
    dispatch(changeloading({ open: true, msg: 'Enviando..' }))
    UsersService.create(data).then((res) => {     
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
    })
      .catch((error) => {       
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  }
  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={1}  >
      <Grid container spacing={2} justifyContent="center">

        <Grid item xs={7} sm={6} md={4} >
          <Typography variant='h1' fontSize='35px!important'>Usuarios</Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={3}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button variant='contained'
            fullWidth size='small'
            onClick={handleOpen}
            startIcon={<Add />}

          > Usuarios
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form onSubmit={onSubmit(handleSubmit)}>
                <Grid container spacing={2} >
                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label='Nome'
                      variant='outlined'
                      fullWidth
                      {...register("name")}
                      onInput={(e) => {
                        e.target.value = MaskNome(e.target.value);
                        setValue("nome", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.name?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <Select
                      label='Perfil'
                      id='perfil'
                      variant='outlined'
                      fullWidth
                      {...register('perfils_id')}
                      value={perfilIdSelecionado}
                      onChange={(e) => setPerfilIdSelecionado(e.target.value)}
                    >
                      {perfil.map((perfil, index) => (
                        <MenuItem key={perfil} value={index + 1}>
                          {perfil}
                        </MenuItem>
                      ))}
                    </Select>
                    <Typography variant='subtitle2'>{errors?.perfils_id?.message}</Typography>
                  </Grid>


                  <Grid item xs={12} sm={6} md={6} lg={4}>
                    <TextField
                      label='Email'
                      type='email'
                      variant='outlined'
                      fullWidth
                      {...register("email")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2}>
                    <TextField
                      label='Telefone'
                      variant='outlined'
                      fullWidth
                      {...register("telefone")}
                      onInput={(e) => {
                        e.target.value = MaskPhone(e.target.value);
                        setValue("telefone", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.telefone?.message}</Typography>
                  </Grid>


                  <Grid item xs={11} sm={6} md={6} lg={3}>
                    <TextField
                      label='Cpf'
                      variant='outlined'
                      fullWidth
                      {...register("cpf")}
                      onInput={(e) => {
                        e.target.value = MaskCpf(e.target.value);
                        setValue("cpf", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.largura?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3}>
                    <TextField
                      label='Indentidade'
                      variant='outlined'
                      fullWidth
                      {...register("rg")}

                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label='Endereço'
                      variant='outlined'
                      fullWidth
                      {...register("endereco")}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={6}>
                    <TextField
                      label='Senha'
                      type='Password'
                      variant='outlined'
                      fullWidth
                      {...register("password")}
                    />
                  <Typography variant='subtitle2'>{errors?.password?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={3} md={1} lg={4}>
                  </Grid>

                  <Grid item xs={12} sm={5} md={5} lg={3}>
                    <Button type='submit' fullWidth variant='contained'>Enviar</Button>
                  </Grid>

                  <Grid item xs={12} sm={6} md={5} lg={3}>
                  </Grid>

                </Grid>
              </form>
            </Box>
          </Modal>
        </Grid>

      </Grid>
    </Box>
  );
}

export default AddUsruario;