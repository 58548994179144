import React, { useEffect, useState } from 'react';
import { Box, Button, Grid, MenuItem, Select, TextField, Typography, IconButton } from '@mui/material';
import { Delete as DeleteIcon } from '@mui/icons-material';
import ProdutosService from '../../../../services/ProdutosService';
import { connect, useDispatch } from 'react-redux';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';
import OrcamentoService from '../../../../services/OrcamentoService';

function FormCalcularAcessorios({ idOrcamento, IdOrcamentoEstrutura, largura, avanco, modulacao, orcamentoId }) {
  const dispatch = useDispatch();
  const [acessoriosSelecionados, setAcessoriosSelecionados] = useState([{ id: '', produto_id: '', valor: '' }]);
  const [acessorioDisponivel, setAcessorioDisponivel] = useState([]);

  const getAcessorios = async () => {
    const res = await ProdutosService.buscarAcessorios();
    if (res) {
      setAcessorioDisponivel(res.acessorios);
    }
  };

  useEffect(() => {
    getAcessorios();
  }, []);

  const handleAcessorioChange = async (event, index) => {
    const acessorioId = event.target.value;
    const acessoriosAtualizados = [...acessoriosSelecionados];
    acessoriosAtualizados[index].produto_id = acessorioId;
    setAcessoriosSelecionados(acessoriosAtualizados);

    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      largura: largura,
      avanco: avanco,
      modulacao: modulacao,
      acessorio: acessorioId,
    };

    if (acessorioId === '') {
      return;
    }

    dispatch(changeloading({ open: true }));
    OrcamentoService.calcularAcessorios(dadosParaEnviar).then((res) => {
      const acessoriosSelecionadosAtualizados = [...acessoriosAtualizados];
      acessoriosSelecionadosAtualizados[index].valor = res.valorTotal;
      setAcessoriosSelecionados(acessoriosSelecionadosAtualizados);
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const handleAddAccessory = () => {
    setAcessoriosSelecionados([...acessoriosSelecionados, { id: '', produto_id: '', valor: '' }]);
  };

  const handleDeleteAccessory = async (index, id) => {
    dispatch(changeloading({ open: true, msg: 'excluindo ...' }));
    try {
      await OrcamentoService.excluirAcessorio(id); // Função para excluir o acessório no backend
      const acessoriosAtualizados = acessoriosSelecionados.filter((_, i) => i !== index);
      setAcessoriosSelecionados(acessoriosAtualizados);
      dispatch(changeloading({ open: false }));
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();   
    const total = acessoriosSelecionados.reduce((acc, acessorio) => acc + parseFloat(acessorio.valor || 0), 0);
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      valor_cobertura: total
    };
    dispatch(changeloading({ open: true }));
    OrcamentoService.salvarValorTotalAcessorios(dadosParaEnviar).then((res) => {     
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const loadAcessoriosData = async (id) => {
    dispatch(changeloading({ open: true, msg: 'buscando ...' }));
    const response = await OrcamentoService.trazerEtapaCinco(id); // Certifique-se de que a função está correta no backend
    if (response) {
      const orcamento = response;
      const novosAcessorios = orcamento.produtos.map(produto => ({
        id: produto.id,
        produto_id: produto.produto_id,
        nome: produto.produto_nome,
        valor: produto.valor
      }));
      setAcessoriosSelecionados(novosAcessorios);
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados dos acessórios:");
      dispatch(changeloading({ open: false }));
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadAcessoriosData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = (event, index) => {
    if (orcamentoId) {
      handleEdit(event, index);
    } else {
      handleAcessorioChange(event, index);
    }
  };

  const handleEdit = async (event, index) => {
    const acessorioId = event.target.value;
    const acessoriosAtualizados = [...acessoriosSelecionados];
    acessoriosAtualizados[index].produto_id = acessorioId;
    setAcessoriosSelecionados(acessoriosAtualizados);

    const dadosParaEnviar = {
      idOrcamento: orcamentoId,    
      acessorio: acessorioId,
    };
 
    dispatch(changeloading({ open: true }));
    try {
      const res = await OrcamentoService.editEtapaCinco(orcamentoId, dadosParaEnviar);
      const acessoriosSelecionadosAtualizados = [...acessoriosAtualizados];
      acessoriosSelecionadosAtualizados[index].valor = res.valorTotal;
      setAcessoriosSelecionados(acessoriosSelecionadosAtualizados);

      dispatch(changeNotify({ open: true, class: 'success', msg: 'Acessórios incluído com sucesso' }));
      dispatch(changeloading({ open: false }));
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
  };
  

  return (
    <Box>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant='h5' marginLeft={2} marginTop={2}>Calcular Acessorios</Typography>
          </Grid>

          {acessoriosSelecionados.map((acessorio, index) => (
            <React.Fragment key={index}>
              <Grid item xs={12} sm={7} md={4} lg={4}>
                <Select
                  id={`acessorios-${index}`}
                  fullWidth
                  size='small'
                  value={acessorio.produto_id}
                  onChange={(event) => handleClick(event, index)}
                >
                  <MenuItem value=''>Selecione o acessorio ...</MenuItem>
                  {acessorioDisponivel.map((acessorioDisp) => (
                    <MenuItem key={acessorioDisp.id} value={acessorioDisp.id}>
                      {acessorioDisp.nome}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>

              <Grid item xs={12} sm={6} md={2} lg={2}>
                <TextField
                  label='Valor'
                  variant='outlined'
                  fullWidth
                  size='small'
                  value={acessorio.valor}
                  disabled // Impede que o campo seja editável
                />
              </Grid>
              <Grid item xs={12} sm={1} md={1} lg={1}>
                <IconButton onClick={() => handleDeleteAccessory(index, acessorio.id)}>
                  <DeleteIcon />
                </IconButton>
              </Grid>
              <Grid item xs={12} sm={6} md={2} lg={4}></Grid>
            </React.Fragment>
          ))}

          <Grid item xs={12} sm={6} md={2} lg={4}>
            <Button variant="contained" onClick={handleAddAccessory}>Adicionar Acessório</Button>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={4}>
            <Button variant="contained" type="submit">Salvar Valor Total</Button>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
    largura: state.orcamentoReducer.largura,
    avanco: state.orcamentoReducer.avanco,
    modulacao: state.orcamentoReducer.modulacao,
  }
}

export default connect(mapStateToProps)(FormCalcularAcessorios);
