import React, { useEffect, useState } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from "@fullcalendar/timegrid";
import interactionPlugin from "@fullcalendar/interaction";
import { Modal, Button, InputLabel, Select, Grid, MenuItem, Box, TextField, Typography, IconButton } from "@mui/material";
import AgendaService from "../../services/AgendaService";
import { useDispatch } from "react-redux";
import { changeloading } from "../../store/actions/loading.action";
import { changeNotify } from "../../store/actions/notify.actions";
import LengendaCores from "./LengendaCores";
import { Delete } from "@mui/icons-material";
import { format, parseISO } from 'date-fns';

function Calendario() {
  const [openModal, setOpenModal] = useState(false);
  const [selectedDates, setSelectedDates] = useState(null);
  const dispatch = useDispatch();

  const [tecnicos, setTecnicos] = useState([]);
  const [tecnicosSelecionados, setTecnicosSelecionados] = useState([]);
  const [vendaSelecionada, setVendaSelecionada] = useState('');
  const status = ['aguardando agenda', 'agendado instalacao', 'agendado retorno', 'inicio preparacao', 'finalizado preparacao', 'inicio instalacao', 'finalizado instalacao'];
  const [statusSelecionado, setStatusSelecionado] = useState('aguardando agenda');
  const [agendas, setAgendas] = useState([]);
  const [titulo, setTitulo] = useState('');

  const [openModalExibir, setOpenModalExibir] = useState(false);
  const [title, setTitle] = useState('');
  const [start, setStart] = useState('');
  const [end, setEnd] = useState('');
  const [id, setId] = useState('');
  const [instalador, setIntalador] = useState('');

  const handleCloseModalExibir = () => {
    setOpenModalExibir(false);
  };

  useEffect(() => {
    getTecnicos();
    getAgendas();
  }, []);

  const getTecnicos = async () => {
    try {
      const res = await AgendaService.getTecnicos();
      if (res) {
        setTecnicos(res.tecnicos);
      }
    } catch (error) {
      console.error("Erro ao obter técnicos:", error);
    }
  };

  const getAgendas = async () => {
    try {
      const res = await AgendaService.getAgendas();
      if (res) {
        setAgendas(res.agendas);
      }
    } catch (error) {
      console.error("Erro ao obter vendas:", error);
    }
  };

  const handleEventClick = (infoEvento) => {
    const evento = infoEvento.event;
    console.log(evento);  // Verifique se os campos start e end estão corretos
    setTitle(evento.title);
    setStart(evento.start ? evento.start.toISOString() : null);
    setEnd(evento.end ? evento.end.toISOString() : null);
    setId(evento.id);
    setIntalador(evento.extendedProps.nomeTecnico);
    setOpenModalExibir(true);
  };

  const handleDateSelect = (selectInfo) => {
    const start = selectInfo.start;
    const end = selectInfo.end;
    const allDay = selectInfo.allDay;
    const isSameDay = start.toDateString() === end.toDateString();
    const adjustedEnd = isSameDay ? end : new Date(end.getTime() - 1 * 24 * 60 * 60 * 1000);
    const startDate = start.toLocaleString();
    const endDate = adjustedEnd.toLocaleString();
    setSelectedDates({ start: startDate, end: endDate, allDay });
    setOpenModal(true);
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleTecnicoChange = (index, value) => {
    const newTecnicosSelecionados = [...tecnicosSelecionados];
    newTecnicosSelecionados[index] = value;
    setTecnicosSelecionados(newTecnicosSelecionados);
  };

  const handleAddTecnicoField = () => {
    setTecnicosSelecionados([...tecnicosSelecionados, ""]);
  };

  const handleSubmit = async () => {
    const data = {
      orcamento_id: vendaSelecionada,
      titulo: titulo,
      tecnicosSelecionados: tecnicosSelecionados,
      dataInicio: new Date(selectedDates.start).toISOString(),
      dataFim: new Date(selectedDates.end).toISOString(),
      statusAgenda: statusSelecionado
    };

    try {
      await AgendaService.create(data);
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Agenda criada com sucesso' }));
      handleCloseModal();
      getAgendas();
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
    handleCloseModal();
  };

  const handleDelete = (id) => {
    dispatch(changeloading({ open: true, msg: 'Deletando agenda ...' }));
    AgendaService.Delete(id)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));
        setOpenModalExibir(false);
        getAgendas();
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
      });
  };

  const eventos = agendas.map(agendasDia => {
    let color;
    switch (agendasDia.status) {
      case 'agendado instalacao':
        color = '#424242';
        break;
      case 'agendado retorno':
        color = '#ff9800';
        break;
      case 'inicio preparacao':
        color = '#64b5f6';
        break;
      case 'finalizado preparacao':
        color = '#ffd600';
        break;
      case 'inicio instalacao':
        color = '#01579b';
        break;
      case 'finalizado instalacao':
        color = '#1b5e20';
        break;
      default:
        color = '#000000';
    }
    return {
      title: agendasDia.titulo_agenda,
      id: agendasDia.id,
      nomeTecnico: agendasDia.nomeTecnico,
      start: new Date(agendasDia.data_inicio).toISOString(),
      end: new Date(agendasDia.data_fim).toISOString(),
      allDay: false,
      backgroundColor: color, // Define a cor com base no status
      borderColor: color, // Define a cor da borda com base no status
      textColor: '#FFFFFF' // Cor do texto para garantir contraste
    };
  });

  const handleEventDrop = (infoEvento) => {
    const evento = infoEvento.event;
    updateEventDate(evento.id, evento);
  };

  const updateEventDate = async (eventId, newDate) => {
    const data = {
      data_inicio: newDate.start.toISOString(),
      data_fim: newDate.end ? newDate.end.toISOString() : "NULL",
    };

    try {
      await AgendaService.updateEventDate(eventId, data);
      dispatch(changeNotify({ open: true, class: "success", msg: "Data do evento atualizada com sucesso" }));
      getAgendas();
    } catch (error) {
      dispatch(changeNotify({ open: true, class: "error", msg: error.response.data.error }));
    }
  };

  return (
    <Box>
      <LengendaCores />
      <Box marginTop={5}>
        <FullCalendar
          plugins={[dayGridPlugin, timeGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale="pt-br"
          headerToolbar={{
            start: "today prev,next",
            center: "title",
            end: "dayGridMonth,timeGridWeek,timeGridDay",
          }}
          buttonText={{
            today: "Hoje",
            day: "Dia",
            week: "Semana",
            month: "Mês",
          }}
          height={"90vh"}
          eventClick={handleEventClick}
          select={handleDateSelect}
          eventDrop={handleEventDrop}
          selectable={true}
          selectMirror={true}
          events={eventos}
          editable={true}
        />
      </Box>

      <Modal open={openModal} onClose={handleCloseModal}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width: "75%",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <h2>
                Adicionar Evento para{" "}
                {selectedDates && `${selectedDates.start} `} até{" "}
                {selectedDates && `${selectedDates.end} `}
              </h2>
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <TextField
                fullWidth
                label="Titulo da agenda"
                value={titulo}
                onChange={(e) => setTitulo(e.target.value)}
                size="small"
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={2}>
              <TextField
                id="venda"
                label="Id da venda"
                fullWidth
                size="small"
                value={vendaSelecionada}
                onChange={(e) => setVendaSelecionada(e.target.value)}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={4}>
              <Select
                label="Status da Agenda"
                size="small"
                variant="outlined"
                fullWidth
                value={statusSelecionado}
                onChange={(e) => setStatusSelecionado(e.target.value)}
              >
                {status.map((status) => (
                  <MenuItem key={status} value={status}>
                    {status}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            {tecnicosSelecionados.map((tecnicoSelecionado, index) => (
              <Grid item xs={12} sm={6} md={4} lg={4} key={index}>
                <InputLabel id={`tecnico-${index}`} sx={{ fontSize: "15px" }}>
                  Selecionar Técnico
                </InputLabel>
                <Select
                  id={`tecnico-${index}`}
                  fullWidth
                  size="small"
                  value={tecnicoSelecionado}
                  onChange={(e) => handleTecnicoChange(index, e.target.value)}
                >
                  {tecnicos.map((tecnico) => (
                    <MenuItem key={tecnico.id} value={tecnico.id}>
                      {tecnico.name}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            ))}

            <Grid container spacing={2} marginTop={4} marginLeft={1}>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleAddTecnicoField}
                >
                  Adicionar Técnico
                </Button>
              </Grid>
              <Grid item xs={12} sm={6} md={4} lg={4}>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleSubmit}
                >
                  Salvar
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </div>
      </Modal>

      <Modal open={openModalExibir} onClose={handleCloseModalExibir}>
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            backgroundColor: "white",
            padding: "20px",
            borderRadius: "5px",
            width: "50%",
          }}
        >
          <Typography variant="h6">
            <b>Id Agenda:</b> {id}
          </Typography>
          <Typography variant="h6">
            <b>Titulo:</b> {title}
          </Typography>
          <Typography variant="h6">
            <b>Tecnico/ajudante:</b> {instalador}
          </Typography>
          <Typography variant="h6">
            <b>Inicio:</b>{" "}
            {start
              ? format(parseISO(start), "dd/MM/yyyy, HH:mm:ss")
              : "Sem data de início"}
          </Typography>
          <Typography variant="h6">
            <b>Fim:</b>{" "}
            {end
              ? format(parseISO(end), "dd/MM/yyyy, HH:mm:ss")
              : "Sem data de fim"}
          </Typography>
          <IconButton>
            <Delete color="error" onClick={() => handleDelete(id)} />
          </IconButton>
        </div>
      </Modal>
    </Box>
  );
}

export default Calendario;
