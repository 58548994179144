import React, { useEffect, useMemo, useState } from 'react';
import { Autocomplete, Box, Button, CircularProgress, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { MaskValor } from '../../../utils/mascaras';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import { useDebounce } from '../../../hooks/UseDebounce';
import ProdutosService from '../../../services/ProdutosService';
import EstoqueService from '../../../services/EstoqueService';


const schema = yup.object({
  produto_id: yup.string().required(),
  qtd_entrada: yup.string().required(),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '55%',
  transform: 'translate(-50%, -50%)',
  width: '75%',
  bgcolor: 'background.paper',
  border: '2px solid #002',
  boxShadow: 24,
  p: 4,
};


function AddEntrada({ onUpdate }) {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset} = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  //const auto complete
  const [optionsProdutos, setOptionsProdutos] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [busca, setBusca] = useState('');
  const { debounce } = useDebounce();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ProdutosService.get(busca)
        .then((res) => {    
          setIsLoading(false);
          setOptionsProdutos(res.produtos.map(produtos => ({ id: produtos.produto_id, label: produtos.nome, valor: produtos.valor })))
        
        })
    },);
  }, [busca,]);


  

  const handleProdutoSelection = (_, newValue) => { 
    setSelectedId(newValue?.id); 
    setBusca('');
    setValue('produto_id', newValue?.id, { shouldValidate: true }); 
    if (newValue) {
      setValue("valor_entrada", newValue.valor, { shouldValidate: true });
    } else {
      setValue("valor_entrada", '', { shouldValidate: true }); // Limpar o campo se nenhum produto for selecionado
    }
  };
  
  const autoCompleteSelectedOption = useMemo(() => {
    if (!selectedId) return null;
    const selectedOption = optionsProdutos.find(optionsProdutos => optionsProdutos.id === selectedId);
    if (!selectedOption) return null;
    return selectedOption;
  }, [selectedId]);

  function handleSubmit(data) {
    // Calcula o subtotal de entrada
    const subtotal = data.qtd_entrada * data.valor_entrada;

    let newData = { ...data, produto_id: selectedId, subtotal_entrada: subtotal};

    dispatch(changeloading({ open: true, msg: 'Inserindo entrada..' }))
    EstoqueService.create(newData).then((res) => {      
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
      onUpdate();
      reset();
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  }



  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={1}  >
      <Grid container spacing={2} justifyContent="center">

        <Grid item xs={7} sm={6} md={4} >
          <Typography variant='h1' fontSize='35px!important'>Entrada</Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={3}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button variant='contained'
            fullWidth size='small'
            onClick={handleOpen}
            startIcon={<Add />}

          > Entrada
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={style}>
              <form onSubmit={onSubmit(handleSubmit)}>
                <Grid container spacing={2} >
                  <Grid item xs={12}>
                    <Typography variant='h1' sx={{ fontSize: '25px!important' }}>Inserir nova entrada no estoque</Typography>
                  </Grid>
                  <Grid item xs={12} sm={6} md={2} lg={4}  marginTop='8px' >
                    <Autocomplete
                      openText='Abrir'
                      closeText='Fechar'
                      noOptionsText='Sem opções'
                      loadingText='Carregando...'
                      disablePortal
                      loading={isLoading}
                      options={optionsProdutos}
                      onChange={handleProdutoSelection}
                      onInputChange={(_, newValue) => setBusca(newValue)}
                      popupIcon={isLoading ? <CircularProgress size={24} /> : undefined}
                      value={autoCompleteSelectedOption}

                      renderInput={(params) => (
                        <TextField
                          {...params}
                          {...register("produto_id")}
                          label='Produtos'
                          variant='outlined'
                          fullWidth
                          size="small"
                        />
                      )}
                    />
                    <Typography variant='subtitle2'>{errors?.produto_id?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={3} marginTop='8px'>
                    <TextField
                      label='Quantidade'
                      type='text'
                      variant='outlined'
                      fullWidth
                      size='small'
                      {...register("qtd_entrada")}
                    />
                    <Typography variant='subtitle2'>{errors?.qtd_entrada?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={6} md={6} lg={2} marginTop='-15px'>
                  <InputLabel htmlFor="valor_entrada"sx={{marginBottom:'-5px'}}>Valor</InputLabel>
                    <TextField                     
                      variant='outlined'
                      fullWidth
                      size='small'
                      {...register("valor_entrada")}
                      onInput={(e) => {
                        e.target.value = MaskValor(e.target.value);
                        setValue("valor_entrada", e.target.value, { shouldValidate: true });
                      }}
                    />
                    <Typography variant='subtitle2'>{errors?.valor_entrada?.message}</Typography>
                  </Grid>

                  <Grid item xs={12} sm={5} md={5} lg={3}>
                    <Button type='submit' fullWidth variant='contained'>Inserir</Button>
                  </Grid>



                </Grid>
              </form>
            </Box>
          </Modal>
        </Grid>

      </Grid>
    </Box>
  );
}

export default AddEntrada;