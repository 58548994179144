import { Http } from "../api";

class OrcamentoService {
  static async create(obj) {
    let res = null;
    await Http.post("/orcamento", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async upDateValor(obj) {
    let res = null;
    await Http.post("/upvalorestrutura", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getOrcamento(searchString, page = 1, pageSize = 5) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/orcamento", params);
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }

  static async salvarValorTotalAcessorios(obj) {
    let res = null;
    await Http.post("/salvarValorTotalAcessorios", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularVigas(obj) {
    let res = null;
    await Http.post("/calcularvigas", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularEletrica(obj) {
    let res = null;
    await Http.post("/calculareletrica", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularComissao(obj) {
    let res = null;
    await Http.post("/calcularcomissao", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async dadosOrcamento(obj) {
    let res = null;
    await Http.post("/dadosorcamento", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async valorTotalUltimaEtapa(obj) {
    let res = null;
    await Http.post("/valortotalultimaetapa", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async calcularMaodeObra(obj) {
    let res = null;
    await Http.post("/calcularmaodeobra", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularPintura(obj) {
    let res = null;
    await Http.post("/calcularpintura", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularForros(obj) {
    let res = null;
    await Http.post("/calcularforros", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calcularTrelica(obj) {
    let res = null;
    await Http.post("/calculartrelica", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calularCobertura(obj) {
    let res = null;
    await Http.post("/calcularcobertura", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async buscarTotalPerfils(obj) {
    let res = null;
    await Http.post("/trazervalorperfil", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async buscarCarcularPe(obj) {
    let res = null;
    await Http.post("/calcularpe", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calularChapa(obj) {
    let res = null;
    await Http.post("/calularchapa", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async calularProdutosAleatorios(obj) {
    let res = null;
    await Http.post("/calularprodutoaleatorio", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async calcularAcessorios(obj) {
    let res = null;
    await Http.post("/calularacessorios", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async buscarCobertura(obj) {
    let res = null;
    await Http.get("/buscarcobertura", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async buscaTotalEstrutura(id) {
    let res = null;
    await Http.get(`/buscavalorestrutura/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async get(searchString, page = 1, pageSize = 5) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/produtos", params);
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }
  static async DeletarPreOrcamento(obj) {
    let res = null;
    await Http.post("/deletarpreorcamento", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async Delete(id) {
    let res = null;
    await Http.delete(`orcamento/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async excluirAcessorio(id) {
    let res = null;
    await Http.delete(`excluiracessorio/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async excluirProduto(id) {
    let res = null;
    await Http.delete(`excluirproduto/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerTipoCobertura (id) {
    let res = null;
    await Http.get(`/trazertiposervico/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaUm(id) {

    let res = null;
    await Http.get(`/trazeretapaumm/${id}`).then((response) => {
      res = response.data;
    });
   
    return res;
  }

  static async editEtapaUm(id, data) {
    let res = null;

    await Http.put(`editEtapaUm/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapadois(id, data) {
    let res = null;

    await Http.get(`trazeretapadois/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async editEtapaDois(id, data) {
    let res = null;

    await Http.put(`editEtapaDois/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async trazerEtapaTres(id, data) {
    let res = null;

    await Http.get(`trazeretapatres/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async editEtapaTres(id, data) {
    let res = null;

    await Http.put(`editEtapaTres/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async trazerEtapaQuatro(id, data) {
    let res = null;

    await Http.get(`trazeretapaquatro/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async editEtapaQuatro(id, data) {
    let res = null;

    await Http.put(`editEtapaquatro/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaCinco(id, data) {
    let res = null;

    await Http.get(`trazeretapacinco/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaCinco(id, data) {
    let res = null;

    await Http.put(`editEtapacinco/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaSeis(id, data) {
    let res = null;

    await Http.get(`trazeretapaseis/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaSeis(id, data) {
    let res = null;

    await Http.put(`editEtapaseis/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaSete(id, data) {
    let res = null;

    await Http.get(`trazeretapasete/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaSete(id, data) {
    let res = null;

    await Http.put(`editEtapasete/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaOito(id, data) {
    let res = null;

    await Http.get(`trazeretapaoito/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaOito(id, data) {
    let res = null;

    await Http.put(`editEtapaoito/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaNove(id, data) {
    let res = null;

    await Http.get(`trazeretapanove/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaNove(id, data) {
    let res = null;

    await Http.put(`editEtapanove/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaDez(id, data) {
    let res = null;

    await Http.get(`trazeretapadez/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaDez(id, data) {
    let res = null;

    await Http.put(`editEtapadez/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaOnze(id, data) {
    let res = null;

    await Http.get(`trazeretapaonze/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaOnze(id, data) {
    let res = null;

    await Http.put(`editEtapaonze/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async trazerEtapaDoze(id, data) {
    let res = null;

    await Http.get(`trazeretapadoze/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async editEtapaDoze(id, data) {
    let res = null;

    await Http.put(`editEtapadoze/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async salvarStatus(obj) {
    let res = null;
    await Http.post("/salvarstatus", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async getStaus(searchString, page = 1, pageSize = 20) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/salvarstatus", params);
      res = response.data;
     
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }

  static async getOrcamentoRetorno(searchString, page = 1, pageSize = 10) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/orcamentoretorno", params);
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }

  static async buscarRetorno(searchString, data) {
    let res = null;
  
    try {
      const response = await Http.get("/buscarretorno", {
        params: { searchString, ...data },
      });
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
      console.error("Erro ao buscar retorno:", error);
    }
    return res;
  }
  
  
  static async buscarRetornoId(id, data) {
    let res = null;

    await Http.get(`buscarretornoId/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }


  static async salvarRetorno(obj) {
    let res = null;
    await Http.post("/salvarretorno", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async salvarRetornoId(id, data) {
    let res = null;

    await Http.put(`salvarretornoId/${id}`, data).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async buscaQuantidadeRetornoDia(){
    let res = null;
    await Http.get('buscarretornodia').then((response)=>{
      res = response.data;
    });
    return res;
  }

  static async deletarRetornoId(id) {
    let res = null;
    await Http.delete(`deletarretornoId/${id}`).then((response) => {
      res = response.data;
    });
    return res;
  }

}
export default OrcamentoService;
