import React, { useEffect, useState } from 'react';
import { Box, Grid, Paper, Typography, } from '@mui/material';
import { TableComponet } from '../../../components';



import EstoqueService from '../../../services/EstoqueService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { MaskDate, MaskValor } from '../../../utils/mascaras';
import AddSaida from './AddSaida';

function Saida({ onUpdate }) {
  const dispatch = useDispatch();
  const [saida, setSaida] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [total, setTotal] = useState('');

  async function getSaida() {
    const res = await EstoqueService.getSaida();
    if (res) {
      setTotal(res.total)
      setSaida(res.saidas.map(entry => ({
        ...entry,
        data_saida: MaskDate(entry.data_saida)
      })));      
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getSaida();
        dispatch(changeloading({ open: false }));
      }
    };
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  const headers = [

    {
      id: 'nome',
      label: 'Nome',
      props: {
        align: 'left',
      },
    },
    {
      id: "data_saida",
      label: "Data",
      props: {
        align: 'right',
      },
    },

    {
      id: 'qtd_saida',
      label: 'Qtd Saida',
      props: {
        align: 'right',
      },
    },
    {
      id: 'valor_saida',
      label: 'Valor',
      props: {
        align: 'right',
      },
    },
    {
      id: 'actionRows',
      label: 'Ações',
      props: {
        align: 'right',
      },
    },
  ];

  //atualizar a tabela como props
  const fetchData = async () => {
    setMounted(true)
    dispatch(changeloading({ open: true, msg: "Carregando" }));
    if (mounted) {
      await getSaida();
      dispatch(changeloading({ open: false }));
    }
  };

  useEffect(() => {
    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted, onUpdate]);


  return (
    <Box >
      <Grid container spacing={2} >

        <Grid item xs={12}>
          <AddSaida onUpdate={() => fetchData()} />
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Box component={Paper} padding={2}>
              <TableComponet
                headers={headers}
                data={saida}
                labelCaption={'Nenhuma saida encontrada!!'}
                labelTable={'Saidas'}
                handlerEditarAction={''}
                handlerDeletarAction={''}
              />
              <Typography variant='h1' sx={{ fontSize: '20px!important', marginTop: '10px' }}>Total de saida no estoque do dia: {MaskValor(total)}</Typography>
            </Box>
          </Box>
        </Grid>



      </Grid>
    </Box>
  )
}

export default Saida;