import React from 'react';
import { TextField } from '@mui/material';
import { PatternFormat } from 'react-number-format';

const CustomTextField = ({ label, mask, allowEmptyFormatting, isAllowed, ...otherProps }) => {
  return (
    <PatternFormat
      mask="_"
      allowEmptyFormatting={allowEmptyFormatting}
      isAllowed={isAllowed}
      {...otherProps}
      customInput={TextField}
      label={label}
    />
  );
};

export default CustomTextField;
