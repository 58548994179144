import { actionTypes } from "../actions/orcamento.actions";

const initialState = {
  idDoOrcamento: '',
  IdOrcamentoEstrutura:'',
  largura: '',
  avanco: '',
  modulacao:'',
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SET_ID_ORCAMENTO:      
      return { 
        ...state,
        idDoOrcamento: action.payload.idDoOrcamento,
        IdOrcamentoEstrutura: action.payload.IdOrcamentoEstrutura,
        largura: action.payload.largura,
        avanco: action.payload.avanco,
        modulacao: action.payload.modulacao
      };
    default:
      return state;
  }
};
