import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProdutosService from '../../../../services/ProdutosService';
import { connect, useDispatch } from 'react-redux';
import OrcamentoService from '../../../../services/OrcamentoService';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';
import OrcamentoToldosService from '../../../../services/OrcamentoToldosService';



function FormCalcMotor({ idOrcamento, IdOrcamentoEstrutura}) {
  const [motorSelecionado, setMotorSelecionado] = useState('');
  const [motor, setMotor] = useState([]);
  const [valor, setValor] = useState('');
  const [valorButtonClicked, setValorButtonClicked] = useState(false);
  const dispatch = useDispatch();



  const getMotores = async () => {
    const res = await OrcamentoToldosService.buscarMotores();
    if (res) {
      setMotor(res.motores);    
    }
  };
  useEffect(() => {
    getMotores();
  }, []);

  function handleCalcularViga() {
    setValorButtonClicked(true)
   const dadosParaEnviar = {  
    IdOrcamentoEstrutura: IdOrcamentoEstrutura,  
    idOrcamento: idOrcamento,
    IdMotor: motorSelecionado
  }; 
  dispatch(changeloading({ open: true })); 
  OrcamentoToldosService.calcularMotor(dadosParaEnviar).then((res) => {    
    setValor(res.valorTotal)
    dispatch(changeloading({ open: false }));
  }).catch((error) => {
    dispatch(changeloading({ open: false }));
    dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
  });

  }
  return (
    <Box >
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5' marginLeft={2} marginTop={2}>Calcular Motor</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={3}>
          <Typography>Selecione o motor:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id='motor'
            size='small'
            fullWidth
            value={motorSelecionado}
            onChange={(e) => setMotorSelecionado(e.target.value)}
          >
            {motor.map((motor) => (
              <MenuItem key={motor.id} value={motor.id}>
                {motor.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={4} sm={3} md={3} lg={2}>
            <TextField
              label='Valor'
              variant='outlined'
              fullWidth
              size='small'
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              onClick={handleCalcularViga}
              sx={{ textAlign: 'right' }}
           //   disabled={valorButtonClicked}              
            />
          </Grid>   
      </Grid>
    </Box>
  )
}
function mapStateToProps(state) {
  return {   
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura,
    
  }
}
export default  connect(mapStateToProps) (FormCalcMotor);