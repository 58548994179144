import React from "react";
import * as S from "./styles";

import { FlipCameraAndroid } from "@mui/icons-material";
import { Tooltip } from "@mui/material";

export default function ExtornoAction({
  onClick,
  styleBox,
  styleIcon,
  ...props
}) {
  return (
    <S.EstornoBox onClick={onClick} style={styleBox} {...props}>
      <Tooltip title="Mudar o status para pago">
        <FlipCameraAndroid style={styleIcon} />
      </Tooltip>
    </S.EstornoBox>
  );
}
