import { ControlPoint } from "@mui/icons-material";
import {
  Autocomplete,
  Box,
  Button,
  CircularProgress,
  Grid,
  IconButton,
  Modal,
  Select,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";

import React, { useCallback, useEffect, useState } from "react";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import ClienteService from "../../../../services/ClienteService";
import { useDebounce } from "../../../../hooks/UseDebounce";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";

const schema = yup.object({
  orcamento_id: yup.string().required(),
  data: yup.string().required(),
  dataProximoRetorno: yup.string().required(),
  tipo_status: yup.string().required(),
});

const modalStyle = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

function AddRetorno({ handleCloseModal, onCadastroConcluido }) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const { debounce } = useDebounce();

  // Estado de carregamento de clientes
  const [loadingClientes, setLoadingClientes] = useState(false);

  // Autocomplete
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [selectedId, setSelectedId] = useState(null);
  const [status, setStatus] = useState("");
  const [options, setOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [selectedOption, setSelectedOption] = useState(null);
  const [openModalButton, setOpenModalButton] = useState(false);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    handleSearchCliente("");
    buscarStatus();
  }, []);

  const handleSearchCliente = (searchValue) => {
    setLoadingClientes(true);
    debounce(() => {
      OrcamentoService.getOrcamentoRetorno(searchValue).then((res) => {
        setOptionsCliente(res);
        setLoadingClientes(false);
      });
    });
  };

  const handleOpenModalButton = () => {
    setOpenModalButton(true);
  };

  const handleCloseModalButton = () => {
    setOpenModalButton(false);
  };

  const onSubmit = (data) => {
    dispatch(changeloading({ open: true, msg: 'Salvando ...' }))
    OrcamentoService.salvarRetorno(data).then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
      onCadastroConcluido();
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  };

  const handleSalvarStatus = () => {
    dispatch(changeloading({ open: true, msg: "Salvando.." }));
    let data = {
      nome: status,
    };
    OrcamentoService.salvarStatus(data)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({ open: true, class: "success", msg: res.message })
        );
        buscarStatus();
        setOpenModalButton(false);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const buscarStatus = useCallback(async (searchValue) => {
    setLoading(true);
    const res = await OrcamentoService.getStaus(searchValue);
    setOptions(res.status || []);
    setLoading(false);
  }, []);

  useEffect(() => {
    debounce(() => {
      buscarStatus(inputValue);
    });
  }, [inputValue, debounce, buscarStatus]);

  useEffect(() => {
    buscarStatus(""); // Busca inicial sem filtro
  }, [buscarStatus]);


  return (
    <Box>
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Autocomplete
              disablePortal
              size="small"
              options={optionsCliente}
              getOptionLabel={(option) => option.nomeCliente}
              value={
                optionsCliente.find((option) => option.id === selectedId) ||
                null
              }
              onChange={(_, newValue) => {
                setSelectedId(newValue ? newValue.id : null);
                setValue("orcamento_id", newValue ? newValue.id : "", {
                  shouldValidate: true,
                });
              }}
              onInputChange={(_, newInputValue) => {
                handleSearchCliente(newInputValue);
              }}
              isOptionEqualToValue={(option, value) => option.id === value.id}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o cliente"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingClientes ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Typography variant="subtitle2">
              {errors?.orcamento_id?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextField
              label="Data"
              type="date"
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              {...register("data")}
            />
            <Typography variant="subtitle2">
              {errors?.data?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={2}>
            <TextField
              label="Proximo Retorno"
              type="date"
              variant="outlined"
              fullWidth
              size="small"
              InputLabelProps={{ shrink: true }}
              {...register("dataProximoRetorno")}
            />
            <Typography variant="subtitle2">
              {errors?.dataProximoRetorno?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <Box display="flex">
              <Autocomplete
                fullWidth
                open={open}
                onOpen={() => setOpen(true)}
                onClose={() => setOpen(false)}
                getOptionLabel={(option) => option.nome}
                options={options}
                loading={loading}
                inputValue={inputValue}
                onInputChange={(event, newInputValue) =>
                  setInputValue(newInputValue)
                }
                value={selectedOption}
                onChange={(event, newValue) => {
                  setSelectedOption(newValue);
                  setValue("tipo_status", newValue ? newValue.id : "");
                }}
                isOptionEqualToValue={(option, value) => option.id === value.id}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Status"
                    size="small"
                    InputProps={{
                      ...params.InputProps,
                      endAdornment: (
                        <React.Fragment>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </React.Fragment>
                      ),
                    }}
                  />
                )}
              />

              <Box marginTop="-4px">
                <Tooltip title="Adicionar status">
                  <IconButton onClick={handleOpenModalButton}>
                    <ControlPoint color="success" />
                  </IconButton>
                </Tooltip>
              </Box>
            </Box>
          </Grid>

          <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
              fullWidth
              label="Observações"
              multiline
              minRows={1}
              maxRows={4}
              {...register("observacao")}
            />
          </Grid>

          <Grid item xs={12} sm={6} md={3} lg={2}>
            <Button fullWidth variant="contained" size="small" type="submit">
              Salvar
            </Button>
          </Grid>
        </Grid>
      </form>

      <Modal
        open={openModalButton}
        onClose={handleCloseModalButton}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={modalStyle}>
          <Box>
            <TextField
              label="Status"
              variant="outlined"
              fullWidth
              size="small"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            />
            <Box marginTop={2}>
              <Button onClick={handleSalvarStatus}>Salvar</Button>
            </Box>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default AddRetorno;
