import React, { useState, useEffect } from 'react';
import { Box, Checkbox, FormControlLabel, Typography, Paper, FormControl, Grid, Button } from '@mui/material';

import { SelectAutoComplete } from '../../../components';

import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import GeralService from '../../../services/GeralService';

const ControleMenu = () => {
    const dispatch = useDispatch();
    const [menus, setMenus] = useState([]);
    const [selectedMenus, setSelectedMenus] = useState([]);
    const [selectedSubmenus, setSelectedSubmenus] = useState([]);
    const [usuarios, setUsuarios] = useState(null); // Ajustado para armazenar um único usuário
    const [errors, setErrors] = useState({});

    useEffect(() => {
        // Carregar os menus do backend
        const fetchMenus = async () => {
            try {
                const response = await GeralService.get('menu');
                setMenus(response);
            } catch (error) {
                console.error('Erro ao buscar menus:', error);
            }
        };

        fetchMenus();
    }, []);

    const handleMenuChange = (menuId) => {
        setSelectedMenus((prev) =>
            prev.includes(menuId) ? prev.filter((id) => id !== menuId) : [...prev, menuId]
        );
    };

    const handleSubmenuChange = (submenuId) => {
        setSelectedSubmenus((prev) =>
            prev.includes(submenuId) ? prev.filter((id) => id !== submenuId) : [...prev, submenuId]
        );
    };

    const handleSubmit = (event) => {
        event.preventDefault();

        // Verifica se o usuário foi selecionado
        if (!usuarios) {
            setErrors({ usuarios: 'Selecione um usuário antes de salvar' });
            return;
        }

        // Limpa erros anteriores
        setErrors({});

        // Cria o payload para enviar ao backend
        const payload = {
            usuarioId: usuarios.id,
            menus: selectedMenus.map(menuId => {
                const menu = menus.find(m => m.id === menuId);
                return {
                    id: menu.id,
                    submenus: menu.sub_menus.filter(submenu => selectedSubmenus.includes(submenu.id))
                };
            })
        };
     
        dispatch(changeloading({ open: true, msg: 'Salvando..' }));
        GeralService.create(payload, 'adicionar/controle/menu').then((res) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'success', msg: res.message }));            
        }).catch((error) => {
            dispatch(changeloading({ open: false }));
            dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
        });
    };

    const renderSubmenus = (submenus) => {
        return (
            <Box sx={{ marginLeft: '20px' }}>
                {submenus.map((submenu) => (
                    <FormControlLabel
                        key={submenu.id}
                        control={
                            <Checkbox
                                checked={selectedSubmenus.includes(submenu.id)}
                                onChange={() => handleSubmenuChange(submenu.id)}
                            />
                        }
                        label={submenu.name}
                    />
                ))}
            </Box>
        );
    };

    return (
        <Box>
            <Box component={Paper} padding={2} marginBottom={2}>
                <Typography variant='h3'>Controle de Menus</Typography>
            </Box>
            <Box component={Paper} padding={3} elevation={4}>
                <form onSubmit={handleSubmit}>
                    <Grid container spacing={2}>
                        {/* Coluna da esquerda - Usuário */}
                        <Grid item xs={4} sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center',}}>
                            <FormControl fullWidth error={!!errors.usuarios}>
                                <SelectAutoComplete
                                    apiUrl="usuarios"
                                    label="Selecione o usuário do sistema"
                                    onChange={(newValue) => setUsuarios(newValue)}
                                />
                                {errors.usuarios && (
                                    <Typography variant='subtitle2' color='error'>{errors.usuarios}</Typography>
                                )}
                            </FormControl>
                        </Grid>

                        {/* Coluna da direita - Menus */}
                        <Grid item xs={7} component={Paper} padding={2} bgcolor='#fafafa' marginLeft={2}>
                            <Box padding={1} component={Paper} marginBottom={2} >
                                <Typography variant='h4' sx={{fontSize:'20px!important'}}> Selecione os menus que deseja atribuir ao colaborador</Typography>
                            </Box>
                            <Box >
                                {menus.map((menu) => (
                                    <Box key={menu.id} >
                                        <FormControlLabel
                                            control={
                                                <Checkbox
                                                    checked={selectedMenus.includes(menu.id)}
                                                    onChange={() => handleMenuChange(menu.id)}
                                                />
                                            }
                                            label={
                                                menu.sub_menus.length > 0
                                                    ? `${menu.name} (${menu.sub_menus.length})`
                                                    : menu.name
                                            }
                                        />
                                        {menu.sub_menus.length > 0 && renderSubmenus(menu.sub_menus)}
                                    </Box>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>

                    <Box display='flex' justifyContent='flex-end' marginTop={2}>
                        <Button type="submit" variant="contained" >
                            Salvar
                        </Button>
                    </Box>
                </form>
            </Box>
        </Box>
    );
};

export default ControleMenu;
