import { Autocomplete, Box, Button, CircularProgress, Grid, Paper, TextField, Typography } from '@mui/material';
import React, { useEffect, useMemo, useState } from 'react';
import { useDebounce } from '../../hooks/UseDebounce';
import ProdutosService from '../../services/ProdutosService';
import CompraService from '../../services/CompraService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../store/actions/loading.action';
import { changeNotify } from '../../store/actions/notify.actions';


function AddDiasTrabalhos({ compra, getCompras, onClose }) {

  const dispatch = useDispatch();
  const [quantidadeDias, setQuantidadeDias] = useState('');
  const [quantidadePessoas, setQuantidadePessoas] = useState('');


  const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: '75%',
    bgcolor: 'background.paper',
    border: '2px solid #002',
    boxShadow: 24,
    p: 4,
  };





  const handleSubmit = async () => {
    dispatch(changeloading({ open: true, msg: 'Enviando..' }));
    const data = {
      orcamento_id: compra.orcamento_id,     
      quantidadeDias: quantidadeDias,
      quantidadePessoas: quantidadePessoas

    };
    try {
      // Adicione aqui a lógica para enviar os dados editados.
      await CompraService.addicionarDias(data);
      dispatch(changeloading({ open: false, }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Enviado com sucesso' }));
      onClose();
      
    } catch (error) {
      dispatch(changeloading({ open: false, }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    }
  };


  return (
    <Box sx={style} component={Paper} elevation={3} padding={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} padding={2}>
          <Typography variant='h3' sx={{fontSize:'20px!important'}}>Selecione a quantidade de dias e de trabalhadores para esse servico.</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label='Quantidade de dias'
            type='number'
            variant='outlined'
            fullWidth
            size='small'
            value={quantidadeDias}
            onChange={(e) => setQuantidadeDias(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <TextField
            label='Quantidade de pessoas'
            type='number'
            variant='outlined'
            fullWidth
            size='small'
            value={quantidadePessoas}
            onChange={(e) => setQuantidadePessoas(e.target.value)}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={4}>
          <Button variant='contained' fullWidth onClick={handleSubmit}>Adicionar</Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default AddDiasTrabalhos;
