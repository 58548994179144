import React, { useEffect,  useState } from "react";
import {
  Autocomplete,
  Box,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";

import * as yup from "yup";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { useDispatch } from "react-redux";
import ProdutosService from "../../../../services/ProdutosService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";
import { MaskLargura, MaskValor } from "../../../../utils/mascaras";
import OrcamentoService from "../../../../services/OrcamentoService";
import { useDebounce } from "../../../../hooks/UseDebounce";
import ClienteService from "../../../../services/ClienteService";
import { changeIdOrcamento } from "../../../../store/actions/orcamento.actions";

const schema = yup.object({
  cliente_id: yup.string().required(),
  largura: yup.string().required(),
  projecao: yup.string().required(),
  modulacao: yup.string().required(),
  perfilTravessas: yup.string().required(),
  perfilPerimetro: yup.string().required(),
});

function FormCalcularEstrurura(props) {
  const { servicoSelecionado, orcamentoId } = props;

  const {
    register,
    handleSubmit,
    formState: { errors },
    setValue,
  } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();

  // Loading state for Autocomplete
  const [loadingClientes, setLoadingClientes] = useState(false);
  //select perfil travessas e perimetros
  const [travessaSelecionada, setTravessaSelecionada] = useState("");
  const [travessas, setTravesas] = useState([]);
  const [trilhoSelecionado, setTrilhoSelecionado] = useState("");
  const [trilho, setTrilho] = useState([]);
  const [perimetro, setPerimetro] = useState([]);
  const [perimetroSelecionado, setPerimetroSelecionado] = useState("");
  const [valorTotal, setValorTotal] = useState(0);

  //const auto complete
  const [optionsCliente, setOptionsCliente] = useState([]);
  const [selectedId, setSelectedId] = useState(null);

  const { debounce } = useDebounce();


  const [orcamento_id, setOrcamento_id] = useState("");

  //select modelo
  const [modelo, setModelo] = useState([]);
  const [modelooSelecionado, setModeloSelecionado] = useState("");

  const handleSearchCliente = (searchValue) => {
    setLoadingClientes(true);
    debounce(() => {
      ClienteService.get(searchValue).then((res) => {
        setOptionsCliente(res.clientes || []);
        setLoadingClientes(false);
      });
    });
  };

  const getTravessas = async () => {
    const res = await ProdutosService.buscarTravessas();
    if (res) {
      setTravesas(res.travessas);
      setPerimetro(res.travessas);
      setTrilho(res.travessas);
    }
  };
  const getTrilhos = async () => {
    const res = await ProdutosService.buscarTrilhos();
    if (res) {
      setTrilho(res.trilhos);
    }
  };

  const getModelo = async () => {
    const res = await ProdutosService.buscarModelo();
    if (res) {
      setModelo(res.modelo);
    }
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: 'buscando ...' }));

    const response = await OrcamentoService.trazerEtapaUm(id);
    if (response) {
      const orcamento = response;

      setValue("cliente_id", orcamento.cliente_id);
      setValue("modeloCobertura_id", orcamento.modeloCobertura_id);
      setValue("largura", orcamento.largura_estrutura);
      setValue("projecao", orcamento.projecao_estrutura);
      setSelectedId(orcamento.cliente_id);
      setModeloSelecionado(orcamento.modeloCobertura_id);
      setValue("valor", orcamento.valor);

      // Atualizar os valores dos selects
      orcamento.produtos.forEach((produto) => {
        switch (produto.tipo_produto) {
          case "perfil_travessa":
            setTravessaSelecionada(produto.produto_id);
            setValue("perfilTravessas", produto.produto_id);
            break;
          case "perfil_perimetro":
            setPerimetroSelecionado(produto.produto_id);
            setValue("perfilPerimetro", produto.produto_id);
            break;
          case "trilho":
            setTrilhoSelecionado(produto.produto_id);
            setValue("trilho", produto.produto_id);
            break;
          default:
            break;
        }
      });
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    getModulacao();
    getTravessas();
    getModelo();
    getTrilhos();
    handleSearchCliente("");
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);


  useEffect(() => {
    if (valorTotal !== 0) {
      const maskedValor = MaskValor(valorTotal);
      setValue("valor", maskedValor);
    }
  }, [valorTotal]);

  //select modulacao
  const [modulacaoSelecionada, setModulacaoSelecionada] = useState("");
  const [produtos, setProdutos] = useState([]);
  const getModulacao = async () => {
    const res = await ProdutosService.buscarModulacao();
    if (res) {
      setProdutos(res.produtos);
    }
  };

  const handleEdit = (data) => {
   
    let newData = {
      ...data,
      servicos_id: servicoSelecionado,
      etapa_id: 1,
      //  id_orcamento:orcamentoId
    };  
    dispatch(changeloading({ open: true, msg: 'editando' }));
    OrcamentoService.editEtapaUm(orcamentoId, newData)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        setValue("valor", res.valorTotal);
        dispatch(
          changeNotify({ open: true, class: "success", msg: 'editado com sucesso'})
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  const handleClick = (data) => {
    if (orcamentoId) {
      handleEdit(data);
    } else {
      handleSubmitForm(data);
    }
  };

  const handleSubmitForm = (data) => {
    let newData = {
      ...data,
      servicos_id: servicoSelecionado,
      etapa_id: 1,
    };

    dispatch(changeloading());
    OrcamentoService.buscarTotalPerfils(newData)
      .then((resposta) => {
        setOrcamento_id(resposta.id_orcamento);
        setValorTotal(resposta.valorTotal);
        dispatch(changeloading({ open: false }));

        dispatch(
          changeIdOrcamento(
            resposta.id_orcamento,
            resposta.id_orcamento_estrutura,
            data.largura,
            data.projecao,
            data.modulacao
          )
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };
  return (
    <Box>
      {/* formulario de busca de valores para travessas */}
      <form onSubmit={handleSubmit(handleClick)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h5" marginLeft={2} marginBottom="-35px">
              calcular estrutura{" "}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={2} lg={4} marginTop="24px">
            <Autocomplete
              disablePortal
              size="small"
              options={optionsCliente}
              getOptionLabel={(option) => option.nome}
              value={
                optionsCliente.find((option) => option.id === selectedId) ||
                null
              }
              onChange={(event, newValue) => {
                setSelectedId(newValue ? newValue.id : null);
                setValue("cliente_id", newValue ? newValue.id : "", {
                  shouldValidate: true,
                });
              }}
              onInputChange={(event, newInputValue) => {
                handleSearchCliente(newInputValue);
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label="Selecione o cliente"
                  variant="outlined"
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {loadingClientes ? (
                          <CircularProgress color="inherit" size={20} />
                        ) : null}
                        {params.InputProps.endAdornment}
                      </>
                    ),
                  }}
                />
              )}
            />
            <Typography variant="subtitle2">
              {errors?.cliente_id?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} md={4}>
            <InputLabel id="modeloCobertura_id" sx={{ fontSize: "15px" }}>
              Seleciono o modelo da cobertura
            </InputLabel>
            <Select
              id="modeloCobertura_id"
              fullWidth
              size="small"
              value={modelooSelecionado}
              {...register("modeloCobertura_id")}
              onChange={(e) => {
                setModeloSelecionado(e.target.value);
              }}
            >
              {modelo.map((modelo) => (
                <MenuItem key={modelo.id} value={modelo.id}>
                  {modelo.modeloCobertura}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.sub_categoria?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <TextField
              label="Largura"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              size="small"
              {...register("largura")}
              onInput={(e) => {
                e.target.value = MaskLargura(e.target.value);
                setValue("largura", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant="subtitle2">
              {errors?.largura?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <TextField
              label="Projeção"
              variant="outlined"
              fullWidth
              InputLabelProps={{ shrink: true }}
              size="small"
              {...register("projecao")}
              onInput={(e) => {
                e.target.value = MaskLargura(e.target.value);
                setValue("projecao", e.target.value, { shouldValidate: true });
              }}
            />
            <Typography variant="subtitle2">
              {errors?.projecao?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              usar modulacao para:
            </InputLabel>
            <Select
              id="modulacao"
              fullWidth
              size="small"
              value={modulacaoSelecionada}
              {...register("modulacao")}
              onChange={(e) => {
                setModulacaoSelecionada(e.target.value);
              }}
            >
              {produtos.map((modulacao) => (
                <MenuItem key={modulacao.id} value={modulacao.modulacao}>
                  {modulacao.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.modulacao?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Perfil Travessas
            </InputLabel>
            <Select
              id="perfilTravessas"
              fullWidth
              size="small"
              value={travessaSelecionada}
              {...register("perfilTravessas")}
              onChange={(e) => {
                setTravessaSelecionada(e.target.value);
              }}
            >
              {travessas.map((travessas) => (
                <MenuItem key={travessas.id} value={travessas.id}>
                  {travessas.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.perfilTravessas?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Perfil Perimetro
            </InputLabel>
            <Select
              id="perfilPerimetro"
              fullWidth
              size="small"
              value={perimetroSelecionado}
              {...register("perfilPerimetro")}
              onChange={(e) => {
                setPerimetroSelecionado(e.target.value);
              }}
            >
              {perimetro.map((perimetro) => (
                <MenuItem key={perimetro.id} value={perimetro.id}>
                  {perimetro.nome}
                </MenuItem>
              ))}
            </Select>
            <Typography variant="subtitle2">
              {errors?.perfilPerimetro?.message}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2}>
            <InputLabel id="category" sx={{ fontSize: "15px" }}>
              Trilho
            </InputLabel>
            <Select
              id="trilho"
              fullWidth
              size="small"
              value={trilhoSelecionado}
              {...register("trilho")}
              onChange={(e) => {
                setTrilhoSelecionado(e.target.value);
              }}
            >
              {trilho.map((trilho) => (
                <MenuItem key={trilho.id} value={trilho.id}>
                  {trilho.nome}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <TextField
              label="Valor"
              variant="outlined"
              fullWidth
              size="small"
              {...register("valor", { value: valorTotal })}
              onClick={handleSubmit(handleClick)}
             // disabled={valorButtonClicked} // Desabilita o campo se o botão de valor já foi clicado
            />
          </Grid>

          <Grid item xs={12} sm={6} md={2} lg={2} marginTop="24px">
            <Typography sx={{ fontSize: "14px" }}>
              numero do orçamento:
              <br />
              {orcamento_id}
            </Typography>
          </Grid>
        </Grid>
      </form>
    </Box>
  );
}

export default FormCalcularEstrurura;
