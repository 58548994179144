import { Http } from "../api";

class VendasService {
  static async getVendas(searchString, page = 1, pageSize = 5) {
    let res = null;
    const params = searchString
      ? { params: { search: searchString, page, pageSize } }
      : { params: { page, pageSize } };

    try {
      const response = await Http.get("/venda", params);
      res = response.data;
    } catch (error) {
      // Lida com erros, se necessário
    }

    return res;
  }

  static async getTipopagamento(obj) {
    let res = null;
    await Http.get("/tipopagamento", obj).then((response) => {
      res = response.data;
    });
    return res;
  }

  static async gerarContrato(obj) {
    let res = null;
    await Http.post("/gerarcontrato", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async buscarValorTotalVenda(obj) {
    let res = null;
    await Http.post("/totalvalorvenda", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
  static async salvarVenda(obj) {
    let res = null;
    await Http.post("/salvarvenda", obj).then((response) => {
      res = response.data;
    });
    return res;
  }
}
export default VendasService;
