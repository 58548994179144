import { actionTypes } from "../actions/retorno.actions ";

const initialState = {
  dataProximoRetorno: '', 
};

export default (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.CHANGE_SET_RETORNO:
      return { 
        ...state,    
        dataProximoRetorno: action.payload.dataProximoRetorno
      };
    default:
      return state;
  }
};
