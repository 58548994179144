import { Box, Button, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { NumericFormat } from "react-number-format";
import { connect, useDispatch } from "react-redux";
import OrcamentoService from "../../../../services/OrcamentoService";
import { changeloading } from "../../../../store/actions/loading.action";
import { changeNotify } from "../../../../store/actions/notify.actions";

function FormTotalPrecoEtapas({ IdOrcamento, orcamentoId }) {
  const [estrutura, setEstrutura] = useState("");
  const [pe, setPe] = useState("");
  const [chapa, setChapa] = useState("");
  const [cobertura, setCobertura] = useState("");
  const [acessorio, setAcessorio] = useState("");
  const [vigas, setVigas] = useState("");
  const [trelicia, setTrelicia] = useState("");
  const [pintura, setPintura] = useState("");
  const [forro, setForro] = useState("");
  const [produtosAleatorio, setProdutoAleatorio] = useState("");
  const [imposto, setImposto] = useState("");
  const [comissao, setComissao] = useState("");
  const [interna, setInterna] = useState("");
  const [eletrica, setEletrica] = useState("");
  const [maoObra, setMaoObra] = useState("");
  const [total, setTotal] = useState(0);
  const dispatch = useDispatch();

  const getBuscaTotalEstrutura = async () => {
    let id = IdOrcamento ? IdOrcamento : orcamentoId;
    if (id) {
      const res = await OrcamentoService.buscaTotalEstrutura(id);
      if (res) {
        console.log(res)
        setEstrutura(res.valor_estrutura);
        setPe(res.valor_pe);
        setChapa(res.valor_chapa);
        setCobertura(res.valor_cobertura);
        setAcessorio(res.valor_acessorios);
        setVigas(res.valor_vigas);
        setTrelicia(res.valor_trelica)
        setPintura(res.valor_pintura);
        setForro(res.valor_forros);
        setProdutoAleatorio(res.valor_produtos_aleatorios);
        setImposto(res.valor_imposto);
        setComissao(res.valor_comissao);
        setInterna(res.valor_comissao_interna);
        setEletrica(res.valor_eletrica);
        setMaoObra(res.valor_mao_de_obra);
      }
    }
  };

  const calculateTotal = useCallback(() => {
    const total =
      parseFloat(estrutura) +
      parseFloat(pe) +
      parseFloat(chapa) +
      parseFloat(cobertura) +
      parseFloat(acessorio) +
      parseFloat(vigas) +
      parseFloat(trelicia) +
      parseFloat(pintura) +
      parseFloat(forro) +
      parseFloat(produtosAleatorio) +
      parseFloat(imposto) +
      parseFloat(comissao) +
      parseFloat(interna) +
      parseFloat(eletrica) +
      parseFloat(maoObra);

    setTotal(total);
  }, [
    estrutura,
    pe,
    chapa,
    cobertura,
    acessorio,
    vigas,
    trelicia,
    pintura,
    forro,
    produtosAleatorio,
    imposto,
    comissao,
    interna,
    eletrica,
    maoObra,
  ]);

  useEffect(() => {
    getBuscaTotalEstrutura();
  }, [IdOrcamento, orcamentoId]);

  useEffect(() => {
    calculateTotal();
  }, [
    estrutura,
    pe,
    chapa,
    cobertura,
    acessorio,
    vigas,
    trelicia,
    pintura,
    forro,
    produtosAleatorio,
    imposto,
    comissao,
    interna,
    eletrica,
    maoObra,
    calculateTotal,
  ]);

  const handleSubmit = async () => {
    let id = IdOrcamento ? IdOrcamento : orcamentoId;
    const data = {
      idEstrutura: id,
      valor_estrutura: estrutura,
      valor_pe: pe,
      valor_chapa: chapa,
      valor_cobertura: cobertura, // Corrigi a chave aqui
      valor_acessorios: acessorio,
      valor_vigas: vigas,
      valor_trelica: trelicia,
      valor_pintura: pintura,
      valor_forros: forro,
      valor_produtos_aleatorios: produtosAleatorio,
      valor_mao_de_obra: maoObra,
      valor_imposto: imposto,
      valor_comissao: comissao,
      valor_comissao_interna: interna,
      valor_eletrica: eletrica,
    };
    try {
      await OrcamentoService.upDateValor(data);
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "success",
          msg: "Alterado com sucesso",
        })
      );
    } catch (error) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: error.response.data.error,
        })
      );
    }
  };

  return (
    <Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Estrutura:</Typography>
        <NumericFormat
          value={estrutura}
          onValueChange={(values) => setEstrutura(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Estrutura"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Pé:</Typography>
        <NumericFormat
          value={pe}
          onValueChange={(values) => setPe(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Pé"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Chapa:</Typography>
        <NumericFormat
          value={chapa}
          onValueChange={(values) => setChapa(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Chapa"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Cobertura:</Typography>
        <NumericFormat
          value={cobertura}
          onValueChange={(values) => setCobertura(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Cobertura"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Acessórios:</Typography>
        <NumericFormat
          value={acessorio}
          onValueChange={(values) => setAcessorio(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Acessórios"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Vigas:</Typography>
        <NumericFormat
          value={vigas}
          onValueChange={(values) => setVigas(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Vigas"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Treliça:</Typography>
        <NumericFormat
          value={trelicia}
          onValueChange={(values) => setTrelicia(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Treliça"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Pintura:</Typography>
        <NumericFormat
          value={pintura}
          onValueChange={(values) => setPintura(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Pintura"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Forros:</Typography>
        <NumericFormat
          value={forro}
          onValueChange={(values) => setForro(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Forros"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Produtos Aleatórios:</Typography>
        <NumericFormat
          value={produtosAleatorio}
          onValueChange={(values) => setProdutoAleatorio(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Produtos Aleatórios"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Mão de Obra:</Typography>
        <NumericFormat
          value={maoObra}
          onValueChange={(values) => setMaoObra(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Mão de Obra"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Imposto:</Typography>
        <NumericFormat
          value={imposto}
          onValueChange={(values) => setImposto(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Imposto"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Comissão:</Typography>
        <NumericFormat
          value={comissao}
          onValueChange={(values) => setComissao(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Comissão"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Comissão Interna:</Typography>
        <NumericFormat
          value={interna}
          onValueChange={(values) => setInterna(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Comissão Interna"
        />
      </Box>
      <Box display="flex" marginTop="7px">
        <Typography>Valor Elétrica:</Typography>
        <NumericFormat
          value={eletrica}
          onValueChange={(values) => setEletrica(values.value)}
          allowLeadingZeros={false}
          allowNegative={false}
          decimalScale={2}
          fixedDecimalScale={true}
          decimalSeparator=","
          allowedDecimalSeparators={["."]}
          prefix="R$ "
          thousandSeparator="."
          customInput={TextField}
          size="small"
          label="Valor Elétrica"
        />
      </Box>
      <Box marginTop="7px">
        <Typography>Soma Total: {(" R$", total)}</Typography>
        <Box marginTop="25px">
          <Button variant="contained" onClick={handleSubmit}>
            {" "}
            salvar
          </Button>
        </Box>
      </Box>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    IdOrcamento: state.orcamentoReducer.idDoOrcamento,
  };
}

export default connect(mapStateToProps)(FormTotalPrecoEtapas);
