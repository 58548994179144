import { styled } from '@mui/material';
import Typography from '@mui/material/Typography';

export const Circle = styled('div')({
  display: 'inline-flex',
  alignItems: 'center',
  justifyContent: 'center',
  width: 18,
  height: 18,
  borderRadius: '50%',
  marginRight: 4,
});

export const Number = styled(Typography)({
  color: '#616161',
  fontWeight: 'bold',
});

export const StyledSpan = styled('span')({
  display: 'flex',
  alignItems: 'center',
  borderRadius: 8, 
  backgroundColor: '#bdbdbd',
  paddingLeft: 8,
});