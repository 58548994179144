import { Http } from "../api";

class OrcamentoToldosService {
  static async calcularArticulado(obj) {
    let res = null;
    await Http.post("/calculararticulado", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }

  static async calcularMaodeObra(obj) {
    let res = null;
    await Http.post("/articuladoobra", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async calcularMotor(obj) {
    let res = null;
    await Http.post("/calcularmotor", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
  static async buscarMotores(obj) {
    let res = null;
    await Http.post("/buscarmotores", obj).then((response) => {
      res = response.data;     
    });
    return res;
  }
}
export default OrcamentoToldosService;