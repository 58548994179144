import { Box, Grid, MenuItem, Select, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react';
import ProdutosService from '../../../../services/ProdutosService';
import { connect, useDispatch } from 'react-redux';
import OrcamentoService from '../../../../services/OrcamentoService';
import { changeloading } from '../../../../store/actions/loading.action';
import { changeNotify } from '../../../../store/actions/notify.actions';

function FormCalcularTrelica({ idOrcamento, IdOrcamentoEstrutura, largura, avanco, orcamentoId }) {
  const [trelicaSelecionada, setTrelicaSelecionada] = useState('');
  const [trelica, setTrelica] = useState([]);
  const [trelicaSelecionadaDois, setTrelicaSelecionadaDois] = useState('');
  const [trelicaDois, setTrelicaDois] = useState([]);
  const [quantidadeLargura, setQuantidadeLargura] = useState('');
  const [quantidadeAvanco, setQuantidadeAvanco] = useState('');
  const [valor, setValor] = useState('');

  const dispatch = useDispatch();

  const getTrelica = async () => {
    const res = await ProdutosService.buscarVigas();
    if (res) {
      setTrelica(res.vigas);
      setTrelicaDois(res.vigas);
    }
  };

  useEffect(() => {
    getTrelica();
  }, []);

  const handleCalcularTrelica = () => {
    const dadosParaEnviar = {
      idOrcamento: idOrcamento,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      produtoLargura: trelicaSelecionada,
      produtoAvanco: trelicaSelecionadaDois,
      quantidadeLargura: quantidadeLargura,
      quantidadeAvanco: quantidadeAvanco,
      avanco: avanco,
      largura: largura
    };
    dispatch(changeloading({ open: true }));

    OrcamentoService.calcularTrelica(dadosParaEnviar).then((res) => {
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const handleEdit = () => {
    const dadosParaEnviar = {
      idOrcamento: orcamentoId,
      IdOrcamentoEstrutura: IdOrcamentoEstrutura,
      produtoLargura: trelicaSelecionada,
      produtoAvanco: trelicaSelecionadaDois,
      quantidadeLargura: quantidadeLargura,
      quantidadeAvanco: quantidadeAvanco,
      avanco: avanco,
      largura: largura
    };

    dispatch(changeloading({ open: true }));
    OrcamentoService.editEtapaSete(orcamentoId, dadosParaEnviar).then((res) => {
      setValor(res.valorTotal);
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'success', msg: 'Treliça editada com sucesso' }));
    }).catch((error) => {
      dispatch(changeloading({ open: false }));
      dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }));
    });
  };

  const loadOrcamentoData = async (id) => {
    dispatch(changeloading({ open: true, msg: "buscando ..." }));
    const response = await OrcamentoService.trazerEtapaSete(id);
    if (response) {
      const orcamento = response;
      orcamento.produtos.forEach(produto => {
        if (produto.tipo_produto === 'trelica_largura') {
          setTrelicaSelecionada(produto.produto_id);
          setQuantidadeLargura(produto.quantidade);
        } else if (produto.tipo_produto === 'trelica_avanco') {
          setTrelicaSelecionadaDois(produto.produto_id);
          setQuantidadeAvanco(produto.quantidade);
        }
      });
      setValor(orcamento.valor_trelica);
      dispatch(changeloading({ open: false }));
    } else {
      alert("Erro ao carregar dados do orçamento:");
    }
  };

  useEffect(() => {
    if (orcamentoId) {
      loadOrcamentoData(orcamentoId);
    }
  }, [orcamentoId]);

  const handleClick = (event) => {
    if (orcamentoId) {
      handleEdit();
    } else {
      handleCalcularTrelica();
    }
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12} marginTop={2}>
          <Typography variant='h5' marginLeft={2} marginTop={2}>Calcular Treliça</Typography>
        </Grid>
        <Grid item xs={4} sm={4} md={4} lg={2}>
          <Typography>Treliça para Largura:</Typography>
        </Grid>
        <Grid item xs={5} sm={5} md={5} lg={3}>
          <Select
            id='trelica'
            size='small'
            fullWidth
            value={trelicaSelecionada}
            onChange={(e) => setTrelicaSelecionada(e.target.value)}
          >
            {trelica.map((trelica) => (
              <MenuItem key={trelica.id} value={trelica.id}>
                {trelica.nome}
              </MenuItem>
            ))}
          </Select>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={2}>
          <TextField
            type='number'
            fullWidth
            size='small'
            label='Quantidade'
            value={quantidadeLargura}
            onChange={(e) => setQuantidadeLargura(e.target.value)}
          />
        </Grid>

        {/* divisa */}

        <Grid container spacing={2} marginTop={2} marginLeft='2px'>
          <Grid item xs={4} sm={4} md={4} lg={2}>
            <Typography>Treliça para Avanço:</Typography>
          </Grid>
          <Grid item xs={5} sm={5} md={5} lg={3}>
            <Select
              id='trelica'
              size='small'
              fullWidth
              value={trelicaSelecionadaDois}
              onChange={(e) => setTrelicaSelecionadaDois(e.target.value)}
            >
              {trelicaDois.map((trelicaDois) => (
                <MenuItem key={trelicaDois.id} value={trelicaDois.id}>
                  {trelicaDois.nome}
                </MenuItem>
              ))}
            </Select>
          </Grid>

          <Grid item xs={3} sm={3} md={3} lg={2}>
            <TextField
              type='number'
              fullWidth
              size='small'
              label='Quantidade'
              value={quantidadeAvanco}
              onChange={(e) => setQuantidadeAvanco(e.target.value)}
            />
          </Grid>
          <Grid item xs={4}></Grid>
          <Grid item xs={4} sm={3} md={3} lg={2}>
            <TextField
              label='Valor'
              variant='outlined'
              fullWidth
              size='small'
              value={valor}
              onChange={(e) => setValor(e.target.value)}
              onClick={handleClick}
              sx={{ textAlign: 'right' }}
            />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

function mapStateToProps(state) {
  return {
    idOrcamento: state.orcamentoReducer.idDoOrcamento,
    IdOrcamentoEstrutura: state.orcamentoReducer.IdOrcamentoEstrutura, 
    largura: state.orcamentoReducer.largura,
    avanco: state.orcamentoReducer.avanco,
  };
}

export default connect(mapStateToProps)(FormCalcularTrelica);
