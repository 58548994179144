import React, { useState } from 'react';
import { Box, Button, DialogContent, Grid, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';
import { MaskCnpj, MaskCpf, MaskNome, MaskPhone } from '../../../utils/mascaras';
import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import ClienteService from '../../../services/ClienteService';

const schema = yup.object({
  name: yup.string(),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  backgroundColor: '#fff',
  border: '2px solid #002',
  boxShadow: 24,
  overflow: 'auto',
};


function AddClientes() {
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, } = useForm({ resolver: yupResolver(schema) });
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);


  function handleSubmit(data) {
    dispatch(changeloading({ open: true, msg: 'Salvando cliente..' }))
    ClienteService.create(data).then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.message.error }))
      })
  }
  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={1}  >
      <Grid container spacing={2} justifyContent="center">

        <Grid item xs={7} sm={6} md={4} >
          <Typography variant='h1' fontSize='35px!important'>Clientes</Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={3}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button variant='contained'
            fullWidth size='small'
            onClick={handleOpen}
            startIcon={<Add />}

          > clientes
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, maxHeight: '80vh', overflowY: 'auto' }}>
              <DialogContent sx={{ overflow: 'auto' }}>
               
                  <form onSubmit={onSubmit(handleSubmit)}>
                    <Grid container spacing={2} >

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                          label='Nome'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("nome")}
                          onInput={(e) => {
                            e.target.value = MaskNome(e.target.value);
                            setValue("nome", e.target.value, { shouldValidate: true });
                          }}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                          label='Razao Social'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("razao_social")}
                          onInput={(e) => {
                            e.target.value = MaskNome(e.target.value);
                            setValue("razao_social", e.target.value, { shouldValidate: true });
                          }}
                        />
                        <Typography variant='subtitle2'>{errors?.razao_social?.message}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                          label='Email'
                          type='email'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("email")}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                          label='Telefone'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("telefone")}
                          onInput={(e) => {
                            e.target.value = MaskPhone(e.target.value);
                            setValue("telefone", e.target.value, { shouldValidate: true });
                          }}
                        />
                        <Typography variant='subtitle2'>{errors?.telefone?.message}</Typography>
                      </Grid>


                      <Grid item xs={11} sm={6} md={6} lg={4}>
                        <TextField
                          label='Cpf'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("cpf")}
                          onInput={(e) => {
                            e.target.value = MaskCpf(e.target.value);
                            setValue("cpf", e.target.value, { shouldValidate: true });
                          }}
                        />
                        <Typography variant='subtitle2'>{errors?.largura?.message}</Typography>
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={4}>
                        <TextField
                          label='cnpj'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("cnpj")}
                          onInput={(e) => {
                            e.target.value = MaskCnpj(e.target.value);
                            setValue("cnpj", e.target.value, { shouldValidate: true });
                          }}

                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={5}>
                        <TextField
                          label='Endereço'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("endereco")}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                          label='Condominio'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("condominio")}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={2}>
                        <TextField
                          label='Bairro'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("bairro")}
                        />
                      </Grid>

                      <Grid item xs={12} sm={6} md={6} lg={3}>
                        <TextField
                          label='Cidade'
                          variant='outlined'
                          fullWidth
                          size='small'
                          {...register("cidade")}
                        />
                      </Grid>


                      <Grid item xs={12} sm={3} md={1} lg={4}>
                      </Grid>

                      <Grid item xs={12} sm={5} md={5} lg={3}>
                        <Button type='submit' fullWidth variant='contained'>Enviar</Button>
                      </Grid>

                      <Grid item xs={12} sm={6} md={5} lg={3}>
                      </Grid>

                    </Grid>
                  </form>
              
              </DialogContent>
            </Box>
          </Modal>
        </Grid>

      </Grid>
    </Box >
  );
}

export default AddClientes;