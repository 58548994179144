import React, { useEffect, useState } from 'react';
import AddProdutos from './AddProdutos';
import { Autocomplete, Box, CircularProgress, Grid, IconButton, InputAdornment, Paper, TextField, } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { useForm } from 'react-hook-form';
import { yupResolver } from "@hookform/resolvers/yup"
import { Confirm, TableComponet } from '../../../components';
import ProdutosService from '../../../services/ProdutosService';
import { useDebounce } from '../../../hooks/UseDebounce';
import { useLocation, useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';

const headers = [
  {
    id: "nome",
    label: "Nome",
    props: {
      align: 'left',
    },
  },
  {
    id: "descricao",
    label: "Descricao",
    props: {
      align: 'right',
    },
  },
  {
    id: 'categoria',
    label: 'Categoria',
    props: {
      align: 'right',
    },
  },
  {
    id: 'subCategorias',
    label: 'Sub Categorias',
    props: {
      align: 'right',
    },
  },
  {
    id: 'uniMedida',
    label: 'Unidade Medida',
    props: {
      align: 'right',
    },
  },
  {
    id: 'largura',
    label: 'Largura',
    props: {
      align: 'right',
    },
  },
  {
    id: 'avanco',
    label: 'Avanço',
    props: {
      align: 'right',
    },
  },
  {
    id: 'modulacao',
    label: 'Modulação',
    props: {
      align: 'right',
    },
  },
  {
    id: 'peso',
    label: 'Peso',
    props: {
      align: 'right',
    },
  },
  {
    id: 'valor',
    label: 'Valor',
    props: {
      align: 'right',
    },
  },
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];

function Produtos() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  // Acessando nome diretamente como uma propriedade
  const nome = location.state ? location.state.nome : '';


  //const da tabela
  const [produtos, setProdutos] = useState([])
  const [loading, setLoading] = useState(false);
  const [totalPage, setTotalPages] = useState(0);

  //const auto complete
  const [optionsProdutos, setOptionsProdutos] = useState([]);
  const [busca, setBusca] = useState(nome);

  //const do confirm
  const handleOpenConfirmDialog = (id) => {
    setConfirmar({ id, confirmDialogOpen: true });
  };
  const handleCloseConfirmDialog = () => {
    setConfirmar({ id: null, confirmDialogOpen: false });
  };
  const [confirmar, setConfirmar] = useState({
    id: null,
    confirmDialogOpen: false,
  });

  const [isLoading, setIsLoading] = useState(false);
  const { debounce } = useDebounce();
  const { register } = useForm({ resolver: yupResolver() });
  const [tableUpdateTrigger, setTableUpdateTrigger] = useState(0);

  useEffect(() => {
    setIsLoading(true);
    debounce(() => {
      ProdutosService.get(busca)
        .then((res) => {
          setIsLoading(false);
          setProdutos(res.produtos || [])
          setOptionsProdutos(res.produtos.map(produtos => ({ id: produtos.id, label: produtos.nome })))
        })
    },);
  }, [busca, tableUpdateTrigger]);

  const handleDelete = () => {
    const idToDelete = confirmar.id.produto_id;
    ProdutosService.Delete(idToDelete)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: 'success',
            msg: res.message,
          })
        );
        setTableUpdateTrigger((prev) => prev + 1);
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: 'error',
            msg: error.message,
          })
        );
      });
    handleCloseConfirmDialog();
  };

  const handleEdit = (produto) => {
    navigate(`/editprodutos/${produto.produto_id}`, { state: { produto } });
  };

  return (
    <Box marginTop='-35px'>
      <Grid container spacing={2} >

        <Grid item xs={11}>
          <AddProdutos  />
        </Grid>

        <Grid item xs={12}>
          <Box padding={1}>
            <Box padding={2} width={400} marginTop='-20px' marginLeft='-10px'>
              <Autocomplete
                openText='Abrir'
                closeText='Fechar'
                noOptionsText='Sem opções'
                loadingText='Carregando...'
                disablePortal
                options={optionsProdutos}
                onInputChange={(_, newValue) => setBusca(newValue )}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    {...register("users_id")}
                    label='Pesquisar...'
                    variant='outlined'
                    fullWidth
                    size="small"
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          {isLoading ? (
                            <CircularProgress size={20} color="inherit" />
                          ) : (
                            <IconButton>
                              <SearchIcon />
                            </IconButton>
                          )}
                        </InputAdornment>
                      ),
                    }}
                  />
                )}
              />
              <Confirm
                open={confirmar.confirmDialogOpen}
                title="Deseja realmente excluir esse produto?"
                onClose={handleCloseConfirmDialog}
                onConfirm={handleDelete}
              />
            </Box>
            <Box>
              <TableComponet
                headers={headers}
                data={produtos}
                labelCaption={'Nenhum Produto encontrado!!'}
                labelTable={'Produtos'}
                handlerEditarAction={(produtoId) => { handleEdit(produtoId) }}
                handlerDeletarAction={(event) => { handleOpenConfirmDialog(event) }}
                request
                qdtPage={totalPage}
                loading={loading}
                setData={setProdutos}
                tableUpdateTrigger={tableUpdateTrigger}
                handlerRequest={async (page, size) => {
                  setLoading(true)
                  ProdutosService.get('', page, size).then(
                    (data) => {
                      setLoading(false)
                      setProdutos(data.produtos || [])
                      setTotalPages(data.totalPages || 0);
                      return data
                    },
                  )
                  return []
                }}
              />
            </Box>
          </Box>
        </Grid>

      </Grid>
    </Box>
  )
}
export default Produtos;
