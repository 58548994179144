import React, { useEffect, useState } from 'react';
import { Box, Button, DialogContent, Grid, InputLabel, MenuItem, Modal, Paper, Select, TextField, Typography } from '@mui/material';
import { Add } from '@mui/icons-material';

import * as yup from 'yup';
import { useForm } from "react-hook-form"
import { yupResolver } from "@hookform/resolvers/yup"
import ProdutosService from '../../../services/ProdutosService';
import { useDispatch } from 'react-redux';
import { changeloading } from '../../../store/actions/loading.action';
import { changeNotify } from '../../../store/actions/notify.actions';
import { MaskLargura, MaskNome, MaskPeso, MaskValor } from '../../../utils/mascaras'

const schema = yup.object({
  nome: yup.string().required().min(3),
  descricao: yup.string(),
  uniMedida: yup.string().required(),
  largura: yup.string(),
  peso: yup.string().required(),
});

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '95%',
  backgroundColor: '#fff',
  border: '2px solid #002',
  boxShadow: 24,
  overflow: 'auto',
};

function AddProdutos() {
  //dispatch reducer 
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const { register, handleSubmit: onSubmit, formState: { errors }, setValue, reset } = useForm({ resolver: yupResolver(schema) });
  //select categorias
  const [categoria, setCategoria] = useState([]);
  const [categoriaSelecionada, setCategoriaSelecionada] = useState('');
  //select subCategorias
  const [subcategoria, setSubCategoria] = useState([]);
  const [subCategoriaSelecionada, setSubCategoriaSelecionada] = useState('');
  //select unidade de medidas
  const uniMedidas = [
    'barra', 'peça', 'metro quadrado', 'chapa', "Unidade de Medidas", 'Metro Linear'
  ]
  const [unidadeSelecionada, setUnidadeSelecionada] = useState('Unidade de Medidas')
  const getCategoria = async () => {
    const res = await ProdutosService.buscarCategoria();
    if (res) {
      setCategoria(res.categoria);
    }
  };
  const getSubCategoria = async () => {
    const res = await ProdutosService.buscarSubCategoria();
    if (res) {
      setSubCategoria(res.subCategoria);
    }
  };
  useEffect(() => {
    getCategoria();
    getSubCategoria();
  }, []);


  function handleSubmit(data) {  
    dispatch(changeloading({ open: true, msg: 'salvando ...' }))
    ProdutosService.create(data).then((res) => {
      dispatch(changeloading({ open: false, }))
      dispatch(changeNotify({ open: true, class: 'success', msg: res.message }))
      reset();
    })
      .catch((error) => {
        dispatch(changeloading({ open: false, }))
        dispatch(changeNotify({ open: true, class: 'error', msg: error.response.data.error }))
      })
  }

  return (
    <Box component={Paper} elevation={5} sx={{ flexGrow: 1 }} marginTop={1} padding={1}  >
      <Grid container spacing={2} justifyContent="center">

        <Grid item xs={7} sm={6} md={4} >
          <Typography variant='h1' fontSize='35px!important'>Produtos</Typography>
        </Grid>

        <Grid item xs={1} sm={1} md={3}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={1} sm={1} md={2}>
          {/* Conteúdo do item */}
        </Grid>

        <Grid item xs={12} sm={4} md={3}>
          <Button variant='contained'
            fullWidth size='small'
            onClick={handleOpen}
            startIcon={<Add />}

          > Produtos
          </Button>
          <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
          >
            <Box sx={{ ...style, maxHeight: '80vh', overflowY: 'auto' }}>
              <DialogContent sx={{ overflow: 'auto' }}>
                <Typography variant='h1' sx={{ fontSize: '25px!important', marginBottom: '10px' }}>Casatrar Produto</Typography>
                <form onSubmit={onSubmit(handleSubmit)}>
                  <Grid container spacing={2} >
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                      <TextField
                        label='Nome'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("nome")}
                        onInput={(e) => {
                          e.target.value = MaskNome(e.target.value);
                          setValue("nome", e.target.value, { shouldValidate: true });
                        }}
                      />
                      <Typography variant='subtitle2'>{errors?.nome?.message}</Typography>
                    </Grid>


                    <Grid item xs={12} sm={6} md={4} lg={4}>
                      <TextField
                        label='Descrição'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("descricao")}
                      />
                      <Typography variant='subtitle2'>{errors?.descricao?.message}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={2}>
                      <TextField
                        label='Peso'
                        variant='outlined'
                        fullWidth
                        size='small'
                        placeholder='ex:10.5'
                        {...register("peso")}
                        onInput={(e) => {
                          e.target.value = MaskPeso(e.target.value);
                          setValue("peso", e.target.value, { shouldValidate: true });
                        }}
                      />
                      <Typography variant='subtitle2'>{errors?.peso?.message}</Typography>
                    </Grid>

                    <Grid item xs={12} sm={6} md={2} lg={3}>
                      <Select label='UniMedidas' id='uniMedida' variant='outlined' fullWidth {...register('uniMedida')}
                        value={unidadeSelecionada}
                        size='small'
                        onChange={(e) => setUnidadeSelecionada(e.target.value)}                    >
                        {uniMedidas.map((uniMedida) => (
                          <MenuItem key={uniMedida} value={uniMedida}>
                            {uniMedida}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant='subtitle2'>{errors?.uniMedida?.message}</Typography>
                    </Grid>



                    <Grid item xs={12} md={4}>
                      <InputLabel id='categoria' sx={{ fontSize: '15px' }}>Categoria</InputLabel>
                      <Select
                        id='categoria'
                        fullWidth
                        size='small'
                        value={categoriaSelecionada}
                        {...register('categoria_id')}
                        onChange={(e) => {
                          setCategoriaSelecionada(e.target.value);
                        }}
                      >
                        {categoria.map((categoria) => (
                          <MenuItem key={categoria.id} value={categoria.id}>
                            {categoria.categoria}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant='subtitle2'>{errors?.categoria?.message}</Typography>
                    </Grid>

                    <Grid item xs={12} md={4}>
                      <InputLabel id='subCategoria' sx={{ fontSize: '15px' }}>Sub Categoria</InputLabel>
                      <Select
                        id='subCategoria'
                        fullWidth
                        size='small'
                        value={subCategoriaSelecionada}
                        {...register('subCategoria_id')}
                        onChange={(e) => {
                          setSubCategoriaSelecionada(e.target.value);
                        }}
                      >
                        {subcategoria.map((subcategoria) => (
                          <MenuItem key={subcategoria.id} value={subcategoria.id}>
                            {subcategoria.sub_categoria}
                          </MenuItem>
                        ))}
                      </Select>
                      <Typography variant='subtitle2'>{errors?.sub_categoria?.message}</Typography>
                    </Grid>

                    <Grid item xs={11} sm={4} md={4} lg={4} marginTop='22px'>
                      <TextField
                        label='Largura'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("largura")}
                        onInput={(e) => {
                          e.target.value = MaskLargura(e.target.value);
                          setValue("largura", e.target.value, { shouldValidate: true });
                        }}
                      />
                      <Typography variant='subtitle2'>{errors?.largura?.message}</Typography>
                    </Grid>


                    <Grid item xs={12} sm={4} md={3} lg={4}>
                      <TextField
                        label='Avanço'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("avanco")}
                        onInput={(e) => {
                          e.target.value = MaskLargura(e.target.value);
                          setValue("avanco", e.target.value, { shouldValidate: true });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={3} lg={4}>
                      <TextField
                        label='Modulação'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("modulacao")}
                        onInput={(e) => {
                          e.target.value = MaskLargura(e.target.value);
                          setValue("modulacao", e.target.value, { shouldValidate: true });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={4} md={4} lg={4}>
                      <TextField
                        label='Valor'
                        variant='outlined'
                        fullWidth
                        size='small'
                        {...register("valor")}
                        onInput={(e) => {
                          e.target.value = MaskValor(e.target.value);
                          setValue("valor", e.target.value, { shouldValidate: true });
                        }}
                      />
                    </Grid>

                    <Grid item xs={12} sm={3} md={1} lg={4}>
                    </Grid>

                    <Grid item xs={12} sm={5} md={5} lg={3}>
                      <Button type='submit' fullWidth variant='contained'>Enviar</Button>
                    </Grid>

                    <Grid item xs={12} sm={6} md={5} lg={3}>
                    </Grid>

                  </Grid>
                </form>
              </DialogContent>
            </Box>
          </Modal>
        </Grid>

      </Grid>
    </Box>
  );
}

export default AddProdutos;
