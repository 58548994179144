import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Circle, Number, StyledSpan } from "./Styled";
import { NumericFormat } from "react-number-format";
import { changeloading } from "../../../store/actions/loading.action";
import { useDispatch } from "react-redux";
import VendasService from "../../../services/VendasService";
import { changeNotify } from "../../../store/actions/notify.actions";
import UsersService from "../../../services/UsersService";

function NovaVenda() {
  const [idOrcamento, setIdOrcamento] = useState("");
  const [selecoes, setSelecoes] = useState([]);
  const [valor, setValor] = useState("");
  const [desconto, setDesconto] = useState("");
  const [dataVenda, setDataVenda] = useState("");
  const [idVenda, setIdVenda] = useState("");
  const [valorButtonClicked, setValorButtonClicked] = useState(false);
  const [quantidadeParcelas, setQuantidadeParcelas] = useState(0);
  const [pagamentoSelecionado, setPagamentoSelecionado] = useState("6");
  const [tipoPagamento, setTipoPagamento] = useState("6");
  const [vendedorSelecionado, setVendedorSelecionado] = useState("");
  const [vendedor, setVendedor] = useState([]);
  const [vendedorError, setVendedorError] = useState(false);

  const [tiposPagamentoParcelas, setTiposPagamentoParcelas] = useState(
    Array.from({ length: quantidadeParcelas }, () => "")
  );
  const [valoresParcelas, setValoresParcelas] = useState(
    Array.from({ length: quantidadeParcelas }, () => "")
  );
  const [datasParcelas, setDatasParcelas] = useState(
    Array.from({ length: quantidadeParcelas }, () => "")
  );
  const dispatch = useDispatch();

  const getVendedor = async () => {
    const res = await UsersService.buscarVendedor();
    if (res) {
      setVendedor(res.vendedor);
    }
  };

  const getTipopagamento = async () => {
    try {
      const res = await VendasService.getTipopagamento();
      if (res) {
        setTipoPagamento(res.tipoPagamento);
      }
    } catch (error) {
      console.error("Erro ao obter técnicos:", error);
    }
  };

  useEffect(() => {
    getTipopagamento();
    getVendedor();
  }, []);

  function handleAddSelecao() {
    if (idOrcamento && idOrcamento.trim() !== "") {
      const ids = idOrcamento.split(",").map((id) => id.trim());
      const newSelecoes = ids.map((id) => ({ id_orcamento: id }));

      setSelecoes([...selecoes, ...newSelecoes]);
      setIdOrcamento("");
    }
  }

  function handleChange(e) {
    const value = e.target.value;
    setIdOrcamento(value);

    if (value && value.slice(-1) === ",") {
      handleAddSelecao();
    }
  }

  function handleRemoveSelecao(id) {
    setSelecoes(selecoes.filter((selecao) => selecao.id_orcamento !== id));
  }

  const handleSubmit = () => {
    if (!vendedorSelecionado) {
      setVendedorError(true);
      return;
    }
    if (!valorButtonClicked) {
      // Verifica se o botão de valor já foi clicado
      setValorButtonClicked(true);
      dispatch(changeloading({ open: true, msg: "Buscando valor ..." }));
      const data = {
        idOrcamento: selecoes,
        dataVenda: dataVenda,
        vendedor: vendedorSelecionado,
      };

      VendasService.buscarValorTotalVenda(data)
        .then((res) => {
          dispatch(changeloading({ open: false }));
          setValor(res.valor_total);
          setIdVenda(res.idVenda);
        })
        .catch((error) => {
          dispatch(changeloading({ open: false }));
          dispatch(
            changeNotify({
              open: true,
              class: "error",
              msg: error.response.data.error,
            })
          );
        });
    }
  };

  const handleEnviarVenda = () => {
    dispatch(changeloading({ open: true, msg: "Salvando a venda ..." }));

    // Calcular o valor total das parcelas
    const totalParcelas = valoresParcelas.reduce(
      (acc, curr) => acc + parseFloat(curr || 0),
      0
    );

    // Adicionar o desconto ao total das parcelas
    const totalComDesconto = totalParcelas + parseFloat(desconto || 0);

    // Verificar se o total com desconto é igual ao valor total da venda
    if (totalComDesconto !== parseFloat(valor)) {
      dispatch(changeloading({ open: false }));
      dispatch(
        changeNotify({
          open: true,
          class: "error",
          msg: "O valor das parcelas somado ao desconto não corresponde ao valor total da venda.",
        })
      );
      return; // Retorna para interromper o envio dos dados
    }

    // Criar um array para armazenar as parcelas
    const parcelas = [];

    // Iterar sobre as parcelas para construir os objetos de parcela
    for (let i = 0; i < quantidadeParcelas; i++) {
      // Construir o objeto de parcela
      const parcela = {
        tiposPagamento: tiposPagamentoParcelas[i] || "",
        valorParcela: valoresParcelas[i] || "",
        dataParcela: datasParcelas[i] || "",
      };

      // Adicionar a parcela ao array de parcelas
      parcelas.push(parcela);
    }

    const data = {
      idVenda: idVenda,
      valorTotal: valor,
      desconto: desconto,
      tipoPagamento: pagamentoSelecionado,
      quantidadeParcelas: quantidadeParcelas,
      // Adicionar o array de parcelas ao objeto de dados
      parcelas: parcelas,
    };

    VendasService.salvarVenda(data)
      .then((res) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "success",
            msg: res.message,
          })
        );
      })
      .catch((error) => {
        dispatch(changeloading({ open: false }));
        dispatch(
          changeNotify({
            open: true,
            class: "error",
            msg: error.response.data.error,
          })
        );
      });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="h1" marginLeft={2} marginTop={2}>
            Nova Venda
          </Typography>
        </Grid>
      </Grid>
      <Grid
        component={Paper}
        elevation={4}
        container
        spacing={2}
        marginTop={3}
        padding={1}
      >
        <Grid item xs={10}>
          <Typography variant="h5">
            Insira os Ids de Orçamento para gerar uma venda! Separe os Id por
            vírgula caso haja mais de um.
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography variant="h5">Id da Venda: {idVenda}</Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4} lg={3}>
          <TextField
            variant="outlined"
            label="Ids Orçamentos"
            fullWidth
            size="small"
            value={idOrcamento}
            onChange={handleChange}
            InputLabelProps={{ shrink: true }}
            InputProps={{
              endAdornment: selecoes.map((selecao, index) => (
                <React.Fragment key={index}>
                  {index > 0 && ", "}
                  <StyledSpan>
                    <Circle>
                      <Number>{selecao.id_orcamento}</Number>
                    </Circle>
                    <IconButton
                      size="small"
                      onClick={() => handleRemoveSelecao(selecao.id_orcamento)}
                    >
                      <CancelIcon />
                    </IconButton>
                  </StyledSpan>
                </React.Fragment>
              )),
            }}
          />
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={2}>
          <FormControl fullWidth error={vendedorError}>
            <InputLabel id="vendedor">Selecione o vendedor</InputLabel>
            <Select
              labelId="vendedor"
              size="small"
              fullWidth
              value={vendedorSelecionado}
              onChange={(e) => {
                setVendedorSelecionado(e.target.value);
                setVendedorError(false);
              }}
            >
              {vendedor.map((vendedor) => (
                <MenuItem key={vendedor.id} value={vendedor.id}>
                  {vendedor.name}
                </MenuItem>
              ))}
            </Select>
            {vendedorError && (
              <FormHelperText>vendedor obrigatorio</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={4} sm={4} md={4} lg={2}>
          <TextField
            label="Data da venda"
            variant="outlined"
            InputLabelProps={{ shrink: true }}
            fullWidth
            size="small"
            type="date"
            value={dataVenda}
            onChange={(e) => setDataVenda(e.target.value)}
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <NumericFormat
            customInput={TextField}
            label="Valor Total"
            variant="outlined"
            fullWidth
            size="small"
            disabled={valorButtonClicked} // Desabilita o TextField quando valorButtonClicked é verdadeiro
            value={valor}
            onClick={() => handleSubmit()}
            onValueChange={(values) => {
              setValor(values.value);
            }}
            allowLeadingZeros={false}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            allowedDecimalSeparators={["."]}
            prefix="R$ "
            thousandSeparator="."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={3} lg={2}>
          <NumericFormat
            customInput={TextField}
            size="small"
            label="Desconto"
            value={desconto}
            onValueChange={(values) => {
              setDesconto(values.value);
            }}
            allowLeadingZeros={false}
            allowNegative={false}
            decimalScale={2}
            fixedDecimalScale={true}
            decimalSeparator=","
            allowedDecimalSeparators={["."]}
            prefix="R$ "
            thousandSeparator="."
          />
        </Grid>

        <Grid item xs={12} sm={6} md={2} lg={1}>
          <TextField
            label="Qdt Parcelas"
            type="number"
            variant="outlined"
            fullWidth
            size="small"
            value={quantidadeParcelas}
            onChange={(e) => {
              const value = e.target.value;
              setQuantidadeParcelas(value);
              setTiposPagamentoParcelas(
                Array.from({ length: value }, () => "")
              );
              setValoresParcelas(Array.from({ length: value }, () => ""));
              setDatasParcelas(Array.from({ length: value }, () => ""));
            }}
          />
        </Grid>

        {/* Renderizar os componentes das parcelas */}
        {Array.from({ length: quantidadeParcelas }).map((_, index) => (
          <React.Fragment key={index}>
            <Grid item xs={4} sm={4} md={4} lg={4}>
              <FormControl fullWidth size="small" variant="outlined">
                <InputLabel id={`label-tipo-pagamento-${index}`}>
                  {`Tipo de Pagamento Parcela ${index + 1}`}
                </InputLabel>
                <Select
                  labelId={`label-tipo-pagamento-${index}`}
                  value={tiposPagamentoParcelas[index] || ""}
                  onChange={(e) => {
                    const newTiposPagamento = [...tiposPagamentoParcelas];
                    newTiposPagamento[index] = e.target.value;
                    setTiposPagamentoParcelas(newTiposPagamento);
                  }}
                >
                  {tipoPagamento.map((pagamento) => (
                    <MenuItem key={pagamento.id} value={pagamento.id}>
                      {pagamento.tipo_pagamento}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={2}>
              <NumericFormat
                customInput={TextField}
                size="small"
                label={`Valor Parcela ${index + 1}`}
                value={valoresParcelas[index] || ""}
                onValueChange={(values) => {
                  const newValores = [...valoresParcelas];
                  newValores[index] = values.value;
                  setValoresParcelas(newValores);
                }}
                allowLeadingZeros={false}
                allowNegative={false}
                decimalScale={2}
                fixedDecimalScale={true}
                decimalSeparator=","
                allowedDecimalSeparators={["."]}
                prefix="R$ "
                thousandSeparator="."
              />
            </Grid>

            <Grid item xs={4} sm={4} md={4} lg={3}>
              <TextField
                label={`Data da Parcela ${index + 1}`}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
                type="date"
                value={datasParcelas[index] || ""}
                onChange={(e) => {
                  const newDatas = [...datasParcelas];
                  newDatas[index] = e.target.value;
                  setDatasParcelas(newDatas);
                }}
              />
            </Grid>
          </React.Fragment>
        ))}

        <Grid item xs={3} sm={3} md={3} lg={3}>
          <Button variant="contained" onClick={() => handleEnviarVenda()}>
            Enviar
          </Button>
        </Grid>
      </Grid>
    </Box>
  );
}

export default NovaVenda;
