import React, { useEffect, useState } from 'react';
import { Box, Grid, Modal, TextField, Button } from '@mui/material';
import { useDispatch } from 'react-redux';
import CategoriaFinanceira from '../../services/CategoriaFinanceira';
import { changeloading } from '../../store/actions/loading.action';
import { TableComponet } from '../../components';

const headers = [
  {
    id: "id",
    label: "Id",
    props: {
      align: 'left',
    },
  },
  {
    id: "valor_min",
    label: "Valor Minimo",
    props: {
      align: 'right',
    },
  },
  {
    id: 'valor_max',
    label: 'Valor Maximo',
    props: {
      align: 'right',
    },
  },
  {
    id: 'multiplicador',
    label: 'Multiplicador',
    props: {
      align: 'right',
    },
  },
  {
    id: 'actionRows',
    label: 'Ações',
    props: {
      align: 'right',
    },
  },
];

function RegrasMultiplicacao() {
  const [regras, setRegras] = useState([]);
  const [mounted, setMounted] = useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [currentRegra, setCurrentRegra] = useState(null);
  const dispatch = useDispatch();

  async function getRegras() {
    const res = await CategoriaFinanceira.getRegras();
    if (res) {
      setRegras(res.regras);
    }
  }

  useEffect(() => {
    const fetchData = async () => {
      dispatch(changeloading({ open: true, msg: "Carregando" }));
      if (mounted) {
        await getRegras();
        dispatch(changeloading({ open: false }));
      }
    };

    fetchData();
    return () => {
      setMounted(false);
    };
  }, [dispatch, mounted]);

  const handleEdit = (regra) => {
    setCurrentRegra(regra);
    setOpenModal(true);
  };

  const handleSave = async () => {
    console.log(currentRegra)
    if (currentRegra) {
      await CategoriaFinanceira.updateRegra(currentRegra.id, currentRegra);
      await getRegras();
      setOpenModal(false);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setCurrentRegra({
      ...currentRegra,
      [name]: value,
    });
  };

  return (
    <Box>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Box>
            <TableComponet
              headers={headers}
              data={regras}
              labelCaption={'Nenhuma regra encontrada!!'}
              labelTable={'Regras de Multiplicação'}
              handlerEditarAction={handleEdit}
              handlerDeletarAction={''}
            />
          </Box>
        </Grid>
      </Grid>

      <Modal open={openModal} onClose={() => setOpenModal(false)}>
        <Box sx={{ 
          position: 'absolute', 
          top: '50%', 
          left: '50%', 
          transform: 'translate(-50%, -50%)', 
          width: 400, 
          bgcolor: 'background.paper', 
          p: 4, 
          boxShadow: 24 
        }}>
          <h2>Editar Regra</h2>
          {currentRegra && (
            <>
              <TextField
                label="Valor Minimo"
                name="valor_min"
                value={currentRegra.valor_min}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Valor Maximo"
                name="valor_max"
                value={currentRegra.valor_max}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <TextField
                label="Multiplicador"
                name="multiplicador"
                value={currentRegra.multiplicador}
                onChange={handleChange}
                fullWidth
                margin="normal"
              />
              <Button variant="contained" color="primary" onClick={handleSave}>
                Salvar
              </Button>
            </>
          )}
        </Box>
      </Modal>
    </Box>
  );
}

export default RegrasMultiplicacao;
